import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import GetFormularioNew from './getFormularioNew';
import DatePicker from "react-datepicker";
import Checkbox from './../util/checkbox';
import { Thingiverse } from 'styled-icons/simple-icons';
import { isMobile, isTablet, isDesktop, browserName, osName } from 'react-device-detect';


class getsurvey extends Component {
    //id_survey, id_local, fecha_creacion, calificacion, observacion, sw_contactarse, telefono, nombres
    constructor(props){
        super(props);
        this.state = {
            uuid_local : this.props.uuid_local,
            nombre:"",
            telefono:"",
            correo:"",
            observacion:"",
            date_desde : new Date(),
            data_ch1:[],
            data_ch2:[],
            data_ch3:[],
            data_ch4:[],
            data_ch5:[],
            id_ch1:0,
            id_ch2:0,
            id_ch3:0,
            id_ch4:0,
            id_ch5:0,
            selectedOptions1:[],
            selectedOptions2:[],
            selectedOptions3:[],
            selectedOptions4:[],
            selectedOptions5:[],
            nombre_local:'',
            sw_pregunta:0,
            calificacion:-1,
            sw_contactarse:0,
            sw_alerta:0,
            checkboxes: [
              { id: 1, label: 'Atención al cliente', checked: false, activeImg: `${process.env.PUBLIC_URL}/CIRCULO_VERDE_GRIS_50X50.png`, inactiveImg: `${process.env.PUBLIC_URL}/CIRCULO_GRIS_50X50.png` },
              { id: 2, label: 'Calidad del producto', checked: false, activeImg: `${process.env.PUBLIC_URL}/CIRCULO_VERDE_GRIS_50X50.png`, inactiveImg: `${process.env.PUBLIC_URL}/CIRCULO_GRIS_50X50.png` },
              { id: 3, label: 'Instalaciones', checked: false, activeImg: `${process.env.PUBLIC_URL}/CIRCULO_VERDE_GRIS_50X50.png`, inactiveImg: `${process.env.PUBLIC_URL}/CIRCULO_GRIS_50X50.png` },
              { id: 4, label: 'Precio', checked: false, activeImg: `${process.env.PUBLIC_URL}/CIRCULO_VERDE_GRIS_50X50.png`, inactiveImg: `${process.env.PUBLIC_URL}/CIRCULO_GRIS_50X50.png` },
              { id: 5, label: 'Variedad de platos', checked: false, activeImg: `${process.env.PUBLIC_URL}/CIRCULO_VERDE_GRIS_50X50.png`, inactiveImg: `${process.env.PUBLIC_URL}/CIRCULO_GRIS_50X50.png` },
            ],sw_atencion:0, sw_calidad:0, sw_instalacion:0, sw_precio:0, sw_variedad:0,otro:'',ip:'',
            userAgent :"Browser: "+browserName+" Operating System: "+osName+" Is Mobile: "+(isMobile ? "Yes" : "No")+" Is Tablet: "+(isTablet ? "Yes" : "No")+
        " Is Desktop: "+(isDesktop ? "Yes" : "No")  ,
        }

        
      
        //console.log(platform)
        //console.log(language)
    }
// sw_atencion, sw_calidad, sw_instalacion, sw_precio, sw_variedad  
    actualizar_lista =()=>{
      let sw_atencion=0
      let sw_calidad=0
      let sw_instalacion=0
      let sw_precio=0
      let sw_variedad=0
      this.state.checkboxes.forEach(dt => {
        if(dt.id==1&&dt.checked==true){
          sw_atencion=1
        }
        if(dt.id==2&&dt.checked==true){
          sw_calidad=1
        }
        if(dt.id==3&&dt.checked==true){
          sw_instalacion=1
        }
        if(dt.id==4&&dt.checked==true){
          sw_precio=1
        }
        if(dt.id==5&&dt.checked==true){
          sw_variedad=1
        }
      })
      this.setState({
        sw_atencion, sw_calidad, sw_instalacion, sw_precio, sw_variedad
      })
      //console.log(sw_atencion+","+sw_calidad+","+sw_instalacion+","+ sw_precio+","+ sw_variedad)
    }
    handleCheckboxChange = (id) => {
      //console.log(id)
      this.setState((prevState) => ({
        checkboxes: prevState.checkboxes.map((checkbox) =>
          checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : { ...checkbox, checked: false }
        ),
      }),()=>this.actualizar_lista());
    

      //sw_atencion, sw_calidad, sw_instalacion, sw_precio, sw_variedad
    };

    onChangeDesde = date => this.setState({ date_desde:date })

    onChangeHasta = date => this.setState({ date_hasta:date })
  
    handleCalendarClose = () => {
      console.log("Calendar closed");
    }
  
    handleCalendarOpen = () => {
        console.log("Calendar opened");
    }
        
    return_data =(name,value,id)=>{
        this.setState({
            [name]:value
        })
    }
    handleCheckboxChange1 = (option) => {
        const { selectedOptions1 } = this.state;

        if (selectedOptions1.includes(option)) {
            // Si ya está seleccionado, quitarlo del array
            this.setState({
                selectedOptions1: selectedOptions1.filter((item) => item !== option),
            });
        } else {
            // Si no está seleccionado, agregarlo al array
            this.setState({
                selectedOptions1: [...selectedOptions1, option],
            });
        }
    };
    handleCheckboxChange2 = (option) => {
        const { selectedOptions2 } = this.state;

        if (selectedOptions2.includes(option)) {
            // Si ya está seleccionado, quitarlo del array
            this.setState({
                selectedOptions2: selectedOptions2.filter((item) => item !== option),
            });
        } else {
            //const { selectedOptions2 } = [];
            
            // Si no está seleccionado, agregarlo al array
            this.setState({
                selectedOptions2: [...[], option],
            });
        }
    };
    handleCheckboxChange3 = (option) => {
        const { selectedOptions3 } = this.state;

        if (selectedOptions3.includes(option)) {
            // Si ya está seleccionado, quitarlo del array
            this.setState({
                selectedOptions3: selectedOptions3.filter((item) => item !== option),
            });
        } else {
            // Si no está seleccionado, agregarlo al array
            this.setState({
                selectedOptions3: [...[], option],
            });
        }
    };
    handleCheckboxChange4 = (option) => {
        const { selectedOptions4 } = this.state;

        if (selectedOptions4.includes(option)) {
            // Si ya está seleccionado, quitarlo del array
            this.setState({
                selectedOptions4: selectedOptions4.filter((item) => item !== option),
            });
        } else {
            // Si no está seleccionado, agregarlo al array
            this.setState({
                selectedOptions4: [...[], option],
            });
        }
        //selectedOptions4
        /*4636
4637
4638
4639
4649
4650
4651
4652
4653
4654*/
        let calificacion =-1
        if(option==4636){
          calificacion=1
        }
        if(option==4637){
          calificacion=2
        }
        if(option==4638){
          calificacion=3
        }
        if(option==4639){
          calificacion=4
        }
        if(option==4649){
          calificacion=5
        }
        if(option==4650){
          calificacion=6
        }
        if(option==4651){
          calificacion=7
        }
        if(option==4652){
          calificacion=8
        }
        if(option==4653){
          calificacion=9
        }
        if(option==4654){
          calificacion=10
        }
        if(option==129){
          calificacion=0
        }
        


        if(option==4652||option==4653||option==4654){
          this.setState({
            calificacion
          })
        }else{
          this.setState({
           calificacion
          })
        }
    };
    handleCheckboxChange5 = (option) => {
        //console.log("5"+option)
        const { selectedOptions5 } = this.state;

        if (selectedOptions5.includes(option)) {
            // Si ya está seleccionado, quitarlo del array
            this.setState({
                selectedOptions5: selectedOptions5.filter((item) => item !== option),
            });
        } else {
            // Si no está seleccionado, agregarlo al array
            this.setState({
                selectedOptions5: [...selectedOptions5, option],
            });
        }
    };

    handleChange = (event) => {
      this.setState({
        sw_contactarse: event.target.value
      });
  };


    render() {
      console.log(this.state.userAgent)
        const data_ch1 = this.state.data_ch1
        const data_ch2 = this.state.data_ch2
        const data_ch3 = this.state.data_ch3
        const data_ch4 = this.state.data_ch4 
        const data_ch5 = this.state.data_ch5 
        //<font>{option.name}</font>
        /* <img style={{"height":"60px",objectFit: 'cover', marginLeft: '-' + (index * 61.5) + 'px'}} src={"https://grupomn.net/app/img/cara.PNG"} /> */
        return (
          <React.Fragment>
             
                 <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"position":"fixed","color":"gray"}}  >


                    <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"overflowY":"auto"}}>
                    
                     
                    <center><img src={`${process.env.PUBLIC_URL}/LOGO_LCR.png`}/> </center>
                     

                    <table border="0"  style={{"width":"100%","padding":"0px"}}>
                    <tbody>
                    <tr><td>
                    {Number(this.state.sw_pregunta)!=5&&Number(this.state.sw_pregunta)!=1?
                      <center>Valoramos tu opinión, te tomará 2 minutos</center>
                      :null}
                      
                      </td></tr>
                    <tr><td><center><div className="survey-style1">
                            <p>LCR {this.state.nombre_local}</p>
                          </div></center></td></tr>
                    {(Number(this.state.sw_pregunta)==0||Number(this.state.sw_pregunta)==2)?<React.Fragment>
                    <tr><td style={{"fontSize":"18px"}}><div className='survey-style2'>1. Del 1 al 10 indique qué tan probable es que recomiendes Los Cebiches de la Rumiñahui a un familiar o amigo</div></td></tr>
                    <tr><td style={{"fontSize":"18px","padding":"0px"}}>
                    <ul class="ks-cboxtagsface">
                        {data_ch4.map((option,index) => (
                            <li key={index}>
                                <input
                                type="checkbox"
                                id={option.id}
                                value={option.name}
                                checked={this.state.selectedOptions4.includes(option.id)}
                                onChange={() => this.handleCheckboxChange4(option.id)}
                                />
                                <label htmlFor={option.id}>
                                <div style={{"height":"100%"}}>
                                  <img style={{"height":"100%"}} src={`${process.env.PUBLIC_URL}/ICONOS_CARAS${index+1}.png`} />
                                </div>
                                </label>
                            </li>
                        ))}</ul></td></tr>
                        <tr><td> {(Number(this.state.sw_alerta)===1)?
                    <div className="alertasurvey">
                        Favor seleccione un número para continuar
                    </div>:null}</td></tr>
                        <tr><td style={{"textAlign":"right"}}>

                           
                          
                           
                            <img src={`${process.env.PUBLIC_URL}/BONTON_SIGUIENTE.png`} alt="Icon" className="survey-style3" onClick={()=>this.return_siguiente()}/>
                            
                          
                          
                        </td></tr>  
                        </React.Fragment>:null}      
                             
                        {Number(this.state.sw_pregunta)==1?<React.Fragment>
                       
                          <tr><td style={{"fontSize":"18px"}}><div className='survey-style2'>2. ¿En qué aspecto podríamos mejorar?</div></td></tr>
                    <tr><td style={{"fontSize":"18px"}}> 
                       <GetInput classname={"textInputsurveyv2"} placeholder={""} name={"otro"} style={{"width":"90%"}} value={this.state.otro} return_data={this.return_data} /> 
                      
                      {(Number(this.state.sw_alerta)===1)?
                    <div className="alertasurvey">
                        Favor ingrese su comentario
                    </div>:null}
                      
                      </td></tr>
                    
                    </React.Fragment>:null}   
                    {(Number(this.state.sw_pregunta)==2||Number(this.state.sw_pregunta)==1)?<React.Fragment>
                    
                    <tr><td style={{"textAlign":"right"}}> 
                      
                      
                      <img src={`${process.env.PUBLIC_URL}/BONTON_FINALIZAR.png`} alt="Icon" className="survey-style3" onClick={()=>this.return_enviar()}/>
                      
                       </td></tr>
                    </React.Fragment>:null}   
                    {(Number(this.state.sw_pregunta)==5)?<React.Fragment>
                      <tr><td style={{"fontSize":"20px"}}><center>¡Muchas gracias por tu opinión!<br/>
                       Nos ayuda a mejorar tu experiencia.<br/><br/>
                      Esperamos volver a atenderte pronto.</center></td></tr>
                     
                    </React.Fragment>:null}  
                    </tbody>
                    </table>
                    {Number(this.state.sw_pregunta)==1?<React.Fragment>
                      <p style={{"paddingLeft":"30%"}}><span className="survey-style4"><font style={{"fontSize":"8pt"}}><i>Las respuestas se receptan de manera anónima con el objetivo de medir la satisfacción de nuestros clientes.
                       LCR Productos y Servicios Cia LTDA., no almacena datos personales en el envío de este formulario</i></font></span></p>
                     </React.Fragment>:null}
                </div>
                </div>
              
           </React.Fragment>
        )
      }
/*    <tr><td style={{"fontSize":"18px"}}><div className='survey-style2'>2. ¿En qué aspecto podríamos mejorar?</div></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                  <ul class="ks-cboxtagsv2">
                  {this.state.checkboxes.map((checkbox) => (
                            <li key={checkbox.id}><input
                            type="checkbox"
                            id={checkbox.id}
                            value={checkbox.label}
                            checked={checkbox.checked}
                            onChange={() => this.handleCheckboxChange(checkbox.id)}
                            />
                            <label htmlFor={checkbox.id}>{checkbox.label}</label></li>
                    ))}
                        </ul>
                  
                    
                    <br/>
                    {(Number(this.state.sw_alerta)===1)?
                    <div className="alertasurvey">
                        Favor seleccione un aspecto en qué podríamos mejorar
                    </div>:null}
                    
                    </td></tr> */
      /*    <GetInput classname={"textInputsurvey"} placeholder={"Escriba su sugerencia o comentarios"} name={"observacion"} style={{"width":"90%"}} 
                    value={this.state.observacion} return_data={this.return_data} /> */
      /* 
                    <tr><td style={{"fontSize":"18px"}}>3. ¿Te gustaría que nos contactemos para resolver tu comentario?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                      <div className='radiosurvey'>
                      <input   type="radio" id="si" name="opcion" value="1" checked={this.state.sw_contactarse === "1"} onChange={this.handleChange}/>
                      <label for="si">Sí</label>
                      <input   type="radio" id="no" name="opcion" value="0" checked={this.state.sw_contactarse === "0"} onChange={this.handleChange}/>
                      <label  for="no">No</label>
                      </div>
                      </td></tr>       */
      /* {(this.state.sw_contactarse === "1")?
                      <React.Fragment>
                    <tr><td style={{"fontSize":"18px"}}>Nombre</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput classname={"textInputsurvey"} placeholder={"Nombres"} name={"nombre"} style={{"width":"90%"}} value={this.state.nombre} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Teléfono</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput classname={"textInputsurvey"} placeholder={"Teléfono"} name={"telefono"} style={{"width":"90%"}} value={this.state.telefono} return_data={this.return_data} /></td></tr>
                    </React.Fragment>
                    :null}
                     */
      //id_survey, id_local, fecha_creacion, calificacion, observacion
      return_siguiente =()=>{
        if(Number(this.state.calificacion)>=0){
          this.setState({
            sw_pregunta:1,sw_alerta:0
          })
        }else{
          this.setState({
            sw_alerta:1
          })
        }
      }
      return_enviar =()=>{
        
        if(Number(this.state.calificacion)<=8){
          let sw_atencion= Number(this.state.sw_atencion)
          let sw_calidad= Number(this.state.sw_calidad)
          let sw_instalacion= Number(this.state.sw_instalacion)
          let sw_precio= Number(this.state.sw_precio)
          let sw_variedad= Number(this.state.sw_variedad)
          if(this.state.otro===''){
            this.setState({
              sw_alerta:1
            })
            return
          }
          
        }





        const fecha_creacion=this.formatDate(new Date())
        var select
        select = [{
          type: 'insertar', data:
          [{
            table : 'survey',
            field :[{
              name : 'id_local',
              value : this.state.id_local,
              type:'string'
            },{
              name : 'fecha_creacion',
              value : fecha_creacion,
              type:'string'
            },{
              name : 'calificacion',
              value : this.state.calificacion,
              type:'string'
            },{
              name : 'observacion',
              value : this.state.otro,
              type :'string'
            },{
              name : 'ip',
              value : this.state.ip+" "+this.state.userAgent.replace("'",""),
              type :'string'
            },{
              name : 'sw_contactarse',
              value : this.state.sw_contactarse,
              type :'string'
            },{
              name : 'nombres',
              value : this.state.nombre,
              type :'string'
            },{
              name : 'telefono',
              value : this.state.telefono,
              type :'string'
            },{
              name : 'sw_atencion',
              value : this.state.sw_atencion,
              type :'string'
            },{
              name : 'sw_calidad',
              value : this.state.sw_calidad,
              type :'string'
            },{
              name : 'sw_instalacion',
              value : this.state.sw_instalacion,
              type :'string'
            },{
              name : 'sw_precio',
              value : this.state.sw_precio,
              type :'string'
            },{
              name : 'sw_variedad',
              value : this.state.sw_variedad,
              type :'string'
            }],
            return:['id_survey']
          }]
        }]
        // sw_atencion, sw_calidad, sw_instalacion, sw_precio, sw_variedad
        var result = Selectbd(select)
        //console.log(result)
        if(result){
          result.then((value) => {
            if(value[1].status==="ok"){
              this.setState({
                sw_pregunta:5
              })
            }
          })
        }
        
      }
      formatDate=(date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
   
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
   
        return [year, month, day].join('-');
     }
      /*<div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"position":"fixed"}}  >
                    <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"overflowY":"auto"}}>
                    <table className="table_v2" style={{"width":"100%"}}>
                    <thead>
                    <tr><th colSpan="2" style={{"fontSize":"18px"}}>Valoramos tu opinión, te tomará menos de 5 minutos</th></tr>
                    </thead>
                    <tbody>
                    <tr><td style={{"fontSize":"18px","textAlign":"center"}}><b>Local {this.state.nombre_local}</b></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Nombre completo</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Juan Pérez"} name={"nombre"} style={{"width":"90%"}} value={this.state.nombre} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Teléfono (Opcional)</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Ejm: 0987654321"} name={"telefono"} style={{"width":"90%"}} value={this.state.telefono} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Correo Electrónico:</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Ejm: correo@mail.com"} name={"correo"} style={{"width":"90%"}} value={this.state.correo} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>De Dónde nos cononces?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtags">
                    {data_ch1.map((option,index) => (
                            <li key={index}><input
                            type="checkbox"
                            id={option.id}
                            value={option.name}
                            checked={this.state.selectedOptions1.includes(option.id)}
                            onChange={() => this.handleCheckboxChange1(option.id)}
                            />
                            <label htmlFor={option.id}>{option.name}</label></li>
                    ))}
                        </ul>
                        </td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Tu fecha de cumpleaños (opcional)</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Personas en la mesa?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtags">
                    {data_ch2.map((option,index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={option.id}
                            value={option.name}
                            checked={this.state.selectedOptions2.includes(option.id)}
                            onChange={() => this.handleCheckboxChange2(option.id)}
                            />
                            <label htmlFor={option.id}>{option.name}</label></li>
                    ))}
                    </ul>
                    </td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Cuantó gastaste por persona?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtags"> 
                    {data_ch3.map((option,index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={option.id}
                            value={option.name}
                            checked={this.state.selectedOptions3.includes(option.id)}
                            onChange={() => this.handleCheckboxChange3(option.id)}
                            />
                        <label htmlFor={option.id}>{option.name}</label></li>
                    ))}</ul></td>
                    </tr>
                    <tr><td style={{"fontSize":"18px"}}>Cómo nos calificas?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtagsface">
                        {data_ch4.map((option,index) => (
                            <li key={index}>
                                <input
                                type="checkbox"
                                id={option.id}
                                value={option.name}
                                checked={this.state.selectedOptions4.includes(option.id)}
                                onChange={() => this.handleCheckboxChange4(option.id)}
                                />
                                <label htmlFor={option.id}><font>{option.name}</font><img style={{"width":"60px","height":"60px"}} src={"https://grupomn.net/app/img/cara"+(index+1)+".png"} /></label>
                            </li>
                        ))}</ul></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>En qué podemos mejorar?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                        <ul class="ks-cboxtags">   
                        {data_ch5.map((option,index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={"mej"+option.id}
                            value={option.name}
                            checked={this.state.selectedOptions5.includes(option.id)}
                            onChange={() => this.handleCheckboxChange5(option.id)}
                            />
                            <label htmlFor={"mej"+option.id}>{option.name}</label></li>
                    ))}</ul></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Compartenos detalles sobre tu experiencia en este lugar?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Observación"} name={"observacion"} style={{"width":"90%"}} value={this.state.observacion} return_data={this.return_data} /></td></tr>
                    
                    </tbody>
                    </table>
                </div>
                </div><div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"position":"fixed"}}  >
                    <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"overflowY":"auto"}}>
                    <table className="table_v2" style={{"width":"100%"}}>
                    <thead>
                    <tr><th colSpan="2" style={{"fontSize":"18px"}}>Valoramos tu opinión, te tomará menos de 5 minutos</th></tr>
                    </thead>
                    <tbody>
                    <tr><td style={{"fontSize":"18px","textAlign":"center"}}><b>Local {this.state.nombre_local}</b></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Nombre completo</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Juan Pérez"} name={"nombre"} style={{"width":"90%"}} value={this.state.nombre} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Teléfono (Opcional)</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Ejm: 0987654321"} name={"telefono"} style={{"width":"90%"}} value={this.state.telefono} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Correo Electrónico:</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Ejm: correo@mail.com"} name={"correo"} style={{"width":"90%"}} value={this.state.correo} return_data={this.return_data} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>De Dónde nos cononces?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtags">
                    {data_ch1.map((option,index) => (
                            <li key={index}><input
                            type="checkbox"
                            id={option.id}
                            value={option.name}
                            checked={this.state.selectedOptions1.includes(option.id)}
                            onChange={() => this.handleCheckboxChange1(option.id)}
                            />
                            <label htmlFor={option.id}>{option.name}</label></li>
                    ))}
                        </ul>
                        </td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Tu fecha de cumpleaños (opcional)</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Personas en la mesa?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtags">
                    {data_ch2.map((option,index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={option.id}
                            value={option.name}
                            checked={this.state.selectedOptions2.includes(option.id)}
                            onChange={() => this.handleCheckboxChange2(option.id)}
                            />
                            <label htmlFor={option.id}>{option.name}</label></li>
                    ))}
                    </ul>
                    </td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Cuantó gastaste por persona?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtags"> 
                    {data_ch3.map((option,index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={option.id}
                            value={option.name}
                            checked={this.state.selectedOptions3.includes(option.id)}
                            onChange={() => this.handleCheckboxChange3(option.id)}
                            />
                        <label htmlFor={option.id}>{option.name}</label></li>
                    ))}</ul></td>
                    </tr>
                    <tr><td style={{"fontSize":"18px"}}>Cómo nos calificas?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                    <ul class="ks-cboxtagsface">
                        {data_ch4.map((option,index) => (
                            <li key={index}>
                                <input
                                type="checkbox"
                                id={option.id}
                                value={option.name}
                                checked={this.state.selectedOptions4.includes(option.id)}
                                onChange={() => this.handleCheckboxChange4(option.id)}
                                />
                                <label htmlFor={option.id}><font>{option.name}</font><img style={{"width":"60px","height":"60px"}} src={"https://grupomn.net/app/img/cara"+(index+1)+".png"} /></label>
                            </li>
                        ))}</ul></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>En qué podemos mejorar?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}>
                        <ul class="ks-cboxtags">   
                        {data_ch5.map((option,index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={"mej"+option.id}
                            value={option.name}
                            checked={this.state.selectedOptions5.includes(option.id)}
                            onChange={() => this.handleCheckboxChange5(option.id)}
                            />
                            <label htmlFor={"mej"+option.id}>{option.name}</label></li>
                    ))}</ul></td></tr>
                    <tr><td style={{"fontSize":"18px"}}>Compartenos detalles sobre tu experiencia en este lugar?</td></tr>
                    <tr><td style={{"fontSize":"18px"}}><GetInput placeholder={"Observación"} name={"observacion"} style={{"width":"90%"}} value={this.state.observacion} return_data={this.return_data} /></td></tr>
                    
                    </tbody>
                    </table>
                </div>
                </div>*/
      actualizar_select=()=>{
        const select = [{
          type: 'consulta', data:
          [{
            table : "catalogo",
            field :[{
              name : 'activo',
              value : '1',
              type:'integer'
            },{
              name : 'tipo',
              value : '24',
              type:'integer'
            }],
            order_by: "id_catalogo",
            return:['id_catalogo as id','catalogo as name']
          },{
            table : "catalogo",
            field :[{
              name : 'activo',
              value : '1',
              type:'integer'
            },{
              name : 'tipo',
              value : '25',
              type:'integer'
            }],
            order_by: "id_catalogo",
            return:['id_catalogo as id','catalogo as name']
          },{
            table : "catalogo",
            field :[{
              name : 'activo',
              value : '1',
              type:'integer'
            },{
              name : 'tipo',
              value : '26',
              type:'integer'
            }],
            order_by: "id_catalogo",
            return:['id_catalogo as id','catalogo as name']
          },{
            table : "catalogo",
            field :[{
              name : 'activo',
              value : '1',
              type:'integer'
            },{
              name : 'tipo',
              value : '27',
              type:'integer'
            }],
            order_by: "id_catalogo",
            return:['id_catalogo as id','catalogo as name']
          },{
            table : "catalogo",
            field :[{
              name : 'activo',
              value : '1',
              type:'integer'
            },{
              name : 'tipo',
              value : '28',
              type:'integer'
            }],
            order_by: "id_catalogo",
            return:['id_catalogo as id','catalogo as name']
          },{
            table : "`local`",
            field :[{
              name : 'uuid_local',
              value : this.state.uuid_local,
              type:'string'
            }],
            order_by: "id_local",
            return:['id_local','nombre_local']
          }]
        }]
        var result = Selectbd(select)
        if(result){
          result.then((value) => {
            if(value[1].status==="ok"&&value[2].status==="ok"&&value[3].status==="ok"&&value[4].status==="ok"&&value[5].status==="ok"&&value[6].status==="ok"){
              this.setState({
                data_ch1: value[1].result,
                data_ch2: value[2].result ,
                data_ch3: value[3].result ,
                data_ch4: value[4].result ,
                data_ch5: value[5].result ,
                nombre_local:value[6].result[0].nombre_local,
                id_local:value[6].result[0].id_local
              })
            }
          });
        }

        

      }
      componentDidMount (){
        this.actualizar_select();
          fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {
            //console.log(data.ip)
            this.setState({ ip: data.ip });
          })
          .catch(error => {
            console.error('Error fetching the IP', error);
          });
      }
}

export default getsurvey;
