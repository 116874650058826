import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getClientes extends Component {
  //id_cliente, razon_social, telefono, direccion, correo_electronico, id_estado, eliminado, codigo
  constructor(){/**********/
    super();
    this.state = {
        id_cliente:0,
        razon_social:"",
        codigo:"",
        telefono:"",
        direccion:"",
        id_estado:-1,
        correo_electronico:"",
        data_estado: [],
        data_cliente:[],
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    }
  }
  return_limpiar = ()=>{
    this.setState ({
        id_cliente:0,
        razon_social:"",
        codigo:"",
        telefono:"",
        direccion:"",
        id_estado:-1,
        correo_electronico:"",
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    })
  }
  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())

  }

  grabar = ()=>{

    const id_cliente = this.state.id_cliente

    if(!validate_text(this.state.razon_social)){
      alert('Ingrese Razón Social')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.codigo)){
      alert('Ingrese Código')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.telefono)){
      alert('Ingrese Teléfono')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.direccion)){
      alert('Ingrese Dirección')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.correo_electronico)){
      alert('Ingrese Correo Electrónico')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    var select
    if(Number(id_cliente)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'cliente',
          field :[{
            name : 'razon_social',
            value : this.state.razon_social,
            type:'string'
          },{
            name : 'codigo',
            value : this.state.codigo,
            type :'string'
          },{
            name : 'telefono',
            value : this.state.telefono,
            type :'string'
          },{
            name : 'direccion',
            value : this.state.direccion,
            type :'string'
          },{
            name : 'correo_electronico',
            value : this.state.correo_electronico,
            type :'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          return:['id_cliente']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'cliente',
          field :[{
            name : 'razon_social',
            value : this.state.razon_social,
            type:'string'
          },{
            name : 'codigo',
            value : this.state.codigo,
            type :'string'
          },{
            name : 'telefono',
            value : this.state.telefono,
            type :'string'
          },{
            name : 'direccion',
            value : this.state.direccion,
            type :'string'
          },{
            name : 'correo_electronico',
            value : this.state.correo_electronico,
            type :'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          id_name : "id_cliente",
          id_value : id_cliente,
          id_type : "integer",
          return:['id_cliente']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  //id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, id_estado, correo_electronico
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "cliente",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "codigo",
        return:['id_cliente','razon_social','codigo','telefono','direccion','correo_electronico','id_estado']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_cliente : value[1].result
          })
        }else{
          this.setState({
            data_cliente : []
          })
        }
      });
    }
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_estado : value[1].result
          })
        }
      });
    }
  }

  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'cliente',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_cliente",
          id_value : data.id_cliente,
          id_type : "integer",
          return:['id_cliente']
        }]
      }]
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
        id_cliente:data.id_cliente,
        razon_social:data.razon_social,
        codigo:data.codigo,
        telefono:data.telefono,
        direccion:data.direccion,
        id_estado:data.id_estado,
        correo_electronico:data.correo_electronico
      })
    }
  }
  render() {

    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Razón Social"} name={"razon_social"} value={this.state.razon_social} return_data={this.return_data} />
          <GetInput label={"Código"} name={"codigo"}  value={this.state.codigo} return_data={this.return_data}  />
          <GetInput label={"Teléfono"} name={"telefono"} value={this.state.telefono} return_data={this.return_data} />
          <GetInput label={"Dirección"} name={"direccion"} value={this.state.direccion} return_data={this.return_data} />
          <GetInput label={"Correo Electrónico"} name={"correo_electronico"} value={this.state.correo_electronico} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_usuario"} data={this.state.data_cliente}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getClientes;
