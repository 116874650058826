import React, { Component } from 'react';
import Selectbd from './../bd/getDb'; 
 
class getUltimasVentas extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            data:[]
        }
         
    }
     
    render() {
        const data = this.state.data
        return (
          <React.Fragment>
             
                <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"position":"fixed"}}  >
                    <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco" style={{"overflowY":"auto"}}>
                    <table className="table_v2" style={{"width":"90%"}}>
                    <thead>
                    <tr><th colSpan="2" style={{"fontSize":"18px"}}>Lista de Ventas por Local</th></tr>
                    </thead>
                    <tbody>
                    
                    {data.map((option,index) => (
                        <tr key={index}><td style={{"fontSize":"18px"}}>{option.etiqueta}</td><td>{option.fecha}</td></tr>
                    ))}
                    </tbody>
                    </table>
                </div>
                </div>
              
           </React.Fragment>
        )
      }
      actualizar_select=()=>{
        const select = [{
          type: 'consulta', data:
          [{
            table : "(SELECT etiqueta,date_format(fecha_ventas,'%Y-%m-%d') as fecha FROM usuario_globales where activo=1 order by fecha_ventas)",
            field :[],
            order_by: "fecha",
            return:['fecha','etiqueta']
          }]
        }]
        var result = Selectbd(select)
        if(result){
          result.then((value) => {
            if(value[1].status==="ok"){
              this.setState({
                data: value[1].result 
              })
            }
          });
        }

        

      }
      componentDidMount (){
        this.actualizar_select();
      }
}

export default getUltimasVentas;
