import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getIndices extends Component {
  constructor(){
    super();
    this.state = {
        id_indice:0,
        indice:'',
        id_estado:-1,
        data_estado:[],
        btn_limpiar_enabled:false,
        btn_grabar_enabled:false,
        data_indice:[]
    }
  }

  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'auditoria_indice',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_auditoria_indice",
          id_value : data.id_indice,
          id_type : "integer",
          return:['id_auditoria_indice']
        }]
      }]
      //console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }

  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())
  }
  grabar = ()=>{
    const id_indice = this.state.id_indice
    if(!validate_text(this.state.indice)){
      alert('Ingrese Índice')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }


    var select
    if(Number(id_indice)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'auditoria_indice',
          field :[{
            name : 'indice',
            value : this.state.indice,
            type:'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          return:['id_auditoria_indice']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'auditoria_indice',
          field :[{
            name : 'indice',
            value : this.state.indice,
            type:'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          id_name : "id_auditoria_indice",
          id_value : id_indice,
          id_type : "integer",
          return:['id_auditoria_indice']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  return_limpiar = ()=>{
    this.setState ({
      id_indice:0,
      indice:'',
      id_estado:-1,
      btn_limpiar_enabled:false,
      btn_grabar_enabled:false
    })
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
        id_indice:data.id_auditoria_indice,
        indice:data.indice,
        id_estado:data.id_estado,
        })
    }
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }

  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_estado : value[1].result
          })
        }
      });
    }
  }
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "auditoria_indice",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "indice",
        return:['id_auditoria_indice','indice','id_estado']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_indice : value[1].result
          })
        }else{
          this.setState({
            data_indice : []
          })
        }
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Índice"} name={"indice"} value={this.state.indice} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_indice"} data={this.state.data_indice}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
       </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getIndices;
