import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getLocales extends Component {
  constructor(){
    super();
    this.state = {
        id_local:0,
        codigo_local:'',
        nombre_local:'',
        razon_social:'',
        ruc:'',
        id_estado:-1,
        id_forma_juridica:-1,
        direccion:'',
        id_provincia:-1,
        id_parroquia:-1,
        id_canton:-1,
        sector:'',
        telefono:'',
        administrador:'',
        correo_electronico:'',
        id_tipo_local:-1,
        id_tipo_precio:-1,
        id_tipo_pantalla_tactil:-1,
        data_estado:[],
        data_forma_juridica:[],
        data_provincia:[],
        data_canton:[],
        data_parroquia:[],
        data_tipo_local:[],
        data_tipo_precio:[],
        data_tipo_precio:[],
        btn_limpiar_enabled:false,
        btn_grabar_enabled:false,
        data_local:[]
    }
  }

  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'local',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_local",
          id_value : data.id_local,
          id_type : "integer",
          return:['id_local']
        }]
      }]
      console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }

  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())
  }
  grabar = ()=>{
    const id_local = this.state.id_local
    if(!validate_text(this.state.codigo_local)){
      alert('Ingrese Código Local')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.nombre_local)){
      alert('Ingrese Nombre del Local')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_forma_juridica)===-1){
      alert('Seleccione Forma Jurídica')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_provincia)===-1){
      alert('Seleccione Provincia')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_canton)===-1){
      alert('Seleccione Cantón')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_parroquia)===-1){
      alert('Seleccione Parroquia')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_tipo_local)===-1){
      alert('Seleccione Tipo Local')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_tipo_precio)===-1){
      alert('Seleccione Tipo Precio')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_tipo_pantalla_tactil)===-1){
      alert('Seleccione Tipo Pantalla Táctil')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    var select
    if(Number(id_local)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'local',
          field :[{
            name : 'codigo_local',
            value : this.state.codigo_local,
            type:'string'
          },{
            name : 'nombre_local',
            value : this.state.nombre_local,
            type :'string'
          },{
            name : 'razon_social',
            value : this.state.razon_social,
            type :'string'
          },{
            name : 'ruc',
            value : this.state.ruc,
            type :'string'
          },{
            name : 'direccion',
            value : this.state.direccion,
            type :'string'
          },{
            name : 'sector',
            value : this.state.sector,
            type :'string'
          },{
            name : 'telefono',
            value : this.state.telefono,
            type :'string'
          },{
            name : 'administrador',
            value : this.state.administrador,
            type :'string'
          },{
            name : 'correo_electronico',
            value : this.state.correo_electronico,
            type :'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'id_forma_juridica',
            value : this.state.id_forma_juridica,
            type :'integer'
          },{
            name : 'id_provincia',
            value : this.state.id_provincia,
            type :'integer'
          },{
            name : 'id_canton',
            value : this.state.id_canton,
            type :'integer'
          },{
            name : 'id_parroquia',
            value : this.state.id_parroquia,
            type :'integer'
          },{
            name : 'id_tipo_local',
            value : this.state.id_tipo_local,
            type :'integer'
          },{
            name : 'id_tipo_precio',
            value : this.state.id_tipo_precio,
            type :'integer'
          },{
            name : 'id_tipo_pantalla_tactil',
            value : this.state.id_tipo_pantalla_tactil,
            type :'integer'
          }],
          return:['id_local']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'local',
          field :[{
            name : 'codigo_local',
            value : this.state.codigo_local,
            type:'string'
          },{
            name : 'nombre_local',
            value : this.state.nombre_local,
            type :'string'
          },{
            name : 'razon_social',
            value : this.state.razon_social,
            type :'string'
          },{
            name : 'ruc',
            value : this.state.ruc,
            type :'string'
          },{
            name : 'direccion',
            value : this.state.direccion,
            type :'string'
          },{
            name : 'sector',
            value : this.state.sector,
            type :'string'
          },{
            name : 'telefono',
            value : this.state.telefono,
            type :'string'
          },{
            name : 'administrador',
            value : this.state.administrador,
            type :'string'
          },{
            name : 'correo_electronico',
            value : this.state.correo_electronico,
            type :'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'id_forma_juridica',
            value : this.state.id_forma_juridica,
            type :'integer'
          },{
            name : 'id_provincia',
            value : this.state.id_provincia,
            type :'integer'
          },{
            name : 'id_canton',
            value : this.state.id_canton,
            type :'integer'
          },{
            name : 'id_parroquia',
            value : this.state.id_parroquia,
            type :'integer'
          },{
            name : 'id_tipo_local',
            value : this.state.id_tipo_local,
            type :'integer'
          },{
            name : 'id_tipo_precio',
            value : this.state.id_tipo_precio,
            type :'integer'
          },{
            name : 'id_tipo_pantalla_tactil',
            value : this.state.id_tipo_pantalla_tactil,
            type :'integer'
          }],
          id_name : "id_local",
          id_value : id_local,
          id_type : "integer",
          return:['id_local']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  return_limpiar = ()=>{
    this.setState ({
      id_local:0,
      codigo_local:'',
      nombre_local:'',
      razon_social:'',
      ruc:'',
      id_estado:-1,
      id_forma_juridica:-1,
      direccion:'',
      id_provincia:-1,
      id_parroquia:-1,
      id_canton:-1,
      sector:'',
      telefono:'',
      administrador:'',
      correo_electronico:'',
      id_tipo_local:-1,
      id_tipo_precio:-1,
      id_tipo_pantalla_tactil:-1,
      btn_limpiar_enabled:false,
      btn_grabar_enabled:false
    })
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
        id_local:data.id_local,
        codigo_local:data.codigo_local,
        nombre_local:data.nombre_local,
        razon_social:data.razon_social,
        ruc:data.ruc,
        id_estado:data.id_estado,
        id_forma_juridica:data.id_forma_juridica,
        direccion:data.direccion,
        id_provincia:data.id_provincia,
        id_parroquia:data.id_parroquia,
        id_canton:data.id_canton,
        sector:data.sector,
        telefono:data.telefono,
        administrador:data.administrador,
        correo_electronico:data.correo_electronico,
        id_tipo_local:data.id_tipo_local,
        id_tipo_precio:data.id_tipo_precio,
        id_tipo_pantalla_tactil:data.id_tipo_pantalla_tactil
      })
      this.return_data_provincia("id_provincia",data.id_provincia,data.id_canton)
      this.return_data_canton("id_canton",data.id_canton,data.id_parroquia)
    }
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }
  return_data_provincia =(name,value,id)=>{
    this.setState({
      [name]:value
    })
    const select = [{
      type: 'consulta', data:
      [{
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '7',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        },{
          name : 'sw_padre',
          value :value,
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          let id_canton =-1
          if(id){
            id_canton =id
          }
          this.setState({
            data_canton: value[1].result,
            id_canton:id_canton
          })
        }
      });
    }
  }
  return_data_canton =(name,value,id)=>{
    this.setState({
      [name]:value
    })
    const select = [{
      type: 'consulta', data:
      [{
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '8',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        },{
          name : 'sw_padre',
          value :value,
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          let id_parroquia =-1
          if(id){
            id_parroquia =id
          }
          this.setState({
            data_parroquia: value[1].result,
            id_parroquia:id_parroquia
          })
        }
      });
    }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '2',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      },
      {
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      },
      {
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '6',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      },
      {
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '3',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      },
      {
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '4',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      },
      {
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '5',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"&&value[3].status==="ok"&&value[4].status==="ok"&&value[5].status==="ok"&&value[6].status==="ok"){
          this.setState({
            data_forma_juridica: value[1].result,
            data_estado : value[2].result,
            data_provincia : value[3].result,
            data_tipo_local : value[4].result,
            data_tipo_precio : value[5].result,
            data_tipo_pantalla_tactil: value[6].result
          })
        }
      });
    }
  }
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "local",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "codigo_local",
        return:['id_local','codigo_local','nombre_local','razon_social','ruc','id_estado','id_forma_juridica','direccion','id_provincia','id_canton','id_parroquia','sector','telefono','administrador','correo_electronico','id_tipo_local','id_tipo_precio','id_tipo_pantalla_tactil']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_local : value[1].result
          })
        }else{
          this.setState({
            data_local : []
          })
        }
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Código Local"} name={"codigo_local"} value={this.state.codigo_local} return_data={this.return_data} />
          <GetInput label={"Nombre Local"} name={"nombre_local"} value={this.state.nombre_local} return_data={this.return_data} />
          <GetInput label={"Razón Social"} name={"razon_social"} value={this.state.razon_social} return_data={this.return_data} />
          <GetInput label={"RUC"} name={"ruc"} value={this.state.ruc} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetSelect label={"Forma Jurídica"} name={"id_forma_juridica"} data={this.state.data_forma_juridica} value={this.state.id_forma_juridica} return_data={this.return_data} />
          <GetSelect label={"Provincia"} name={"id_provincia"} data={this.state.data_provincia} value={this.state.id_provincia} return_data={this.return_data_provincia} />
          <GetSelect label={"Cantón"} name={"id_canton"} data={this.state.data_canton} value={this.state.id_canton} return_data={this.return_data_canton} />
          <GetSelect label={"Parroquia"} name={"id_parroquia"} data={this.state.data_parroquia} value={this.state.id_parroquia} return_data={this.return_data} />
          <GetInput label={"Sector"} name={"sector"} value={this.state.sector} return_data={this.return_data} />
          <GetInput label={"Teléfono"} name={"telefono"} value={this.state.telefono} return_data={this.return_data} />
          <GetInput label={"Administrador"} name={"administrador"} value={this.state.administrador} return_data={this.return_data} />
          <GetInput label={"Correo Electrónico"} name={"correo_electronico"} value={this.state.correo_electronico} return_data={this.return_data} />
          <GetSelect label={"Tipo de Local"} name={"id_tipo_local"} data={this.state.data_tipo_local} value={this.state.id_tipo_local} return_data={this.return_data} />
          <GetSelect label={"Tipo de Precio"} name={"id_tipo_precio"} data={this.state.data_tipo_precio} value={this.state.id_tipo_precio} return_data={this.return_data} />
          <GetSelect label={"Pantalla Táctil"} name={"id_tipo_pantalla_tactil"} data={this.state.data_tipo_pantalla_tactil} value={this.state.id_tipo_pantalla_tactil} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_local"} data={this.state.data_local}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
       </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getLocales;
