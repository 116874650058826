import React, { Component } from 'react';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import GetFormularioNewLogistica from './getFormularioNewLogistica';
import DatePicker from "react-datepicker";
//ID CATALOGO  =20
class getListaLogistica extends Component {
  constructor(props){
    super(props);
    this.state = {
        id_formulario_cabecera:0,
        id_formulario:0,
        data_formulario:[],
        id_local:0,
        data_local:[],
        data_usuario:[],
        id_usuario:0,
        btn_limpiar_enabled:false,
        data_detalle:[],
        date_desde : new Date(),
        date_hasta : new Date()
    }
  }
  onChangeDesde = date => this.setState({ date_desde:date })

  onChangeHasta = date => this.setState({ date_hasta:date })

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    },()=>{
      this.actualizar_tabla_auditoria()
    })
  }
  actualizar_tabla_auditoria =()=>{
    //console.log("(SELECT c.id_formulario,c.id_formulario_cabecera,f.formulario,nombre_local,cast(mid(c.fecha_emision,1,10) as char) as fecha,if(c.id_estado=0,'Pendiente',if(c.id_estado=1,'Finalizado','Anulado')) as estado,c.id_estado FROM formulario_cabecera c inner join formulario f on f.id_formulario=c.id_formulario inner join `local`l on l.id_local=c.id_local where id_usuario="+Number(localStorage.getItem("neolav2_uuid_usuario"))+" order by c.id_estado,c.fecha_emision)")
    let sql_cons =""

    const id_formulario = Number(this.state.id_formulario)
    if(Number(id_formulario)===0||Number(id_formulario)===-1){
      sql_cons=""
    }else{
      sql_cons="c.id_formulario="+id_formulario+" and "
    }
    let  sql_usuario=" c.id_usuario="+Number(localStorage.getItem("neolav2_uuid_usuario"))+" "
    if(Number(localStorage.getItem("neolav2_id_tipo_rol"))==4647){
      if(Number(this.state.id_usuario)>0){
        sql_usuario=" c.id_usuario ="+Number(this.state.id_usuario)
      }else{
        sql_usuario=" c.id_usuario>0 "
      }
    }

    const select = [{
     type: 'consulta', data:
     [{
       table : "(SELECT concat(apellidos,' ',nombres) as nom,c.id_formulario,c.id_formulario_cabecera,f.formulario,cast(catalogo as char) as Placa,cast(c.fecha_emision as char) as fecha,if(c.id_estado=0,'Pendiente',if(c.id_estado=1,'Finalizado',if(c.id_estado=2,'Enviado','Anulado'))) as estado,c.id_estado,cast(concat(c.avance,'%') as char) as avance FROM formulario_cabecera c inner join formulario f on f.id_formulario=c.id_formulario inner join catalogo l on l.id_catalogo=c.id_local and l.tipo=22 and f.tipo=1 inner join usuario u on u.id_usuario=c.id_usuario where "+sql_cons+"  "+sql_usuario+" order by c.fecha_emision DESC)",
       field :[],
       order_by: "fecha DESC",
       return:['id_formulario_cabecera', 'formulario', 'Placa', 'fecha', 'estado','avance', 'id_estado','id_formulario','nom']
     }]
   }]
   var result = Selectbd(select)
   if(result){
     result.then((value) => {
       if(value[1].status==="ok"){
         this.setState({
           data_auditorias : value[1].result
         })
       }else{
         this.setState({
           data_auditorias : []
         })
       }
     });
   }
  }

get_loading =(msg_loading)=>{
  this.setState({
    msg_loading
  })
}

 actualizar_tabla =()=>{
   const id_formulario = Number(this.state.id_formulario)
   const select = [{
    type: 'consulta', data:
    [{
      table : "(SELECT f.id_formulario_detalle,c.catalogo,cast(cs.catalogo as char) as subcategoria,i.indice,calificacion,sw_imagenes,txt_id_etiquetas,f.orden FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice left join catalogo cs on cs.id_catalogo=f.id_subcategoria)",
      field :[],
      order_by: "orden,id_formulario_detalle",
      return:['id_formulario_detalle', 'catalogo', 'subcategoria', 'indice', 'calificacion', 'sw_imagenes','txt_id_etiquetas']
    }]
  }]
  var result = Selectbd(select)
  if(result){
    result.then((value) => {
      if(value[1].status==="ok"){
        this.setState({
          data_detalle : value[1].result
        })
      }else{
        this.setState({
          data_detalle : []
        })
      }
      this.get_loading(false)
    });
  }
  this.get_loading(true)
 }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "formulario",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        },{
          name : 'tipo',
          value : '1',
          type:'integer'
        }],
        order_by: "formulario",
        return:['id_formulario as id','formulario as name']
      },{
        table : '`local`',
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        },{
          name : 'id_estado',
          value :'1',
          type:'integer'
        }],
        return:['id_local as id','nombre_local as name']
      },{
        table : '`local`',
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        },{
          name : 'id_estado',
          value :'1',
          type:'integer'
        }],
        return:['id_local as id','nombre_local as name']
      },{
        table : "(select id_usuario,concat(apellidos,' ',nombres) as nom from usuario where id_tipo_rol in(4552,4647) order by apellidos)",
        field :[],
        return:['id_usuario as id','nom as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"&&value[4].status==="ok"){
          this.setState({
            data_formulario: value[1].result,
            data_local: value[2].result,
            data_usuario: value[4].result
          })
        }
      });
    }
  }
  return_limpiar = ()=>{

    const id_local = Number(this.state.id_local)
    const id_formulario = Number(this.state.id_formulario)
    if(id_formulario===0){
      alert("Seleccione Formulario")
      return null
    }
    if(id_local===0){
      alert("Seleccione Local")
      return null
    }

 let fecha_emision = this.state.date_desde
    var select = [{
      type: 'insertar', data:
      [{
        table : 'formulario_cabecera',
        field :[{
          name : 'id_formulario',
          value : id_formulario,
          type:'integer'
        },{
          name : 'id_local',
          value : id_local,
          type :'integer'
        },{
          name : 'id_usuario',
          value : Number(localStorage.getItem("neolav2_uuid_usuario")),
          type :'integer'
        },{
          name : 'id_estado',
          value : 0,
          type :'integer'
        },{
          name : 'fecha_creacion',
          value : this.formatDate(new Date()),
          type :'string'
        },{
          name : 'fecha_emision',
          value : this.formatDate(fecha_emision),
          type :'string'
        }],
        return:['id_formulario_cabecera']
      }]
    }]
    var result = Selectbd(select)

    if(result){
        result.then((value) => {
          console.log(value)
            this.setState ({
              btn_limpiar_enabled:false,id_formulario_cabecera : value[1].result[0].id_formulario_cabecera
            },()=>{
              this.actualizar_tabla()
            })
        })
    }

  }
  return_limpiarT =()=>{
    this.setState ({
      id_formulario_cabecera:0,
      data_detalle:[]
    })
  }
  formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }
  render() {
    const msg_loading = this.state.msg_loading
    let modal_loading
    if(msg_loading){
      modal_loading = <div id="loaderf" className="loaderf loaderf-default is-active" data-text=""></div>
    }
    let dat
    if(Number(localStorage.getItem("neolav2_id_tipo_rol"))==4647){
      dat = <GetSelect style={"select-css"} width={"100%"} label={""} name={"id_usuario"} data={this.state.data_usuario} value={this.state.id_usuario} return_data={this.return_data} />
    }else{
      dat = localStorage.getItem("neolav2_nombres")
    }

    return (
      <React.Fragment>
        <div id="loader_msg" className="loader_msg loader_msg-default is-active" data-text="">
            <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
              <div className="cssversion_pw100 cssversion_ph100 " style={{"overflowY":"auto"}} >

                <table className="table_v2" style={{"width":"100%"}}>
                <thead>
                 <tr><th colSpan="2" style={{"fontSize":"18px"}}>Lista de Logísticas</th></tr>
                </thead>
                <tbody>
                 <tr><td style={{"fontSize":"18px"}}>Usuario:</td><td style={{"fontSize":"18px"}}>{dat}</td></tr>
                 <tr><td style={{"fontSize":"18px"}}>Tipo:</td><td><GetSelect style={"select-css"} width={"100%"} label={""} name={"id_formulario"} data={this.state.data_formulario} value={this.state.id_formulario} return_data={this.return_data} /></td></tr>
                </tbody>
                </table>

                <table className="table_v2" style={{"width":"100%"}}>
                  <thead>
                   <tr><th>Placa</th><th>Fecha</th><th>Estado</th><th style={{"textAlign":"right"}}>Avance</th><th colSpan="3">Opciones</th></tr>
                  </thead>
                  <tbody>
                    {(this.state.data_auditorias) ?
                      this.state.data_auditorias.map(row => (
                        <React.Fragment>
                        <tr><td colSpan="6" style={{"textAlign":"center"}}><b>{row.formulario}
                        {(Number(localStorage.getItem("neolav2_id_tipo_rol"))==4647&&Number(this.state.id_usuario)==0)? " ("+row.nom+") " :null} 
                        
                        </b></td></tr>
                        <tr>
                        <td >{row.Placa}</td><td >{row.fecha}</td><td style={(row.estado==="Pendiente")?{"backgroundColor":"#ffcfcf"}:(row.estado==="Finalizado")?{"backgroundColor":"#83ff6d"}:{"backgroundColor":"#fcffc6"}} >{row.estado}</td><td style={{"textAlign":"right"}} >{row.avance}</td>
                          {(row.estado!=="Enviado"&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!=4647)?
                          <td onClick={ () => this.getEditarFormulario(row.id_formulario_cabecera,row.id_estado,row.id_formulario)}>Editar</td>
                          :<td></td>}
                          <td onClick={ () => this.getPdFFormulario(row.id_formulario_cabecera,row.id_estado,row.id_formulario)}>PDF</td>
                          {(row.estado==="Finalizado")?
                          <td onClick={ () => this.getMailFormulario(row.id_formulario_cabecera,row.id_estado,row.id_formulario)}>Enviar Mail</td>
                          :<td></td>}
                        </tr>
                        </React.Fragment>
                      ))
                      : <React.Fragment></React.Fragment>
                    }
                  </tbody>
                </table>
                  <GetButton classname={"button_v2"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} name={"Salir"} value={"Salir"}  return_accion={this.props.setSubModulo}  />
                </div>
              {(this.state.data_detalle&&this.state.data_detalle.length>0)?
              <GetFormularioNewLogistica  return_limpiarT ={this.return_limpiarT} firebase={this.props.firebase} id_formulario_cabecera={this.state.id_formulario_cabecera} id_formulario={this.state.id_formulario} data_detalle ={this.state.data_detalle} />
              :null}
          </div>
          </div>
          {modal_loading}
       </React.Fragment>
    )
  }
  getEditarFormulario=(id_formulario_cabecera,sw_estado,id_formulario)=>{
    this.setState ({
      btn_limpiar_enabled:false,id_formulario_cabecera : id_formulario_cabecera,id_formulario
    },()=>{
      this.actualizar_tabla()
    })
  }
  getPdFFormulario=(id_formulario_cabecera,sw_estado,id_formulario)=>{
      this.get_loading(true)
      window.open("https://grupomn.net/centralizacion/api/neolav2/public/index.php/database/getPDFAuditoria/"+id_formulario_cabecera+"/"+id_formulario)
      this.get_loading(false)
  }

  getMailFormulario=(id_formulario_cabecera,sw_estado,id_formulario)=>{
      if (window.confirm("Desea Enviar Informe")) {
        const url= "https://grupomn.net/centralizacion/api/neolav2/public/index.php/database/getEnvioAuditoria/"+id_formulario_cabecera+"/"+id_formulario
        var obj = { accion:'getvalidatemoresign'}
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(obj),
          headers:{
            'Accept':'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(res => res.json())
        .then((data) => {
          this.actualizar_tabla_auditoria();
          this.get_loading(false)
        })
        .catch(console.log)
        this.get_loading(true)
      }
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla_auditoria();
  }

}

export default getListaLogistica;
