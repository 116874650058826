import React, { Component } from 'react';

import './../css_generales/getUploadImage.css';
import { v4 as uuidv4 } from 'uuid';
let storage
class getUploadImage extends Component {
  constructor(props){
    super(props);
    this.state = {
      image : null,
      progress: 0,
      url :"https://www.grupomn.net/app/img/sinfoto.png",
      width: this.props.width,
      height: this.props.height,
      token_image:this.props.token_image,
      style:{"width":this.props.width,"height":this.props.height},
      msg: this.props.width+'-'+this.props.height,
      uuid_image:'',
      uuid_image_2:'',
      msg_loading:false
    }
  }
  isValid =(file)=>{
    //return /\.png?g$/i.test(file);
    return true;
  }


  tick () {

    const index  =this.props.index
    //console.log("pedido")
    this.props.getUUID(this.state.uuid_image,this.props.uuid,this.state.uuid_image_2,index)
    /*const uploadTask = storage.ref(`images/${this.state.uuid_image}${this.props.pref}.png`).getDownloadURL().then(
      (downloadURL) => {
        this.setState({
          token_image:downloadURL
        })
      }
    )*/
    this.stopTimer()
    this.get_loading(false)
  }
  startTimer () {
    clearInterval(this.timer)
    this.timer = setInterval(this.tick.bind(this), 2000)
  }
  stopTimer () {
    clearInterval(this.timer)
  }



  getUUID=(uuid,uuid2)=>{
    this.setState({
      uuid_image:uuid,
      uuid_image_2:uuid2
    },()=>{
      this.get_loading(true)
    })

    this.startTimer();




    //console.log(uuid)
    /*/*const uploadTask = storage60x60.ref(`images/${uuid}.png`).getDownloadURL().then(
      function(downloadURL) {
        console.log(this.state.url)
      }pref
    )*/
/*
    setTimeout(
        function() {
          storage.ref(`images/${uuid}${this.props.pref}.png`).getDownloadURL().then(
            (downloadURL) => {
              this.setState({
                token_image:downloadURL
              })
            }
          ).catch(error => {

            setTimeout(
                function() {
                storage.ref(`images/${uuid}${this.props.pref}.png`).getDownloadURL().then(
                    (downloadURL) => {
                      this.setState({
                        token_image:downloadURL
                      })
                    }
                  ).catch(error => console.log(error))
                }
                .bind(this),
                3000
            );



          })
        }
        .bind(this),
        3000
    );
*/
  }
  handleChange =(e)=>{
    if(e.target.files[0]){
      if(this.isValid(e.target.files[0].name)){
        this.setState({
          image:e.target.files[0]
        },()=>{
          this.handleUpload()
        })
      }else{
        this.setState({
          msg:"Solo Png"
        })
      }
    }
  }
  handleUpload_2 =(uuid_image)=>{
    storage = this.props.firebase.app().storage("gs://"+this.props.file_xxx_2)
    const image = this.state.image
    //const uuid_image = uuidv4()
    const uploadTask = storage.ref(`images/${uuid_image}.png`).put(image)
    uploadTask.on(
      "state_changed",
      snapshot =>{
        const progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100)
        if(progress>=99){
          this.setState({
            progress : 0
          })
        }else{
          this.setState({
            progress
          })
        }
      },
      error =>{
        this.setState({
          msg:error
        })
      },
      ()=>{
        this.getUUID(uuid_image+"_500x500",uuid_image+"_500x500")
      }
    )
  }
  handleUpload =()=>{
    //console.log("sss"this.props.file_xxx)
    storage = this.props.firebase.app().storage("gs://"+this.props.file_xxx)
    const image = this.state.image
    const uuid_image = uuidv4()
    const uploadTask = storage.ref(`images/${uuid_image}.png`).put(image)
    uploadTask.on(
      "state_changed",
      snapshot =>{
        const progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100)
        if(progress>=99){
          this.setState({
            progress : 0
          })
        }else{
          this.setState({
            progress
          })
        }
      },
      error =>{
        this.setState({
          msg:error
        })
      },
      ()=>{
          if(this.props.file_xxx_2){
            this.handleUpload_2(uuid_image+"_500x500")
            //this.getUUID(uuid_image,'')
          }else{
            this.getUUID(uuid_image+"_500x500",'')
          }
        /*if(this.props.file_xxx_2){
          let storage2 = this.props.firebase.app().storage("gs://"+this.props.file_xxx_2)

          const image = this.state.image
          const uuid_image_2 = uuid_image
          const uploadTask2 = storage2.ref(`images/${uuid_image_2}.png`).put(image)
          console.log(this.props.file_xxx_2)
          console.log(uuid_image_2)
          console.log(image)

          uploadTask2.on(
            "state_changed",
            snapshot2 =>{
              const progress = Math.round((snapshot2.bytesTransferred/snapshot2.totalBytes)*100)
              if(progress>=99){
                this.setState({
                  progress : 0
                })
              }else{
                this.setState({
                  progress
                })
              }
            },
            error =>{
              this.setState({
                msg:error
              })
            },
            ()=>{
              this.getUUID(uuid_image,uuid_image_2)
            }
          )*/
        //}else{

        //}



          //uploadTask.snapshot.ref.getDownloadURL().then(
          //(downloadURL) => {
            //setTimeout(this.getUUID(uuid_image),
              //  10000
          //  )
          //}
        //  );
          //setTimeout(
            //function() {

          //  }
          //  .bind(this),
          //  2000
        //);
      }
    )
  }
  get_loading =(msg_loading)=>{
    this.setState({
      msg_loading
    })
  }

  render() {
    //const token_image = this.state.token_image
    const token_image = this.props.token_image
    let modal_loading
    const msg_loading = this.state.msg_loading
    if(msg_loading){
      modal_loading = <div id="loader" className="loader loader-default is-active" data-text=""></div>
    }
    return (
      <React.Fragment>
      <div className="grid-container-upload" style={{"width":this.state.width}}>
        <div className="lbl_image_upload" style={this.state.style}>
        {(token_image)?
          <img style={this.state.style} alt="Cargando Imagen . . ." src={token_image} />
          :<img style={this.state.style} alt="Cargando Imagen . . ." src={this.state.url} />
        }
        </div>
        <div className="lbl_button_upload">
          <div className="upload-btn-wrapper">
            <button className="btn">Upload</button>
            <input type="file" name="myfile" className="custom-file-input" onChange={this.handleChange} />
          </div>
        </div>
        <div className="lbl_progress_upload">
          {(this.state.progress!==0)?
          <progress value={this.state.progress} max ="100" style={{"width":this.state.width}} />
          :''}
        </div>
        <div className="lbl_msg_upload" style={{"width":this.state.width}}>{this.state.msg}</div>
      </div>
      {modal_loading}
      </React.Fragment>
    )
  }
  componentDidMount (){

  }

}
export default getUploadImage;
//console.log(props.token_image)
