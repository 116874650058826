import React,{ Component} from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams
} from "react-router-dom";
import './App.css';
import './css/getVersion.css';
//import { MenuContext } from "react-flexible-sliding-menu";
//import GetStatusBd from './bd/getStatusBd';
import GetGlobalContent from './componentes/getGlobalContent';
import Getsurvey from './componentes/getsurvey';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBfMxt2IQ6izSdYmQ-Ie0gKedPwD2PLzwU",
  authDomain: "app-delivery-8b998.firebaseapp.com",
  databaseURL: "https://app-delivery-8b998.firebaseio.com",
  projectId: "app-delivery-8b998",
  storageBucket: "app-delivery-8b998-60x60",
  messagingSenderId: "74232381793",
  appId: "1:74232381793:web:09303378ca795bdeb48520",
  measurementId: "G-9J0C50L1ES"
}
firebase.initializeApp(firebaseConfig)

//const basepath = process.env.PUBLIC_URL
//let dir_url =  "http://localhost/neolav2/index.php/"

class App extends Component {
  render (){
    //var res = basepath.replace("https://www.grupomn.net", "")
    //if(basepath!==''){
  //    dir_url =  basepath.replace("/web", "/api/index.php/")
    //}
    //localStorage.setItem("notificacion_pedido",detalle)
    //console.log("pruebas")
    /*return (
        <Home/>
    )*/
    return(
      <Router>
          <Routes >
            <Route path={"/"} element={<Home opcion={'load'} />} />
            <Route path={"/survey"} element={<Home opcion={'survey'} />} />
            <Route path={"/survey/:data_return"} element={<Home opcion={'survey'} />} />
          </Routes > 
    </Router>
    )
  }
}
export default App
function Home({opcion}) {
  let { data_return } = useParams();
  //let basepath = process.env.PUBLIC_URL
  //console.log("URL "+basepath)
  //let { data_return } = useParams();
  //console.log("pruebas1")
    //let { data_return } = useParams();
  //  if(data_return ==='app'||data_return ==='centralizacion'||data_return ===''){
      //console.log('Bienvenido a Neolav2 :)')
      //if(localStorage.getItem("notificacion_pedido")){
        /*return(
          <Router>
              <Routes >
                <Route path={"/"} element={<Home opcion={'load'} />} />
                <Route path={"/survey"} element={<Home opcion={'survey'} />} />
                <Route path={"/survey/:data_return"} element={<Home opcion={'survey'} />} />
              </Routes > 
        </Router>
        )*/
    
      if(opcion==='survey'){
        //console.log(data_return)
        if(data_return!==undefined){
          return (
            <React.Fragment>
              <Getsurvey uuid_local={data_return} firebase={firebase}/>
            </React.Fragment>
          )
          return null
        }
      }
      return (
        <React.Fragment>
          <GetGlobalContent firebase={firebase}/>
        </React.Fragment>
      )
      //const { toggleMenu } = useContext(MenuContext);

  //  }else{
    ///  return (
    //    <React.Fragment>
      //   Dirección Errónea {data_return}
      //  </React.Fragment>
      //)
    //}
}

/*import React, { useState } from 'react';
import {  BrowserRouter as Router } from 'react-router-dom';
//import logo from './logo.svg';
import './App.scss';
import SideBar from './layout/sidebar/SideBarMenu'
import MainContent from './layout/main/MainContent'
import { GlobalAppContext } from './context';

function App() {
  const [toggled, setToggled] = useState(true);
  const [hasBackground, setHasBackground] = useState(true);

  let style = toggled ? "toggled" : "";
  style += hasBackground ? " sidebar-bg" : "";

  return (
    <Router>
      <GlobalAppContext.Provider
        value={{toggled, setToggled, hasBackground, setHasBackground}}
      >
        <div className={"page-wrapper default-theme bg1 "+ style  }>
          <SideBar />
          <MainContent />
        </div>
      </GlobalAppContext.Provider>
    </Router>
  );
}

export default App;*/
