import React, { Component } from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import 'react-big-calendar/lib/sass/styles.scss';
//import 'react-big-calendar/lib/addons/dragAndDrop/styles';

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})
class getCalendar extends Component {
  constructor(props){
     super();
  }

  render () {
    const now = new Date()
    const myEventsList = [
  {
    id: 0,
    title: 'Limpieza - Real Audiencia',
    allDay: true,
    start: new Date(2021, 8, 1),
    end: new Date(2021, 8, 1),
  },
  {
    id: 1,
    title: 'Limpieza - Nazareth',
    start: new Date(2021, 8, 5),
    end: new Date(2021, 8, 5),
  },

  {
    id: 2,
    title: 'Imagen Externa - Cumbayá',
    start: new Date(2021, 8, 10),
    end: new Date(2021, 8, 10),
  },

  {
    id: 3,
    title: 'Limpieza Cumbayá',
    start: new Date(2021, 8, 13),
    end: new Date(2021, 8, 13),
  }
]
   return (
     <React.Fragment>
      <div style={{"width":"100%","height":"100%","backgroundColor":"white"}}>
         <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
     </React.Fragment>
   )
  }
  componentDidUpdate(){
  }
}
export default getCalendar;
