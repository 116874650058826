import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import GetFormularioNewLogistica from './getFormularioNewLogistica';
import DatePicker from "react-datepicker";
import GetUploadImage from './getUploadImage';
import Moment from 'moment';
//ID CATALOGO  =20
class getTracking extends Component {
  constructor(props){
    super(props);
    this.state = {
        id_formulario:0,
        data_formulario:[],
        id_local:0,
        data_local:[],
        btn_limpiar_enabled:false,
        data_detalle:[],
        date_desde : new Date(),
        id_tracking:0,
        id_subtracking:0,
        id_local_tracking:0,
        data_local_tracking:[],
        id_ruta_tracking:0,
        sw_inicio:0,
        odometro:'',
        uuid_imagen:'',
        nombre_local:'',
    }
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }
 actualizar_tabla =()=>{
   const id_formulario = Number(this.state.id_formulario)
   const select = [{
    type: 'consulta', data:
    [{
      table : "(SELECT f.id_formulario_detalle,c.catalogo,cast(cs.catalogo as char) as subcategoria,i.indice,calificacion,sw_imagenes,txt_id_etiquetas,f.orden FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice left join catalogo cs on cs.id_catalogo=f.id_subcategoria)",
      field :[],
      order_by: "orden,id_formulario_detalle",
      return:['id_formulario_detalle', 'catalogo', 'subcategoria', 'indice', 'calificacion', 'sw_imagenes','txt_id_etiquetas']
    }]
  }]
  var result = Selectbd(select)
  if(result){
    result.then((value) => {
      if(value[1].status==="ok"){
        this.setState({
          data_detalle : value[1].result
        })
      }else{
        this.setState({
          data_detalle : []
        })
      }
    });
  }
 }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "rutas",
        field :[{
          name : 'activo',
          value : '1',
          type:'integer'
        } ],
        order_by: "ruta",
        return:['id_rutas as id','ruta as name']
      },{
        table : '`catalogo`',
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        },{
          name : 'id_estado',
          value :'1',
          type:'integer'
        },{
            name : 'tipo',
            value :'22',
            type:'integer'
          }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"){
          this.setState({
            data_formulario: value[1].result,
            data_local: value[2].result
          })
        }
      });
    }
  }
  return_limpiar = ()=>{

    const id_local = Number(this.state.id_local)
    const id_formulario = Number(this.state.id_formulario)
    let fecha_emision = this.state.date_desde
    if(id_formulario===0){
      alert("Seleccione Ruta")
      return null
    }
    if(id_local===0){
      alert("Seleccione Placa")
      return null
    }
    var sw_existencia = 0
    const select_verificar_existencia = [{
     type: 'consulta', data:
     [{
       table : "(SELECT * FROM tracking WHERE estado=0 and sw_cabecera=0 and id_usuario="+Number(localStorage.getItem("neolav2_uuid_usuario"))+" AND MID(inicia,1,10)='"+this.formatDate(fecha_emision)+"')",
       field :[],
       return:['id_tracking']
     }]
   }]
   //console.log("--> 1")
   var result_verificar_existencia = Selectbd(select_verificar_existencia)
   if(result_verificar_existencia){

    result_verificar_existencia.then((value) => {
        if(value[1].status==="ok"){
            sw_existencia=1
            
        }
        // id_tracking, id_router, id_transportista, id_usuario, fecha_creacion, sw_cabecera, inicia, finaliza, estado, id_local, fecha_llegada,
        //  fecha_salida, odometro_llegada, odometro_salida, imagen_llegada, imagen_salida, observacion_llegada, observacion_salidad
        if(sw_existencia===0){
            var select = [{
                type: 'insertar', data:
                [{
                  table : 'tracking',
                  field :[{
                    name : 'id_router',
                    value : id_formulario,
                    type:'integer'
                  },{
                    name : 'id_transportista',
                    value : id_local,
                    type :'integer'
                  },{
                    name : 'id_usuario',
                    value : Number(localStorage.getItem("neolav2_uuid_usuario")),
                    type :'integer'
                  },{
                    name : 'fecha_creacion',
                    value : Moment(fecha_emision).format('YYYY-MM-DD HH:mm:ss'),
                    type :'string'
                  },{
                    name : 'inicia',
                    value : Moment(fecha_emision).format('YYYY-MM-DD HH:mm:ss') ,
                    type :'string'
                  }],
                  return:['id_tracking']
                }]
              }]
              var result = Selectbd(select)
    
              if(result){
                  result.then((value) => {
                    this.getDataTracking()
                    this.get_loading(false)
                  })
              }
        }else{
            this.getDataTracking()
        }
        this.get_loading(false)
    })
    this.get_loading(true)
   }
  
/*
    var sw_existencia = 0
    const select_verificar_existencia = [{
     type: 'consulta', data:
     [{
       table : "(SELECT * FROM formulario_cabecera WHERE id_local="+id_local+" AND id_formulario="+id_formulario+" AND id_usuario="+Number(localStorage.getItem("neolav2_uuid_usuario"))+" AND MID(fecha_emision,1,10)='"+this.formatDate(fecha_emision)+"')",
       field :[],
       return:['id_formulario_cabecera']
     }]
   }]
   //console.log("--> 1")
   var result_verificar_existencia = Selectbd(select_verificar_existencia)
   if(result_verificar_existencia){
     result_verificar_existencia.then((value) => {
       if(value[1].status==="ok"){
           sw_existencia=1
          //  console.log("--> 2")
           this.setState ({
             btn_limpiar_enabled:false,id_formulario_cabecera : value[1].result[0].id_formulario_cabecera
           },()=>{
             this.actualizar_tabla()
           })
           this.get_loading(false)
       }
       if(sw_existencia===0){

          var select = [{
            type: 'insertar', data:
            [{
              table : 'formulario_cabecera',
              field :[{
                name : 'id_formulario',
                value : id_formulario,
                type:'integer'
              },{
                name : 'id_local',
                value : id_local,
                type :'integer'
              },{
                name : 'id_usuario',
                value : Number(localStorage.getItem("neolav2_uuid_usuario")),
                type :'integer'
              },{
                name : 'id_estado',
                value : 0,
                type :'integer'
              },{
                name : 'fecha_creacion',
                value : this.formatDate(new Date()),
                type :'string'
              },{
                name : 'fecha_emision',
                value : Moment(fecha_emision).format('YYYY-MM-DD HH:mm:ss') ,
                type :'string'
              }],
              return:['id_formulario_cabecera']
            }]
          }]
          var result = Selectbd(select)

          if(result){
              result.then((value) => {
                console.log(value)
                  this.setState ({
                    btn_limpiar_enabled:false,id_formulario_cabecera : value[1].result[0].id_formulario_cabecera
                  },()=>{
                    this.actualizar_tabla()
                  })
                  this.get_loading(false)
              })
          }
        }


     });
   }
this.get_loading(true)
*/


  }

  getSubDataTracking=()=>{
    const select = [{
        type: 'consulta', data:
        [{
          table : "(SELECT t.*,nombre_local FROM tracking t inner join `local` l on l.id_local=t.id_local)",
          field :[{
            name : 'sw_cabecera',
            value : this.state.id_tracking,
            type:'integer'
          },{
            name : 'estado',
            value : '0',
            type:'integer'
          }],
          return:['id_tracking','nombre_local']
        },{
            table : "(SELECT * FROM `local` WHERE FIND_IN_SET(id_local, (SELECT locales FROM rutas WHERE id_rutas = "+this.state.id_ruta_tracking+")))",
            field :[],
            return:['id_local as id','nombre_local as name']
          }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
            let id_subtracking= 0
            let nombre_local=''
            if(value[1].status==="ok"){
                id_subtracking= value[1].result[0].id_tracking 
                nombre_local= value[1].result[0].nombre_local
            }
            if(value[2].status==="ok"){
                this.setState({
                    data_local_tracking: value[2].result 
                })
            }
            this.setState({
                id_subtracking,uuid_imagen:'',nombre_local
            }) 
        });
      }
  }


  getDataTracking=()=>{
    const select = [{
        type: 'consulta', data:
        [{
          table : "tracking",
          field :[{
            name : 'sw_cabecera',
            value : '0',
            type:'integer'
          },{
            name : 'estado',
            value : '0',
            type:'integer'
          },{
            name : 'id_usuario',
            value : Number(localStorage.getItem("neolav2_uuid_usuario")),
            type:'integer'
          }],
          return:['id_tracking','id_router']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              id_tracking: value[1].result[0].id_tracking,
              id_ruta_tracking: value[1].result[0].id_router 
            },()=>this.getSubDataTracking())
          }else{
            this.setState({id_tracking:0})
          }
        });
      }
  }


  return_limpiarT =()=>{
    this.setState ({
      id_formulario_cabecera:0,
      data_detalle:[]
    })
  }
  formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }
  get_loading =(msg_loading)=>{
    this.setState({
      msg_loading
    })
  }
  getCrearSalida =()=>{
    if(Number(this.state.odometro)>0){
    }else{
        alert("Valor Incorrecto de Odómetro")
        return
    }
    var select = [{
        type: 'actualizar', data:
        [{
          table : 'tracking',
          field :[{
            name : 'imagen_salida',
            value : this.state.uuid_imagen,
            type :'string'
          },{
            name : 'odometro_salida',
            value : Number(this.state.odometro),
            type :'string'
          },{
            name : 'finaliza',
            value : Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') ,
            type :'string'
          },{
            name : 'fecha_salida',
            value : Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') ,
            type :'string'
          },{
            name : 'estado',
            value : "1" ,
            type :'string'
          }  ],
          id_name : "id_tracking",
          id_value : this.state.id_subtracking,
          id_type : "integer",
          return:['id_tracking']
        }]
      }]
      var result = Selectbd(select)

      if(result){
          result.then((value) => {
            this.getDataTracking()
            this.get_loading(false)
          })
      }
  }
  getCrearLlegada =()=>{
    
    if(Number(this.state.id_local_tracking)===0){
        alert("Debe seleccionar Local")
        return
    }
    if(Number(this.state.odometro)>0){
    }else{
        alert("Valor Incorrecto de Odómetro")
        return
    }
    var select = [{
        type: 'insertar', data:
        [{
          table : 'tracking',
          field :[{
            name : 'imagen_llegada',
            value : this.state.uuid_imagen,
            type :'string'
          },{
            name : 'odometro_llegada',
            value : Number(this.state.odometro),
            type :'string'
          },{
            name : 'id_local',
            value : this.state.id_local_tracking,
            type :'string'
          },{
            name : 'sw_cabecera',
            value : this.state.id_tracking,
            type :'string'
          },{
            name : 'fecha_creacion',
            value : Moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            type :'string'
          },{
            name : 'inicia',
            value : Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') ,
            type :'string'
          },{
            name : 'fecha_llegada',
            value : Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') ,
            type :'string'
          }],
          return:['id_tracking']
        }]
      }]
      var result = Selectbd(select)

      if(result){
          result.then((value) => {
            this.getDataTracking()
            this.get_loading(false)
          })
      }
  }
  getFinalizar = ()=>{
    var select = [{
        type: 'actualizar', data:
        [{
          table : 'tracking',
          field :[{
            name : 'finaliza',
            value : Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') ,
            type :'string'
          },{
            name : 'estado',
            value : "1" ,
            type :'string'
          }  ],
          id_name : "id_tracking",
          id_value : this.state.id_tracking,
          id_type : "integer",
          return:['id_tracking']
        }]
      }]
      var result = Selectbd(select)

      if(result){
          result.then((value) => {
            this.getDataTracking()
            this.get_loading(false)
          })
      }
  }
  render() {
    const firebase =  this.props.firebase
    //console.log("ssss"+firebase)
    const file_250x200 ="app-delivery-8b998"
    const url_image ="https://firebasestorage.googleapis.com/v0/b/"
    const separator ="/o/images%2F"
    const token_image = url_image+file_250x200+separator
    const ext=".png?alt=media"

    let modal_loading
    const msg_loading = this.state.msg_loading
    if(msg_loading){
      modal_loading = <div id="loaderf" className="loaderf loaderf-default is-active" data-text=""></div>
    }
    return (
      <React.Fragment>
        
        <div id="loader_msg" className="loader_msg loader_msg-default is-active" data-text="">
            <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
            
            {Number(this.state.id_tracking)===0?
            <React.Fragment>
                <table className="table_v2" style={{"width":"100%"}}>
                <thead>
                <tr><th colSpan="2" style={{"fontSize":"18px"}}>Nuevo Tracking</th></tr>
                </thead>
                <tbody>
                <tr><td style={{"fontSize":"18px"}}>Usuario:</td><td style={{"fontSize":"18px"}}>{localStorage.getItem("neolav2_nombres")}</td></tr>
                <tr><td style={{"fontSize":"18px"}}>Transportista:</td><td><GetSelect style={"select-css"} width={"100%"} label={""} name={"id_local"} data={this.state.data_local} value={this.state.id_local} return_data={this.return_data} /></td></tr>
                <tr><td style={{"fontSize":"18px"}}>Fecha:</td><td> {Moment(this.state.date_desde).format('YYYY-MM-DD HH:mm:ss') }</td></tr>
                <tr><td style={{"fontSize":"18px"}}>Ruta:</td><td><GetSelect style={"select-css"} width={"100%"} label={""} name={"id_formulario"} data={this.state.data_formulario} value={this.state.id_formulario} return_data={this.return_data} /></td></tr>
                </tbody>
                </table>
                <GetButton classname={"button_v1"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} name={"Iniciar"} value={"Iniciar"} return_accion={this.return_limpiar}  />
                <GetButton classname={"button_v2"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} name={"Salir"} value={"Salir"}
                return_accion={this.props.setSubModulo}  />

                {(this.state.data_detalle&&this.state.data_detalle.length>0)?
                <GetFormularioNewLogistica  return_limpiarT ={this.return_limpiarT} firebase={this.props.firebase} id_formulario_cabecera={this.state.id_formulario_cabecera} id_formulario={this.state.id_formulario} data_detalle ={this.state.data_detalle} />
                :null}
            </React.Fragment>
            :
            
            <React.Fragment>
                <table className="table_v2" style={{"width":"100%"}}>
                <thead>
                <tr><th colSpan="2" style={{"fontSize":"18px"}}>Tracking en Proceso</th></tr>
                </thead>
                <tbody>
                <tr><td style={{"fontSize":"18px"}}>Usuario:</td><td style={{"fontSize":"18px"}}>{localStorage.getItem("neolav2_nombres")}</td></tr>

                {Number(this.state.id_subtracking)===0?
                    <React.Fragment>
                        <tr><td>Local:</td><td><GetSelect style={"select-css"} width={"100%"} label={""} name={"id_local_tracking"} data={this.state.data_local_tracking} value={this.state.id_local_tracking} return_data={this.return_data} /></td></tr>
                        <tr><td>Odómetro
                            </td><td>
                            <GetInput name={"odometro"} style={{"width":"95%"}} value={this.state.odometro} return_data={this.return_data} />
                                </td></tr>
                        <tr><td>Foto</td><td>
                        <GetUploadImage uuid={this.state.id_formulario} index={0}
                          file_xxx={file_250x200}  firebase={firebase} getUUID={this.getUUID} 
                          width={'250px'} height={'200px'} pref={'_250x200'}  token_image={
                            (this.state.uuid_imagen)?
                            token_image+this.state.uuid_imagen+ext:''
                          }/>
                            
                            </td></tr>
                        
                        <tr><td colSpan={2}><GetButton id="llegada" classname={"button_v2"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} name={"TimbrarLlegada"} value={"Timbrar Llegada"}
                return_accion={this.getCrearLlegada}  /></td></tr>
                    

                    <tr><td colSpan={2}><GetButton id="finalizar" classname={"button_v2"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} 
                    name={"finalizar"} value={"Finalizar Tracking"}
                return_accion={this.getFinalizar}  /></td></tr>

                    </React.Fragment>
                :
                
                <React.Fragment>
                        <tr><td>Local:</td><td>{this.state.nombre_local}</td></tr>
                        <tr><td>Odómetro
                            </td><td>
                            <GetInput name={"odometro"} style={{"width":"95%"}} value={this.state.odometro} return_data={this.return_data} />
                                </td></tr>
                        <tr><td>Foto</td><td>
                        <GetUploadImage uuid={this.state.id_formulario} index={0}
                          file_xxx={file_250x200}  firebase={firebase} getUUID={this.getUUID} 
                          width={'250px'} height={'200px'} pref={'_250x200'}  token_image={
                            (this.state.uuid_imagen)?
                            token_image+this.state.uuid_imagen+ext:''
                          }/>
                            
                            </td></tr>
                        
                        <tr><td colSpan={2}><br/><br/><br/><GetButton id="salida" classname={"button_v2"} style={{"width":"100%"}}  name={"TimbrarSalida"} value={"Timbrar Salida"}
                return_accion={this.getCrearSalida}  /></td></tr>
                    
                    </React.Fragment>
                
                }

                </tbody>
                </table>

            </React.Fragment>
            
            
            }
          </div>
          </div>


          {modal_loading}
       </React.Fragment>
    )
  }
  getUUID =(uuid_imagen,id_rubro,uuid_imagen_2,index)=>{
    /*let uuid_image_data = this.state.uuid_image_data
    console.log(index)
    uuid_image_data[index].uuid_imagen=uuid_imagen*/
    this.setState({
        uuid_imagen
    })

  }
  componentDidMount (){
    this.actualizar_select();
    this.getDataTracking()
  }

}

export default getTracking;
