import React, { Component } from 'react';
import GetLogin from './getLogin';
import GetDashboard from './getDashboard';
import SideBar from './../layout/sidebar/SideBarMenu'
import MainContent from './../layout/main/MainContent'
import { Route } from "react-router-dom";

class getApp extends Component {
  constructor(){
     super();
     this.state = {
       load_user : false,
       id_usuario:0,
       id_tipo_rol:0
     }
  }
  validar_login=(id_usuario,id_tipo_rol,tipo_rol,nombres,sw_venta)=>{
    //console.log( tipo_rol+"  "+nombres)
    localStorage.setItem("neolav2_uuid_usuario",id_usuario)
    localStorage.setItem("neolav2_id_tipo_rol",id_tipo_rol)
    localStorage.setItem("neolav2_tipo_rol",tipo_rol)
    localStorage.setItem("neolav2_nombres",nombres)
    localStorage.setItem("neolav2_sw_venta",sw_venta)
    this.setState({
      id_usuario,id_tipo_rol,load_user:true
    })
  }
  render () {
    let loading = "Cargando. . ."
    const load_user  = this.state.load_user
    if(load_user===false){
      loading = <GetLogin validar_login={this.validar_login}/>
    }
    if(load_user===true){
      loading =  <GetDashboard firebase={this.props.firebase} />
    }
   return (
     <React.Fragment>
     <div style={{"width":"100%","height":"100%","backgroundSize": "cover"}}>
      {loading}
     </div>
     </React.Fragment>
   )
  }
  //"backgroundImage":"url(" + require('../images/fondo.jpg')  + ")",
  componentDidMount (){

    if(localStorage.getItem("neolav2_uuid_usuario")&&localStorage.getItem("neolav2_id_tipo_rol")&&localStorage.getItem("neolav2_tipo_rol")
    &&localStorage.getItem("neolav2_nombres")&&localStorage.getItem("neolav2_sw_venta")){
      this.validar_login(localStorage.getItem("neolav2_uuid_usuario"),localStorage.getItem("neolav2_id_tipo_rol"),
      localStorage.getItem("neolav2_tipo_rol"),localStorage.getItem("neolav2_nombres"),localStorage.getItem("neolav2_sw_venta"));
    }
  }

}
export default getApp;
