import React from 'react';

import IndexContent from './IndexContent'

import GetLocales from '../../componentes/getLocales'
import GetUsuarios from '../../componentes/getUsuarios'
import GetClientes from '../../componentes/getClientes'
import GetRubro from '../../componentes/getRubro'
import GetPresentacion from '../../componentes/getPresentacion'
import GetServicio from '../../componentes/getServicio'
import GetProductos from '../../componentes/getProductos'
import GetRubro2 from '../../componentes/getRubro2'
import GetInsumos from '../../componentes/getInsumos'
import GetReporteCentralizado from '../../componentes/getReporteCentralizado'
import GetFormulas from '../../componentes/getFormulas'
import GetCosteo from '../../componentes/getCosteo'
import GetPantalla from '../../componentes/getPantalla'
import GetReporte1 from '../../componentes/getReporte1'
import GetIndices from '../../componentes/getIndices'
import GetCategoria from '../../componentes/getCategoria'
import GetFormulario from '../../componentes/getFormulario'
import GetVistaPrevia from '../../componentes/getVistaPrevia'
import GetCalendar from '../../componentes/getCalendar'

function MainContent(props) {
    let modulo
    if(Number(props.id_modulo)===1){
      modulo =<GetUsuarios/>
    }
    if(Number(props.id_modulo)===2){
      modulo =<GetLocales/>
    }
    if(Number(props.id_modulo)===3){
      modulo =<GetClientes/>
    }
    if(Number(props.id_modulo)===4){
      modulo =<GetRubro/>
    }
    if(Number(props.id_modulo)===5){
      modulo =<GetPresentacion/>
    }
    if(Number(props.id_modulo)===6){
      modulo =<GetServicio/>
    }
    if(Number(props.id_modulo)===7){
      modulo =<GetProductos/>
    }
    if(Number(props.id_modulo)===8){
      modulo =<GetRubro2/>
    }
    if(Number(props.id_modulo)===9){
      modulo =<GetInsumos/>
    }
    if(Number(props.id_modulo)===10){
      modulo =<GetFormulas/>
    }
    if(Number(props.id_modulo)===11){
      modulo =<GetCosteo/>
    }
    if(Number(props.id_modulo)===12){
      modulo =<GetPantalla/>
    }
    if(Number(props.id_modulo)===13){
      modulo =<GetReporte1/>
    }
    if(Number(props.id_modulo)===14){
      modulo =<GetIndices/>
    }
    if(Number(props.id_modulo)===15){
      modulo =<GetCategoria/>
    }
    if(Number(props.id_modulo)===16){
      modulo =<GetFormulario/>
    }
    if(Number(props.id_modulo)===17){
      modulo =<GetVistaPrevia  firebase={props.firebase}/>
    }
    if(Number(props.id_modulo)===18){
      modulo =<GetCalendar/>
    }

    if(Number(props.id_modulo)===98){
      modulo =<GetReporteCentralizado/>
    }



    if(Number(props.id_modulo)===99){
      localStorage.removeItem("neolav2_uuid_usuario")
      localStorage.removeItem("neolav2_id_tipo_rol")
      localStorage.removeItem("neolav2_tipo_rol")
      localStorage.removeItem("neolav2_nombres")
      window.location.reload(false);
    }
    return (
        <main className="page-content">
            <IndexContent />
            <div className="overlay"></div>
            <div className=" routes ">
            {modulo}
            </div>
        </main>
    )
}

export default MainContent;
