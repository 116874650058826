import React, { Component } from 'react';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import Selectbd from './../bd/getDb';
class getPantalla extends Component {

  constructor(){/**********/
    super();
    this.state = {
        id_pantalla:-1,
        data_pantalla:[],
        data_boton:[]
    }
  }
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_rubro, rubro, id_estado,  if(id_estado=0,'INACTIVO','ACTIVO') as estado,codigo,eliminado FROM rubro )",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "rubro",
        return:['id_rubro','rubro','id_estado','codigo','estado']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_rubro : value[1].result
          })
        }else{
          this.setState({
            data_rubro : []
          })
        }
      });
    }
  }

  lista_botones =()=>{
    const id_pantalla = this.state.id_pantalla
    const select = [{
      type: 'consulta', data:
      [{
        table : "(select id_puntoventa_pantalla,color,id_puntoventa_pantalla as id,puntoventa_pantalla as Nombre,orden as Orden,id_sub_puntoventa_pantalla as id_pantalla,id_item,activo,if(tipo_funcion=0,'Normal',if(tipo_funcion=1,'Llevar',if(tipo_funcion=3,'Domicilio','Menú'))) as Tipo from puntoventa_pantalla where sw_cabecera="+id_pantalla+" order by orden asc)",
        field :[],
        order_by: "Orden",
        return:['id_puntoventa_pantalla','id','Nombre','Orden','id_pantalla','id_item','activo','Tipo','color']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_boton : value[1].result
          })
        }
      });
    }
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(select id_puntoventa_pantalla as id,cast(concat(if(sw_primaria=1,' PRIMARIA -',''),'',puntoventa_pantalla) as char) as name,sw_primaria,activo,color from puntoventa_pantalla where orden=0 and sw_cabecera=0 order by id_puntoventa_pantalla)",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_pantalla : value[1].result
          })
        }
      });
    }
  }
 return_data =(name,value)=>{
    this.setState({
      [name]:value
    },()=>{
      this.lista_botones()
    })
  }
 return_table_eliminar =()=>{

 }
 return_table_edicion=()=>{

 }
 toColor =(num)=> {
    num >>>= 0;
    var b = num & 0xFF,
        g = (num & 0xFF00) >>> 8,
        r = (num & 0xFF0000) >>> 16,
        a = ( (num & 0xFF000000) >>> 24 ) / 255 ;
    return "rgba(" + [r, g, b, a].join(",") + ")";
}
  render() {

    return (
      <React.Fragment>
        <div className="formulario white">
          <GetSelect label={"Pantalla"} name={"id_pantalla"} data={this.state.data_pantalla} value={this.state.id_pantalla} return_data={this.return_data} />
        </div>
        <GetTable name={"tabla_boton"} data={this.state.data_boton}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
         <React.Fragment>
          {this.state.data_boton.map(item => (
            <span key={item.id_puntoventa_pantalla} className="boton_item"><button style={(Number(item.color)!==0)?{"background":this.toColor(Number(item.color))}:null} className="boton_item_span">{item.Nombre}</button></span>
          ) ) }
        </React.Fragment>
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select()
  }

}

export default getPantalla;
