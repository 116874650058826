import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import reactLogo from '../images/logow.png'

class getApp extends Component {
  constructor(){
     super();
     this.state = {
       load_user : false,
       usuario:'',
       clave:''
     }
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  return_validar_login =(id_usuario,id_tipo_rol,tipo_rol,nombres,sw_venta)=>{
    this.props.validar_login(id_usuario,id_tipo_rol,tipo_rol,nombres,sw_venta);
  }
  return_accion = ()=>{
    const usuario = this.state.usuario
    const clave = this.state.clave
    var md5 = require('md5');
    const ciphertext = md5(clave)
    console.log(ciphertext)
    //var CryptoJS = require("crypto-js");
    //var ciphertext = CryptoJS.AES.encrypt(clave, 'my-secret-key@123').toString();
  //  var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
  //  var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    //console.log(decryptedData)
    if(validate_text(usuario)){
      if(validate_text(clave)){
          const select = [{
            type: 'consulta', data:
            [{
              table : '(SELECT u.*,catalogo,sw_venta FROM usuario u inner join catalogo c on c.id_catalogo=u.id_tipo_rol)',
              field :[{
                name : 'usuario',
                value :usuario,
                type:'string',
                type_string :'strict'
              },{
                name : 'clave',
                value :ciphertext,
                type:'string',
                type_string :'strict'
              }],
              return:['id_usuario','id_tipo_rol','catalogo','apellidos','nombres','sw_venta']
            }]
          }]

          var result = Selectbd(select)
          if(result){
            result.then((value) => {
              if(value[1].status==="ok"){
                //console.log("correcto"+value[1].result[0].id_usuario);
                this.return_validar_login(value[1].result[0].id_usuario,value[1].result[0].id_tipo_rol,value[1].result[0].catalogo,
                  value[1].result[0].apellidos+" "+value[1].result[0].nombres,value[1].result[0].sw_venta)
              }else{
                //console.log("incorrecto");
                alert("Usuario o Contraseña Incorrecta")
              }
            });
          }
      }else{
        alert("Ingrese Contraseña")
        return null
      }
    }else{
      alert("Ingrese Usuario")
      return null
    }
  }
  render () {
   return (
     <React.Fragment>
       <div style={{"width":"100%","height":"100%"}}  className="parent">
        <div className="box" style={{"textAlign":"center","fontSize": "medium","color": "#338d48","fontWeight": "bolder"}}>
          <img src={reactLogo} alt="React logo" width="350px" /><br/><br/>
          <p>Bienvenid@ al <br/>Módulo de Centralización v.1.0. </p>
        </div>
        <div className="box">
           <div className="formulario white" style={{"width":"300px","backgroundColor": "#b3e25a"}}>
            <GetInput label={"Usuario"} name={"usuario"} value={this.state.usuario} style={{"width":"95%","color": "#338d48"}} return_data={this.return_data} />
            <GetInput label={"Clave "} name={"clave"}  type={"password"} value={this.state.clave}  style={{"width":"95%","color": "#338d48"}} return_data={this.return_data}  />
            <GetButton name={"login"} value={"Ingresar"}  style={{"width":"95%"}}  return_accion={this.return_accion}  />
           </div>
         </div>
         <div className="box">
         </div>
       </div>
     </React.Fragment>
   )
  }

}
export default getApp;
