import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import GetUploadImage from './getUploadImage';
import DatePicker from "react-datepicker";
import Select from 'react-select'
//ID CATALOGO  =20
class getFormularioNew extends Component {
  constructor(props){
    super(props);

    let categoria =""
    let data_categoria =[]
    this.props.data_detalle.forEach (ditem=> {
          if(categoria!==ditem.catalogo){
            categoria=ditem.catalogo
            data_categoria=[...data_categoria, {
            	'categoria': categoria,'subcategoria': ditem.subcategoria
            }]
          }
    })
  //  console.log(this.props.data_detalle)
  // console.log(data_categoria)
  //console.log(new Date())
    this.state = {
        date_desde : new Date(),
        id_formulario:this.props.id_formulario,
        id_modulo:0,
        data_calificacion:[],
        id_calificacion:-1,
        uuid_imagen:'',
        uuid_image_data:[],
        data_categoria:data_categoria,
        categoria:'',
        observacion:'',
        accion:'',
        id_formulario_detalle:0,
        id_formulario_items:0,
        indice:"",
        id_formulario_cabecera:this.props.id_formulario_cabecera,
        conclusion_general:'',
        recomendacion_general:'',
        subcategoria:[],
        valor_titulo:0,
        valor_categoria:[],
        valor_procedimiento:[],
        valor_indice:[],
        etiquetas:[],
        selectedOption:[],
        valor_porcentaje_audD:[],
        valor_porcentaje_aud:-1,
        valor_final_audD:[],
        valor_final_aud:-1,
        valor_maximo_nota:5
      }
  }

  onChangeDesde = date => this.setState({ date_desde:date })
  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }

  agregarFoto =()=>{
    let uuid_image_data = this.state.uuid_image_data
    uuid_image_data=[...uuid_image_data, {
            'uuid_imagen': ""
           }]
     this.setState({
       uuid_image_data
     })
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }
  getOpciones=(id_formulario_detalle,indice)=>{
        this.setState({
          id_modulo:2,id_formulario_detalle,id_calificacion:-1,uuid_imagen:'',observacion:'',accion:'',id_formulario_items:0,indice,uuid_image_data:[],
          etiquetas:[],selectedOption:[]
        },()=>{
          this.getLoadData(id_formulario_detalle,this.state.id_formulario,this.state.id_formulario_cabecera)
        })
  }
  Regresar=()=>{
        this.setState({
          id_modulo:1
        })
  }
  RegresarT=()=>{
        this.setState({
          id_modulo:0
        })
  }
  SalirT =()=>{
      this.setState({
        id_modulo:0,
        data_categoria:[]
      },()=>{
        this.props.return_limpiarT()
      })
  }
  getLoadData =(id_formulario_detalle,id_formulario,id_formulario_cabecera)=>{
    //id_formulario_items, id_formulario_asignado, id_formulario_detalle, id_calificacion, observaciones, uuid_imagenes, fecha_creacion, id_usuario
//;
    const select = [{
      type: 'consulta', data:
      [{
        table : 'formulario_items',
        field :[{
          name : 'id_formulario_asignado',
          value : id_formulario,
          type:'integer'
        },{
          name : 'id_formulario_detalle',
          value :id_formulario_detalle,
          type:'integer'
        },{
          name : 'id_formulario_cabecera',
          value :id_formulario_cabecera,
          type:'integer'
        }],
        return:['id_formulario_items','id_calificacion','acciones','observaciones','uuid_imagenes','fecha_maximo_cumplimiento']
      },{
        table : '(SELECT cast(c.id_catalogo as char) as `value`,cast(catalogo as char) as `label`  FROM catalogo c inner join  (SELECT if(trim(txt_id_etiquetas)="","0",txt_id_etiquetas) as etiqueta FROM formulario_detalle where id_formulario_detalle ='+id_formulario_detalle+') b  on  concat(",",etiqueta,",") like concat("%,",c.id_catalogo,",%") )',
        field :[],
        return:['`value`','`label`']
      },{
        table : '(SELECT cast(c.id_catalogo as char) as `value`,cast(catalogo as char) as `label`  FROM catalogo c inner join (SELECT if(trim(txt_etiquetas_)="","0",txt_etiquetas_) as etiqueta FROM formulario_items where id_formulario_detalle = '+id_formulario_detalle+' and id_formulario_cabecera='+id_formulario_cabecera+' ) b  on  concat(",",etiqueta,",") like concat("%,",c.id_catalogo,",%"))',
        field :[],
        return:['`value`','`label`']
      },{
        table : 'formulario_detalle',
        field :[{
          name : 'id_formulario_detalle',
          value :id_formulario_detalle,
          type:'integer'
        }],
        return:['calificacion']
      }]
    }]
    //SELECT * FROM formulario_detalle where id_formulario=4 and id_indice=467
    //  selectedOption:value[2].result
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          let uuid_image_data = []

          if(value[1].result[0].uuid_imagenes){
            var nuevo_data = value[1].result[0].uuid_imagenes.split(",");

             nuevo_data.map((key, index)=>{
  //console.log(key)
             uuid_image_data=[...uuid_image_data, {
                    'uuid_imagen': key
                   }]
            })
          }
  //console.log(uuid_image_data)
          this.setState({
            id_formulario_items : value[1].result[0].id_formulario_items,
            id_calificacion : value[1].result[0].id_calificacion,
            observacion : value[1].result[0].observaciones,
            accion : value[1].result[0].acciones,
            uuid_image_data : uuid_image_data,
            date_desde: new Date(value[1].result[0].fecha_maximo_cumplimiento)
          })
        }else{

        }
        if(value[2].status==="ok"){
          this.setState({
            etiquetas:value[2].result
          })
        }else{
          this.setState({
            etiquetas:[]
          })
        }
        if(value[3].status==="ok"){
          this.setState({
            selectedOption:value[3].result
          })
        }else{
          this.setState({
            selectedOption:[]
          })
        }
        if(value[4].status==="ok"){
          this.setState({
            valor_maximo_nota:value[4].result[0].calificacion
          },()=>{
            this.actualizar_selectOnlyCalificacion()
          })
        }else{
          this.setState({
            valor_maximo_nota:5
          },()=>{
            this.actualizar_selectOnlyCalificacion()
          })
        }//
        //valor_maximo_nota
      });
    }
  }
  GrabaRT =()=>{
    const valor_final_aud =5;//Number(this.state.valor_final_aud)
    const valor_porcentaje_aud =Number(this.state.valor_porcentaje_aud)
    const conclusion_general = this.state.conclusion_general
    const recomendacion_general = this.state.recomendacion_general
    if(conclusion_general===''){
      alert("Ingrese Conclusión General")
      return null
    }
    if(recomendacion_general===''){
      alert("Ingrese Recomendación General")
      return null
    }
    if(valor_porcentaje_aud===-1){
      alert("Seleccione Porcentaje Auditor")
      return null
    }
    //if(valor_final_aud===-1){
    //  alert("Seleccione Calificación Auditor")
    //  return null
    //}


    const id_formulario_cabecera = this.state.id_formulario_cabecera
    var select = [{
      type: 'actualizar', data:
      [{
        table : 'formulario_cabecera',
        field :[{
          name : 'id_estado',
          value : 1,
          type :'integer'
        },{
          name : 'porcentaje_auditor',
          value : valor_porcentaje_aud,
          type :'string'
        },{
          name : 'nota_auditor',
          value : valor_final_aud,
          type :'string'
        },{
          name : 'conclusion_final',
          value : conclusion_general,
          type :'string'
        },{
          name : 'recomendacion_final',
          value : recomendacion_general,
          type :'string'
        }],
        id_name : "id_formulario_cabecera",
        id_value : id_formulario_cabecera,
        id_type : "integer",
        return:['id_formulario_cabecera']
      }]
    }]
    var result = Selectbd(select)
    //console.log(result)
    if(result){
        result.then((value) => {
          if(value[1].status==="ok"){

          }
        })

      this.SalirT()
    }
  }
  Guardar =()=>{
    //console.log(window.document.etiquetas_finales)
    const id_formulario_detalle = this.state.id_formulario_detalle
    if(Number(id_formulario_detalle)>0){
      let txt_id_etiquetas_ =""
      this.state.selectedOption.forEach (ditem=> {
        if(txt_id_etiquetas_===""){
          txt_id_etiquetas_=  ditem.value
        }else{
          txt_id_etiquetas_= txt_id_etiquetas_+","+ditem.value
        }
      })
      if(this.state.etiquetas){
        if(Number(this.state.etiquetas.length)>0){
          if(txt_id_etiquetas_===""){
            alert("Ingrese por lo menos una etiqueta")
            return
          }
        }
      }
      //console.log(txt_id_etiquetas_)
      const id_formulario_asignado = this.state.id_formulario
      const id_formulario_detalle = this.state.id_formulario_detalle
      const id_formulario_cabecera = this.state.id_formulario_cabecera
      const id_calificacion = this.state.id_calificacion
      const observaciones = this.state.observacion.trim()
      const accion =this.state.accion.trim()
      const fecha_creacion=this.formatDate(new Date())
      const id_usuario =Number(localStorage.getItem("neolav2_uuid_usuario"))
      let uuid_imagen= ""
      if(Number(id_calificacion)===-1){
        alert("Seleccione Calificación")
        return
      }

      this.state.uuid_image_data.map((row, index) => {
        if(uuid_imagen===""){
          uuid_imagen=row.uuid_imagen
        }else{
          uuid_imagen=uuid_imagen+","+row.uuid_imagen
        }
      })

      if((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||
      Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3
    )&&Number(this.state.valor_maximo_nota)!=Number(this.state.id_calificacion)){
        if(observaciones===""){
          alert("Ingrese Observación")
          return
        }
        if(accion===""){
          alert("Ingrese Acciones a Tomar")
          return
        }
        if(uuid_imagen===""){
          //alert("Ingrese Fotos")
          //return
        }

      }
      let fecha_maximo_cumplimiento =this.formatDate(this.state.date_desde)

        //this.state.uuid_imagen
      //id_formulario_items, id_formulario_asignado, id_formulario_detalle, id_calificacion, observaciones, uuid_imagenes, fecha_creacion, id_usuario

      const id_formulario_items = this.state.id_formulario_items
      var select
      if(Number(id_formulario_items)===0){
        select = [{
          type: 'insertar', data:
          [{
            table : 'formulario_items',
            field :[{
              name : 'id_formulario_cabecera',
              value : id_formulario_cabecera,
              type:'integer'
            },{
              name : 'id_formulario_asignado',
              value : id_formulario_asignado,
              type:'integer'
            },{
              name : 'id_formulario_detalle',
              value : id_formulario_detalle,
              type :'integer'
            },{
              name : 'id_calificacion',
              value : id_calificacion,
              type :'integer'
            },{
              name : 'observaciones',
              value : observaciones,
              type :'string'
            },{
              name : 'acciones',
              value : accion,
              type :'string'
            },{
              name : 'fecha_creacion',
              value : fecha_creacion,
              type :'string'
            },{
              name : 'id_usuario',
              value : id_usuario,
              type :'integer'
            },{
              name : 'uuid_imagenes',
              value :uuid_imagen,
              type :'string'
            },{
              name : 'fecha_maximo_cumplimiento',
              value :fecha_maximo_cumplimiento,
              type :'string'
            },{
              name : 'txt_etiquetas_',
              value :txt_id_etiquetas_,
              type :'string'
            }],
            return:['id_formulario_items']
          }]
        }]
      }else{
        select = [{
          type: 'actualizar', data:
          [{
            table : 'formulario_items',
            field :[{
              name : 'id_calificacion',
              value : id_calificacion,
              type :'integer'
            },{
              name : 'observaciones',
              value : observaciones,
              type :'string'
            },{
              name : 'acciones',
              value : accion,
              type :'string'
            },{
              name : 'fecha_creacion',
              value : fecha_creacion,
              type :'string'
            },{
              name : 'id_usuario',
              value : id_usuario,
              type :'integer'
            },{
              name : 'txt_etiquetas_',
              value :txt_id_etiquetas_,
              type :'string'
            },{
              name : 'fecha_maximo_cumplimiento',
              value :fecha_maximo_cumplimiento,
              type :'string'
            },{
              name : 'uuid_imagenes',
              value :uuid_imagen,
              type :'string'
            }],
            id_name : "id_formulario_items",
            id_value : id_formulario_items,
            id_type : "integer",
            return:['id_formulario_items']
          }]
        }]

      }
      var result = Selectbd(select)
      //console.log(result)
      if(result){
          result.then((value) => {
            if(value[1].status==="ok"){
                this.verificar_avance_total()
            }
          })

        this.setState({
          id_modulo:1
        })
      }
    }
  }

  formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }
//c.catalogo,c.id_catalogo
 getSwSubCategoria =(id_subcategoria)=>{
   let subdata_categoria_new =[]
   let subcategoria = this.state.subcategoria
   subcategoria.forEach (ditem=> {
         if(Number(id_subcategoria)===Number(ditem.id_catalogo)){
           let sw =0
           if(0=== Number(ditem.sw)){
             sw=1
           }
           subdata_categoria_new=[...subdata_categoria_new, {
             'catalogo': ditem.catalogo,'id_catalogo': ditem.id_catalogo ,'sw':sw
           }]
         }else{
           subdata_categoria_new=[...subdata_categoria_new, {
             'catalogo': ditem.catalogo,'id_catalogo': ditem.id_catalogo,'sw':ditem.sw
           }]
         }
   })
   //console.log(subcategoria)
  console.log(subdata_categoria_new)
   //console.log(subcategoria)

   this.setState({
     subcategoria:subdata_categoria_new
   })
 }
  getDataCategoria =(categoria)=>{
    this.setState({
      id_modulo:1,
      categoria
    })
    console.log(categoria)
  }
  //this.props.id_formulario_cabecera
  //
  //id_formulario_cabecera, id_local, id_formulario, id_usuario, id_estado, eliminado, fecha_creacion, fecha_emision, calificacion_final, avance,

  actualizar_selectOnlyCalificacion=()=>{
    const id_formulario_cabecera = Number(this.state.id_formulario_cabecera)
    const valor_maximo_nota = this.state.valor_maximo_nota
    let sql_tmp=""
    sql_tmp=sql_tmp+"((SELECT 98 AS id,'0' AS name)"
    if(Number(valor_maximo_nota)>=1){
      sql_tmp=sql_tmp+"union(SELECT 1 AS id,'1' AS name)"
    }
    if(Number(valor_maximo_nota)>=2){
      sql_tmp=sql_tmp+"union(SELECT 2 AS id,'2' AS name)"
    }
    if(Number(valor_maximo_nota)>=3){
      sql_tmp=sql_tmp+"union(SELECT 3 AS id,'3' AS name)"
    }
    if(Number(valor_maximo_nota)>=4){
      sql_tmp=sql_tmp+"union(SELECT 4 AS id,'4' AS name)"
    }
    if(Number(valor_maximo_nota)>=5){
      sql_tmp=sql_tmp+"union(SELECT 5 AS id,'5' AS name)"
    }
    sql_tmp=sql_tmp+"union(SELECT 99 AS id,'NO APLICA' AS name))"

    const select = [{
      type: 'consulta', data:
      [{
        table : sql_tmp,
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        let data_calificacion = []
        if(value[1].status==="ok"){
          data_calificacion =value[1].result
        }
        this.setState({
          data_calificacion
        })

      });
    }
  }
  actualizar_select=()=>{
    const id_formulario_cabecera = Number(this.state.id_formulario_cabecera)
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 98 AS id,'0' AS name)union(SELECT 1 AS id,'1' AS name)union(SELECT 2 AS id,'2' AS name)union(SELECT 3 AS id,'3' AS name)union(SELECT 4 AS id,'4' AS name)union(SELECT 5 AS id,'5' AS name)union(SELECT 99 AS id,'NO APLICA' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT c.catalogo,c.id_catalogo,0 as sw FROM formulario_detalle d INNER JOIN catalogo c ON c.id_catalogo=d.id_subcategoria  WHERE id_formulario="+this.props.id_formulario+" GROUP BY c.catalogo)",
        field :[],
        return:['id_catalogo','catalogo','sw']
      },{
        table : "((SELECT 1 AS id,'1' AS name)union(SELECT 2 AS id,'2' AS name)union(SELECT 3 AS id,'3' AS name)union(SELECT 4 AS id,'4' AS name)union(SELECT 5 AS id,'5' AS name)union(SELECT 6 AS id,'6' AS name)union(SELECT 7 AS id,'7' AS name)union(SELECT 8 AS id,'8' AS name)union(SELECT 9 AS id,'9' AS name)union(SELECT 10 AS id,'10' AS name)union(SELECT 11 AS id,'11' AS name)union(SELECT 12 AS id,'12' AS name)union(SELECT 13 AS id,'13' AS name)union(SELECT 14 AS id,'14' AS name)union(SELECT 15 AS id,'15' AS name)union(SELECT 16 AS id,'16' AS name)union(SELECT 17 AS id,'17' AS name)union(SELECT 18 AS id,'18' AS name)union(SELECT 19 AS id,'19' AS name)union(SELECT 20 AS id,'20' AS name)union(SELECT 21 AS id,'21' AS name)union(SELECT 22 AS id,'22' AS name)union(SELECT 23 AS id,'23' AS name)union(SELECT 24 AS id,'24' AS name)union(SELECT 25 AS id,'25' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "((SELECT 1 AS id,'1' AS name)union(SELECT 2 AS id,'2' AS name)union(SELECT 3 AS id,'3' AS name)union(SELECT 4 AS id,'4' AS name)union(SELECT 5 AS id,'5' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT  * FROM formulario_cabecera WHERE id_formulario_cabecera="+id_formulario_cabecera+")",
        field :[],
        return:['porcentaje_auditor', 'nota_auditor', 'conclusion_final', 'recomendacion_final']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        let data_calificacion = []
        let subcategoria = []
        let valor_porcentaje_audD = []
        let valor_final_audD = []
        let conclusion_general = ""
        let recomendacion_general =""
        let valor_porcentaje_aud = -1
        let valor_final_aud =-1
        if(value[1].status==="ok"){
          data_calificacion =value[1].result
        }
        if(value[2].status==="ok"){
          subcategoria =value[2].result
        }
        if(value[3].status==="ok"){
          valor_porcentaje_audD =value[3].result
        }
        if(value[4].status==="ok"){
          valor_final_audD =value[4].result
        }
        if(value[5].status==="ok"){
          valor_porcentaje_aud =value[5].result[0].porcentaje_auditor
          valor_final_aud =value[5].result[0].nota_auditor
          conclusion_general =value[5].result[0].conclusion_final
          recomendacion_general =value[5].result[0].recomendacion_final
        }
        this.setState({
          data_calificacion ,subcategoria,valor_porcentaje_audD,valor_final_audD,conclusion_general,recomendacion_general,valor_porcentaje_aud,valor_final_aud
        })

      });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  getDataIndice =(file_250x200,firebase,token_image,ext)=>{
//defaultValue={[this.state.etiquetas[2], this.state.etiquetas[3]]}
    //this.state.indice
    return(
        <tr><td colSpan="2">
        <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
          <div className="cssversion_pw100 cssversion_ph100 " style={{"overflowY":"auto"}} >
            <table className="table_v2" style={{"width":"100%"}}>
              <tbody>

                  {(this.state.etiquetas)?
                    <React.Fragment>
                      {(Number(this.state.etiquetas.length)>0)?
                      <React.Fragment>
                      <tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Etiquetas</td></tr>
                      <tr><td  colSpan="2"><Select  value={this.state.selectedOption}  onChange={this.handleChange} name="etiquetas_finales" isMulti options={this.state.etiquetas} /></td></tr>
                      </React.Fragment>
                      :null}
                    </React.Fragment>
                  :null}

                  <tr><td colSpan="2"  style={{"fontSize":"18px"}} >Calificación</td></tr>
                  <tr><td colSpan="2"><GetSelect style={{"fontSize":"18pt"}} style_span={{"fontSize":"18pt"}}name={"id_calificacion"} data={this.state.data_calificacion} value={this.state.id_calificacion} return_data={this.return_data} />
                  </td></tr>
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3||Number(this.state.id_calificacion)===4||Number(this.state.id_calificacion)===99)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?<tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Observación</td></tr>:null}
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3||Number(this.state.id_calificacion)===4||Number(this.state.id_calificacion)===99)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?<tr><td  colSpan="2"><GetInput name={"observacion"} style={{"width":"95%"}} value={this.state.observacion} return_data={this.return_data} /></td></tr>:null}
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?<tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Acciones a Tomar</td></tr>:null}
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?<tr><td  colSpan="2"><GetInput name={"accion"} style={{"width":"95%"}} value={this.state.accion} return_data={this.return_data} /></td></tr>:null}
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?<tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Fecha Límite de Cumplimiento</td></tr>:null}
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?<tr><td  colSpan="2"><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /></td></tr>:null}
                  {((Number(this.state.id_calificacion)===98||Number(this.state.id_calificacion)===1||Number(this.state.id_calificacion)===2||Number(this.state.id_calificacion)===3)&&Number(this.state.valor_maximo_nota)!==Number(this.state.id_calificacion))?
                    <React.Fragment>
                    <tr><td colspan="2" style={{"textAlign": "center"}}>
                    {(this.state.uuid_image_data) ?
                      this.state.uuid_image_data.map((row, index) => (
                        
                          <GetUploadImage uuid={this.state.id_formulario}
                          file_xxx={file_250x200}  firebase={firebase} getUUID={this.getUUID} index={index}
                          width={'250px'} height={'200px'} pref={'_250x200'}  token_image={
                            (row.uuid_imagen)?
                            token_image+row.uuid_imagen+ext:''
                          }/>
                      ))
                      : <React.Fragment></React.Fragment>
                    }
                    </td></tr>
                    <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#697683","color": "white","textAlign": "center"}} colSpan="2" onClick={ () => this.agregarFoto()} > Agregar Foto</td></tr>
                    </React.Fragment>
                  :null}
                  <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#4CAF50","color": "white","textAlign": "center"}} colspan="2" onClick={ () => this.Guardar()} >Guardar</td></tr>
                  <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#EF4E3E","color": "white","textAlign": "center"}}  colspan="2" onClick={ () => this.Regresar()} >Regresar</td></tr>
              </tbody>
            </table>
          </div>
        </div>
        </td></tr>

    )
  }
  getDataCategoriaSUB =(subcategoria,sw,categoria,subformulario)=>{
    if(Number(sw)===0) return null
    const data_categoria = this.state.data_categoria
    return (
      <React.Fragment>
        {(data_categoria) ?
          data_categoria.map((row , index) => (
            <React.Fragment>
              {(row.subcategoria===subcategoria)?
                <React.Fragment>
                <tr><td onClick={ () => this.getDataCategoria(row.categoria)}  style={{"fontSize":"18px"}}>{(index+1)+". "+row.categoria}</td><td style={{"textAlign":"right"}}>{this.getavance_procedimiento(row.categoria)}</td></tr>
                {(categoria===row.categoria)?subformulario:null}
                </React.Fragment>
              :null}
            </React.Fragment>
          ))
          : <React.Fragment></React.Fragment>
        }
      </React.Fragment>
    )
  }
  /*<div id="loader_msg" className="loader_msg loader_msg-default is-active" data-text="">
        <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
          <div className="cssversion_pw100 cssversion_ph100 "   style={{"overflowY":"auto"}}>
            <table className="table_v2" style={{"width":"100%"}}>
              <thead>
               <tr>
                <th style={{"fontSize":"18px"}}>Categoría: {this.state.categoria}</th>
                </tr>
              </thead><tbody></tbody>
              </table>
            <table className="table_v2" style={{"width":"100%"}}>
              <thead>
              <tr>
               <th style={{"fontSize":"18px"}}>PROCEDIMIENTOS</th><th>Opciones</th>
               </tr>
              </thead>
              <tbody>
                {(data_detalle_filtre) ?
                  data_detalle_filtre.map((row ,index) => (
                    <tr><td  style={{"fontSize":"18px"}} >{(index+1)+".  "+row.indice}</td><td  style={{"fontSize":"18px"}} onClick={ () => this.getOpciones(row.id_formulario_detalle,row.indice)}>Calificar</td></tr>
                  ))
                  : <React.Fragment></React.Fragment>
                }

                <tr><td  style={{"fontSize":"19px",  "backgroundColor": "red","color": "white","textAlign": "center"}} colSpan="2" onClick={ () => this.RegresarT()}>REGRESAR</td></tr>
              </tbody>
              </table>
              </div>
            </div>
          </div>*/






          /*if(id_modulo===2){
            dir_modulo_modal = <div id="loader_msg" className="loader_msg loader_msg-default is-active" data-text="">
                                <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
                                  <div className="cssversion_pw100 cssversion_ph100 " style={{"overflowY":"auto"}} >
                                    <table className="table_v2" style={{"width":"100%"}}>
                                      <thead>
                                       <tr>
                                        <th style={{"fontSize":"18px"}}>Categoría: {this.state.categoria}</th>
                                        </tr>
                                      </thead><tbody></tbody>
                                    </table>
                                    <table className="table_v2" style={{"width":"100%"}}>
                                      <thead>
                                       <tr>
                                        <th style={{"fontSize":"18px"}}>Índice: {this.state.indice}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <tr><td colSpan="2"  style={{"fontSize":"18px"}} >Calificación</td></tr>
                                          <tr><td colSpan="2"><GetSelect style={{"fontSize":"18pt"}} style_span={{"fontSize":"18pt"}}name={"id_calificacion"} data={this.state.data_calificacion} value={this.state.id_calificacion} return_data={this.return_data} />
                                          </td></tr>
                                          <tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Observación</td></tr>
                                          <tr><td  colSpan="2"><GetInput name={"observacion"} style={{"width":"100%"}} value={this.state.observacion} return_data={this.return_data} /></td></tr>
                                          <tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Acciones a Tomar</td></tr>
                                          <tr><td  colSpan="2"><GetInput name={"accion"} style={{"width":"100%"}} value={this.state.accion} return_data={this.return_data} /></td></tr>
                                          <tr><td  colSpan="2"  style={{"fontSize":"18px"}} >Fecha Límite de Cumplimiento</td></tr>
                                          <tr><td  colSpan="2"><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /></td></tr>

                                          <tr><td colspan="2" style={{"textAlign": "center"}}>

                                          {(this.state.uuid_image_data) ?
                                            this.state.uuid_image_data.map((row, index) => (
                                                <GetUploadImage uuid={this.state.id_formulario}
                                                file_xxx={file_250x200}  firebase={firebase} getUUID={this.getUUID} index={index}
                                                width={'250px'} height={'200px'} pref={'_250x200'}  token_image={
                                                  (row.uuid_imagen)?
                                                  token_image+row.uuid_imagen+ext:''
                                                }/>
                                            ))
                                            : <React.Fragment></React.Fragment>
                                          }
                                          </td></tr>
                                          <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#697683","color": "white","textAlign": "center"}} colSpan="2" onClick={ () => this.agregarFoto()} > Agregar Foto</td></tr>
                                          <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#4CAF50","color": "white","textAlign": "center"}} colspan="2" onClick={ () => this.Guardar()} >Guardar</td></tr>
                                          <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#EF4E3E","color": "white","textAlign": "center"}}  colspan="2" onClick={ () => this.Regresar()} >Regresar</td></tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
          }*/
      getavance_indice =(catalogo)=>{
          const data_detalle_filtre =this.state.valor_indice.filter(
            valor_categoria => valor_categoria.id_formulario_detalle === catalogo
          )
         return (
               <React.Fragment>
                 {(data_detalle_filtre) ?
                   data_detalle_filtre.map((row ,index) => (
                     <React.Fragment>
                       {row.avance+"%"}
                     </React.Fragment>
                   ))
                   :null
                 }
                 </React.Fragment>
               )
      }

    getavance_procedimiento =(catalogo)=>{
        const data_detalle_filtre =this.state.valor_procedimiento.filter(
          valor_categoria => valor_categoria.catalogo === catalogo
        )
       return (
             <React.Fragment>
               {(data_detalle_filtre) ?
                 data_detalle_filtre.map((row ,index) => (
                   <React.Fragment>
                     {row.avance+"%"}
                   </React.Fragment>
                 ))
                 :null
               }
               </React.Fragment>
             )
    }

  getavance_categoria =(catalogo)=>{
      const data_detalle_filtre =this.state.valor_categoria.filter(
        valor_categoria => valor_categoria.catalogo === catalogo
      )
     return (
           <React.Fragment>
             {(data_detalle_filtre) ?
               data_detalle_filtre.map((row ,index) => (
                 <React.Fragment>
                   {row.avance+"%"}
                 </React.Fragment>
               ))
               :null
             }
             </React.Fragment>
           )
  }


  render() {
    //onClick={ () => this.getDataCategoria(row.catalogo)}
    let formulario
    let subformulario
    const data_categoria = this.state.data_categoria
    const subcategoria = this.state.subcategoria
    const id_modulo = this.state.id_modulo
    const data_detalle = this.props.data_detalle
    let categoria =this.state.categoria


    let dir_modulo_modal

    const firebase =  this.props.firebase
    //console.log("ssss"+firebase)
    const file_250x200 ="app-delivery-8b998"
    const url_image ="https://firebasestorage.googleapis.com/v0/b/"
    const separator ="/o/images%2F"
    const token_image = url_image+file_250x200+separator
    const ext=".png?alt=media"


    if(id_modulo===1||id_modulo===2){
      //console.log(data_detalle)
      if(data_detalle){
       const data_detalle_filtre =data_detalle.filter(
         data_detalle => data_detalle.catalogo === this.state.categoria
       )

      subformulario=
              <tr><td colSpan="2">
                <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
                  <div className="cssversion_pw100 cssversion_ph100 "   style={{"overflowY":"auto"}}>
                    <table className="table_v2" style={{"width":"100%"}}>
                      <thead>
                      <tr>
                       <th style={{"fontSize":"18px"}}>PROCEDIMIENTOS</th><th style={{"width":"30px"}}>Avance</th>
                       </tr>
                      </thead>
                      <tbody>
                        <React.Fragment>
                          {(data_detalle_filtre) ?
                            data_detalle_filtre.map((row ,index) => (
                              <React.Fragment>
                                <tr><td  onClick={ () => this.getOpciones(row.id_formulario_detalle,row.indice)} style={{"fontSize":"18px"}} >{(index+1)+".  "+row.indice}</td><td style={{"textAlign":"right"}}>{this.getavance_indice(row.id_formulario_detalle)}</td> </tr>
                                {(id_modulo===2&&this.state.indice===row.indice)?this.getDataIndice(file_250x200,firebase,token_image,ext):null}
                              </React.Fragment>
                            ))
                            : <React.Fragment></React.Fragment>
                          }

                         </React.Fragment>
                        <tr><td  style={{"fontSize":"19px",  "backgroundColor": "red","color": "white","textAlign": "center"}} colSpan="2" onClick={ () => this.RegresarT()}>REGRESAR</td></tr>
                      </tbody>
                      </table>
                      </div>
                    </div>
                  </td>
                </tr>

        }
    }





    if(this.state.data_categoria){
      formulario=<div id="loader_msg" className="loader_msg loader_msg-default is-active" data-text="">
                          <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
                            <div className="cssversion_pw100 cssversion_ph100 cssversion_contentCenter" style={{"display":"block","overflowY":"auto"}} >
                              <table className="table_v2" style={{"width":"100%"}} >
                                <thead>
                                 <tr>
                                  <th style={{"fontSize":"18px"}}>CATEGORÍAS  </th><th style={{"width":"30px"}}>{this.state.valor_titulo+"%"}</th>
                                 </tr>
                                </thead>
                                <tbody>
                                  {(data_categoria) ?
                                    data_categoria.map((row , index) => (
                                      <React.Fragment>
                                        {(!row.subcategoria)?
                                          <React.Fragment>
                                          <tr><td onClick={ () => this.getDataCategoria(row.categoria)}  style={{"fontSize":"18px"}}>{(index+1)+". "+row.categoria}</td><td style={{"textAlign":"right"}}>{this.getavance_procedimiento(row.categoria)}</td></tr>
                                          {(categoria===row.categoria)?subformulario:null}
                                          </React.Fragment>
                                        :null}
                                      </React.Fragment>
                                    ))
                                    : <React.Fragment></React.Fragment>
                                  }
                                  {(subcategoria) ?
                                    subcategoria.map((row , index) => (
                                      <React.Fragment>
                                      <tr><td  onClick={ () => this.getSwSubCategoria(row.id_catalogo)} style={{"fontSize":"18px"}}><b>{row.catalogo}</b></td><td style={{"textAlign":"right"}}>{this.getavance_categoria(row.catalogo)}</td></tr>
                                      {this.getDataCategoriaSUB(row.catalogo,row.sw,categoria,subformulario)}
                                      </React.Fragment>
                                    ))
                                    : <React.Fragment></React.Fragment>
                                  }
                                  <tr><td  colSpan="2">Conclusión General</td></tr>
                                  <tr><td  colSpan="2"><GetInput name={"conclusion_general"} style={{"width":"95%"}} value={this.state.conclusion_general} return_data={this.return_data} /></td></tr>
                                  <tr><td  colSpan="2">Recomendación General</td></tr>
                                  <tr><td  colSpan="2"><GetInput name={"recomendacion_general"} style={{"width":"95%"}} value={this.state.recomendacion_general} return_data={this.return_data} /></td></tr>


                                  <tr><td colSpan="2"  style={{"fontSize":"18px"}} >Porcentaje Auditor</td></tr>
                                  <tr><td colSpan="2"><GetSelect style={{"fontSize":"18pt"}} style_span={{"fontSize":"18pt"}}name={"valor_porcentaje_aud"} data={this.state.valor_porcentaje_audD} value={this.state.valor_porcentaje_aud} return_data={this.return_data} />
                                  </td></tr>


                                  {(Number(this.state.valor_titulo)===100)?
                                    <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#4CAF50","color": "white","textAlign": "center"}}  colSpan="2" onClick={ () => this.GrabaRT()}>FINALIZAR</td></tr>
                                  :
                                    <tr><td  style={{"fontSize":"19px",  "backgroundColor": "#4CAF50","color": "white","textAlign": "center"}}  colSpan="2"  >100% PARA FINALIZAR</td></tr>
                                  }
                                  <tr ><td colSpan="2"   style={{"fontSize":"19px",  "backgroundColor": "red","color": "white","textAlign": "center"}} onClick={ () => this.SalirT()}>SALIR</td></tr>
                                </tbody>
                                </table><br/><br/><br/>
                              </div>
                            </div>
                          </div>
    }

    /**/
    /* "token":"GRUPO_MAITRE&NEOLA_",
					 "api_key_google":"AIzaSyAtyO9FEIXPiDDSLE1a88MNGVpLKgxnxqo",
					 "url_image":"https://firebasestorage.googleapis.com/v0/b/",
					 "separator":"/o/images%2F",
					 "file_60x60":"app-delivery-8b998-60x60",
					 "file_250x200":"app-delivery-8b998-250x200",
					 "file_500x200":"app-delivery-8b998-500x200",
					 "file_250x250":"app-delivery-8b998-250x250",
					 "database":"'.$this->db->database.'"*/



    return (
      <React.Fragment>
        <div className="formulario white">
        {formulario}
        </div>
        {dir_modulo_modal}
       </React.Fragment>
    )
  }
  getUUID =(uuid_imagen,id_rubro,uuid_imagen_2,index)=>{
    let uuid_image_data = this.state.uuid_image_data
    console.log(index)
    uuid_image_data[index].uuid_imagen=uuid_imagen
    this.setState({
      uuid_image_data
    })

  }
  componentDidMount (){
    this.actualizar_select();
    this.verificar_avance_total();
  }
  actualizar_total_avance =()=>{
    const id_formulario_cabecera = Number(this.props.id_formulario_cabecera)
    const avance = Number(this.state.valor_titulo)
    let select = [{
    type: 'query', data:"update formulario_cabecera set avance="+avance+" where id_formulario_cabecera="+id_formulario_cabecera}]
    var result = Selectbd(select)
    if(result){
       result.then((value) => {
       })
    }
  }
  verificar_avance_total =()=>{

    //



    const id_formulario = Number(this.props.id_formulario)
    const id_formulario_cabecera = Number(this.props.id_formulario_cabecera)
    const select = [{
     type: 'consulta', data:
     [{
       table : "(select  cast((sum(if(b.id_formulario_detalle is null,0,1))/count(*))*100 as decimal(20,2)) as avance from (SELECT f.* FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice left join catalogo cs on cs.id_catalogo=f.id_subcategoria) a left join (SELECT * FROM formulario_items where id_formulario_cabecera="+id_formulario_cabecera+") b on a.id_formulario_detalle=b.id_formulario_detalle)",
       field :[],
       return:['avance']
     },
     {
       table : "(  select  cast((sum(if(b.id_formulario_detalle is null,0,1))/count(*))*100 as decimal(20,2)) as avance,catalogo from (SELECT f.*,cs.catalogo FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice inner join catalogo cs on cs.id_catalogo=f.id_subcategoria) a left join (SELECT * FROM formulario_items where id_formulario_cabecera="+id_formulario_cabecera+") b on a.id_formulario_detalle=b.id_formulario_detalle group by catalogo)",
       field :[],
       return:['avance','catalogo']
     },
     {
       table : "(select  cast((sum(if(b.id_formulario_detalle is null,0,1))/count(*))*100 as decimal(20,2)) as avance,catalogo from (SELECT f.*,c.catalogo FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice) a left join (SELECT * FROM formulario_items where id_formulario_cabecera="+id_formulario_cabecera+") b on a.id_formulario_detalle=b.id_formulario_detalle group by catalogo)",
       field :[],
       return:['avance','catalogo']
     },
     {
       table : "(select  cast(((if(b.id_formulario_detalle is null,0,1)))*100 as decimal(20,2)) as avance,a.id_formulario_detalle from (SELECT f.*,c.catalogo FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice) a left join (SELECT * FROM formulario_items where id_formulario_cabecera="+id_formulario_cabecera+") b on a.id_formulario_detalle=b.id_formulario_detalle)",
       field :[],
       return:['avance','id_formulario_detalle']
     }]
   }]
   var result = Selectbd(select)
   if(result){
     result.then((value) => {
       let valor_titulo =0
       let valor_categoria =[]
       let valor_procedimiento=[]
       let valor_indice=[]
       if(value[1].status==="ok"){
         valor_titulo= Number(value[1].result[0].avance)
       }
       if(value[2].status==="ok"){
         valor_categoria= value[2].result
       }
       if(value[3].status==="ok"){
         valor_procedimiento= value[3].result
       }
       if(value[4].status==="ok"){
         valor_indice=  value[4].result
       }
       this.setState({
         valor_titulo,
         valor_categoria,
         valor_procedimiento,
         valor_indice
       },()=>{
         this.actualizar_total_avance()
       })
     });
   }
  }

}

export default getFormularioNew;
