import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getUsuarios extends Component {
  //id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, id_estado, correo_electronico
  constructor(){/**********/
    super();
    this.state = {
        id_usuario:0,
        usuario:"",
        clave:"",
        id_tipo_rol:-1,
        apellidos:"",
        nombres:"",
        id_estado:-1,
        correo_electronico:"",
        data_tipo_rol: [],
        data_estado: [],
        data_usuario:[],
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    }
  }
  return_limpiar = ()=>{
    this.setState ({
        id_usuario:0,
        usuario:"",
        clave:"",
        id_tipo_rol:-1,
        apellidos:"",
        nombres:"",
        id_estado:-1,
        correo_electronico:"",
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    })
  }
  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())

  }
  grabar = ()=>{

    const id_usuario = this.state.id_usuario

    if(!validate_text(this.state.usuario)){
      alert('Ingrese Usuario')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.clave)){
      alert('Ingrese Clave')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.apellidos)){
      alert('Ingrese Apellidos')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.nombres)){
      alert('Ingrese Nombres')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_tipo_rol)===-1){
      alert('Seleccione Tipo de Rol')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    var select
    if(Number(id_usuario)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'usuario',
          field :[{
            name : 'usuario',
            value : this.state.usuario,
            type:'string'
          },{
            name : 'clave',
            value : this.state.clave,
            type :'string'
          },{
            name : 'apellidos',
            value : this.state.apellidos,
            type :'string'
          },{
            name : 'nombres',
            value : this.state.nombres,
            type :'string'
          },{
            name : 'correo_electronico',
            value : this.state.correo_electronico,
            type :'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'id_tipo_rol',
            value : this.state.id_tipo_rol,
            type :'integer'
          }],
          return:['id_usuario']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'usuario',
          field :[{
            name : 'usuario',
            value : this.state.usuario,
            type:'string'
          },{
            name : 'clave',
            value : this.state.clave,
            type :'string'
          },{
            name : 'apellidos',
            value : this.state.apellidos,
            type :'string'
          },{
            name : 'nombres',
            value : this.state.nombres,
            type :'string'
          },{
            name : 'correo_electronico',
            value : this.state.correo_electronico,
            type :'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'id_tipo_rol',
            value : this.state.id_tipo_rol,
            type :'integer'
          }],
          id_name : "id_usuario",
          id_value : id_usuario,
          id_type : "integer",
          return:['id_usuario']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  //id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, id_estado, correo_electronico
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, us.id_estado, correo_electronico, ct.catalogo as tipo_rol, if(us.id_estado=0,'INACTIVO','ACTIVO') as estado,us.eliminado FROM usuario us INNER JOIN catalogo ct ON ct.id_catalogo=us.id_tipo_rol)",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "usuario",
        return:['id_usuario','usuario','clave','id_tipo_rol','apellidos','nombres','id_estado','correo_electronico','tipo_rol','estado']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_usuario : value[1].result
          })
        }else{
          this.setState({
            data_usuario : []
          })
        }
      });
    }
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '1',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      },
      {
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"){
          this.setState({
            data_tipo_rol : value[1].result,
            data_estado : value[2].result
          })
        }
      });
    }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'usuario',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_usuario",
          id_value : data.id_usuario,
          id_type : "integer",
          return:['id_usuario']
        }]
      }]
      console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
          id_usuario:data.id_usuario,
          usuario:data.usuario,
          clave:data.clave,
          id_tipo_rol:data.id_tipo_rol,
          apellidos:data.apellidos,
          nombres:data.nombres,
          id_estado:data.id_estado,
          correo_electronico:data.correo_electronico
      })
    }
  }
  render() {

    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Usuario"} name={"usuario"} value={this.state.usuario} return_data={this.return_data} />
          <GetInput label={"Clave"} name={"clave"}  type={"password"} value={this.state.clave} return_data={this.return_data}  />
          <GetInput label={"Apellidos"} name={"apellidos"} value={this.state.apellidos} return_data={this.return_data} />
          <GetInput label={"Nombres"} name={"nombres"} value={this.state.nombres} return_data={this.return_data} />
          <GetInput label={"Correo Electrónico"} name={"correo_electronico"} value={this.state.correo_electronico} return_data={this.return_data} />
          <GetSelect label={"Tipo Rol"} name={"id_tipo_rol"} data={this.state.data_tipo_rol} value={this.state.id_tipo_rol} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_usuario"} data={this.state.data_usuario}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getUsuarios;
