import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getHomologacionItem extends Component {
  constructor(){/**********/
    super();
    this.state = {
      data_sucursal:[],
      data_lista_actual:[],
      data_lista_actual2:[],
      id_sucursal:'0'
    }
  }
  return_data =(name,value)=>{

    this.setState({
      [name]:value
    } )
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT cast(id_usuario_globales as char) as id,cast(etiqueta as char) as name FROM bd_matriz_maitreneola.usuario_globales  where activo=1 and sw_cabecera>0)",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_sucursal : value[1].result
          })
        }
      });
    }
  }
//id_item_sucursal, item, cod_fabrica, item1, cod_fabrica_3
return_coincidencia = ()=>{
  this.setState({
    data_lista_actual : [],
    data_lista_actual2: []
  })
  const id_sucursal = Number(this.state.id_sucursal)
  if(id_sucursal>0){
      const select = [{
        type: 'consulta', data:
        [{
          table : "(SELECT i.id_item_sucursal,id_items,i.item,i.cod_fabrica,cast(l.item as char) as item1,l.cod_fabrica_3 FROM bd_matriz_maitreneola.items i inner join bd_centralizacion_lcr.item1 l on l.cod_fabrica=i.cod_fabrica  where id_usuario_globables="+id_sucursal+" and not trim(l.cod_fabrica_3)='' and trim(i.tabla_maitre)='')",
          field :[],
          order_by: "item",
          id_sucursal,
          return:['id_item_sucursal','id_items', 'item', 'cod_fabrica', 'item1', 'cod_fabrica_3']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              data_lista_actual:[],
              data_lista_actual2 : value[1].result
            })
          }else{
            this.setState({
              data_lista_actual : [],
              data_lista_actual2: []
            })
          }
        });
      }
  }
}
 return_buscar =()=>{
   this.setState({
     data_lista_actual : [],
     data_lista_actual2: []
   })
   const id_sucursal = Number(this.state.id_sucursal)
   if(id_sucursal>0){
       const select = [{
         type: 'consulta', data:
         [{
           table : "(select id_item_sucursal,cod_fabrica,cod_global,item,if(tabla_maitre='OK','SI',if(tabla_maitre='ELIMINADO','ELIMINADO','NO')) as homologado from items i where id_usuario_globables="+id_sucursal+"  order by item )",
           field :[],
           order_by: "item",
           id_sucursal,
           return:['id_item_sucursal', 'cod_fabrica', 'cod_global', 'item', 'homologado']
         }]
       }]
       var result = Selectbd(select)
       if(result){
         result.then((value) => {
           if(value[1].status==="ok"){
             this.setState({
               data_lista_actual : value[1].result
             })
           }else{
             this.setState({
               data_lista_actual : []
             })
           }
         });
       }
   }
 }
  getUnificar =(id_items,cod_fabrica_3,item,jhis)=>{
    //console.log(jhis)
    var select

      select = [{
        type: 'actualizar', data:
        [{
          table : 'items',
          field :[{
            name : 'tabla_maitre',
            value : 'OK',
            type:'string'
          },{
            name : 'cod_global',
            value : cod_fabrica_3,
            type:'string'
          },{
            name : 'item',
            value :item,
            type:'string'
          }],
          id_name : "id_items",
          id_value : id_items,
          id_type : "integer",
          return:['id_items']
        }]
      }]

    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          //this.actualizar_tabla()
          //this.return_limpiar()
        }
      })
    }
  }
  render() {
    const data_lista_actual = this.state.data_lista_actual
    const data_lista_actual2 = this.state.data_lista_actual2
    return (
      <React.Fragment>
        
        <GetSelect label={"Sucursal"} name={"id_sucursal"} data={this.state.data_sucursal} value={this.state.id_sucursal} return_data={this.return_data} />
        <GetButton    name={"actualizar"} value={"Actualizar"} return_accion={this.return_buscar}  />
        <GetButton    name={"coincidencias"} value={"Coincidencia Codigo Antiguo"} return_accion={this.return_coincidencia}  />
        <br/>
        <table className="tb_pedidos">
          <thead>
           <tr><th>id_item</th><th>Código Antiguo</th><th>Código Nuevo</th><th>Producto</th><th>Homologado</th></tr>
          </thead>
          <tbody>
          {(data_lista_actual.length !== 0) ?
            data_lista_actual.map(row => (
              <tr><td>{row.id_item_sucursal}</td><td>{row.cod_fabrica}</td><td>{row.cod_global}</td><td>{row.item}</td><td>{row.homologado}</td></tr>
            ))
            : <React.Fragment></React.Fragment>
          }
          </tbody>
        </table>
        <table className="tb_pedidos">
          <thead>
           <tr><th>id_item</th><th>Código Antiguo</th><th>Código Nuevo</th><th>Producto Antiguo</th><th>Producto Nuevo</th><th>Opciones</th></tr>
          </thead>
          <tbody>
          {(data_lista_actual2.length !== 0) ?
            data_lista_actual2.map(row => (
              <tr><td>{row.id_item_sucursal}</td><td>{row.cod_fabrica}</td><td>{row.cod_fabrica_3}</td><td>{row.item}</td><td>{row.item1}</td>
              <td><button class="button" onClick={ (e) => this.getUnificar(row.id_items,row.cod_fabrica_3,row.item1,e)}  >Unificar</button></td></tr>
            ))
            : <React.Fragment></React.Fragment>
          }
          </tbody>
        </table>

      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select();
  }

}

export default getHomologacionItem;
