import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getFormulas extends Component {
 /*id_produccion_formula, produccion_formula, codigo, registro, fecha_creacion, id_creado_por,
 eliminado, tipo_item, id_item, cantidad, id_medida, sw_cabecera, costo_, id_bodega_*/
  constructor(){
    super();
    this.state = {
        id_produccion_formula:0,
        id_produccion_formula_2:0,
        codigo:"",
        produccion_formula:"",
        data_formula:[],
        data_insumo:[],
        data_insumo_select:[],
        id_produccion_formula_select:0,
        cantidad:"",
        id_usuario :Number(localStorage.getItem("neolav2_uuid_usuario"))
    }
  }
  return_limpiar = ()=>{
    this.setState ({
        id_produccion_formula:0,
        id_produccion_formula_2:0,
        id_produccion_formula_select:0,
        codigo:"",
        cantidad:"",
        produccion_formula:"",
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false,
        btn_limpiar_enabled_2 : false,
        btn_grabar_enabled_2 : false,
        data_insumo:[]
    })
  }
  return_limpiar_2 = ()=>{
    this.setState ({
        id_produccion_formula_select:0,
        id_produccion_formula_2:0,
        cantidad:"",
        btn_limpiar_enabled_2 : false,
        btn_grabar_enabled_2 : false
    })
  }
  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())

  }
  grabar = ()=>{
    const id_produccion_formula = this.state.id_produccion_formula
    if(!validate_text(this.state.produccion_formula)){
      alert('Ingrese Detalle')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.codigo)){
      alert('Ingrese Código')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    const date = new Date();
    const mysqlDate = date.toISOString().split("T")[0];
    var select
    if(Number(id_produccion_formula)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'produccion_formula',
          field :[{
            name : 'produccion_formula',
            value : this.state.produccion_formula,
            type:'string'
          },{
            name : 'codigo',
            value : this.state.codigo,
            type:'string'
          },{
            name : 'fecha_creacion',
            value : mysqlDate,
            type:'string'
          },{
            name : 'id_creado_por',
            value : this.state.id_usuario,
            type:'integer'
          },{
            name : 'eliminado',
            value : '0',
            type :'integer'
          }],
          return:['id_produccion_formula']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'produccion_formula',
          field :[{
            name : 'produccion_formula',
            value : this.state.produccion_formula,
            type:'string'
          },{
            name : 'codigo',
            value : this.state.codigo,
            type:'string'
          }],
          id_name : "id_produccion_formula",
          id_value : id_produccion_formula,
          id_type : "integer",
          return:['id_produccion_formula']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          if(Number(id_produccion_formula)===0){
            this.setState({
              id_produccion_formula : value[1].result[0].id_produccion_formula
            },()=>{
              this.actualizar_tabla()
            })
          }else{
            this.actualizar_tabla()
            this.return_limpiar()
          }
        }
      })
    }
  }



  return_grabar_2 =()=>{
    this.setState({
      btn_grabar_enabled_2:true
    },()=>this.grabar_2())

  }
  grabar_2 = ()=>{
    const id_produccion_formula_select = this.state.id_produccion_formula_select
    const id_produccion_formula_2 = this.state.id_produccion_formula_2

    if(Number(id_produccion_formula_select)<=0){
      alert('Ingrese Insumo')
      this.setState({
        btn_grabar_enabled_2:false
      })
      return null
    }
    if(!validate_text(this.state.cantidad)){
      alert('Ingrese Cantidad')
      this.setState({
        btn_grabar_enabled_2:false
      })
      return null
    }
    if(Number(this.state.cantidad)<=0){
      alert('Ingrese Cantidad')
      this.setState({
        btn_grabar_enabled_2:false
      })
      return null
    }


    const date = new Date();
    const mysqlDate = date.toISOString().split("T")[0];
    var select
    if(Number(id_produccion_formula_2)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'produccion_formula',
          field :[{
            name : 'tipo_item',
            value : '2',
            type:'string'
          },{
            name : 'id_item',
            value : id_produccion_formula_select,
            type:'integer'
          },{
            name : 'cantidad',
            value : Number(this.state.cantidad),
            type:'string'
          },{
            name : 'fecha_creacion',
            value : mysqlDate,
            type:'string'
          },{
            name : 'id_creado_por',
            value : this.state.id_usuario,
            type:'integer'
          },{
            name : 'eliminado',
            value : '0',
            type :'integer'
          },{
            name : 'id_medida',
            value : '2',
            type :'integer'
          },{
            name : 'sw_cabecera',
            value : this.state.id_produccion_formula,
            type :'integer'
          }],
          return:['id_produccion_formula']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'produccion_formula',
          field :[{
            name : 'id_item',
            value : id_produccion_formula_select,
            type:'integer'
          },{
            name : 'cantidad',
            value : Number(this.state.cantidad),
            type:'string'
          }],
          id_name : "id_produccion_formula",
          id_value : id_produccion_formula_2,
          id_type : "integer",
          return:['id_produccion_formula']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.return_table_insumos()
          this.return_limpiar_2()
        }
      })
    }
  }


  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(select id_produccion_formula,codigo,produccion_formula,cast(DATE_FORMAT(p.fecha_creacion,'%Y-%m-%d %H:%i') AS CHAR) AS FechaCreacion,usuario,p.eliminado FROM produccion_formula p INNER JOIN usuario u ON u.id_usuario=p.id_creado_por WHERE p.sw_cabecera=0 AND p.eliminado=0)",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "codigo",
        return:['id_produccion_formula','codigo','produccion_formula','FechaCreacion','usuario']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_formula : value[1].result
          })
        }else{
          this.setState({
            data_formula : []
          })
        }
      });
    }
  }

  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  return_data =(name,value)=>{

    this.setState({
      [name]:value
    } )
  }



  actualizar_formulas=()=>{
      let select = [{
      type: 'query', data:"update produccion_formula p , item2 i set p.costo_=i.precio_costo where i.id_item =p.id_item"}]
      var result = Selectbd(select)
      if(result){
         result.then((value) => {
         })
      }

      select = [{
      type: 'query', data:"update produccion_formula c ,(SELECT cast(sum(cantidad*costo_) as decimal(20,2)) as costo,sw_cabecera FROM produccion_formula where sw_cabecera>0 and eliminado=0 group by sw_cabecera) d set c.costo_=d.costo where c.id_produccion_formula=d.sw_cabecera"}]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
        })
      }


  }


  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_item as id,if(medida is null,item,concat(item,' - ',medida)) as name FROM item2 i INNER JOIN medida m ON m.id_medida=i.id_medida)",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_insumo_select : value[1].result
          })
        }
      });
    }
  }
  return_table_eliminar_2 =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'produccion_formula',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_produccion_formula",
          id_value : data.id_produccion_formula,
          id_type : "integer",
          return:['id_produccion_formula']
        }]
      }]
      //console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.return_table_insumos()
            this.return_limpiar_2()
          }
        })
      }
    }
  }
  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'produccion_formula',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_produccion_formula",
          id_value : data.id_produccion_formula,
          id_type : "integer",
          return:['id_produccion_formula']
        }]
      }]
      //console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }
  return_table_insumos =()=>{
    const id_produccion_formula = this.state.id_produccion_formula
    if(Number(id_produccion_formula)>0){
      const select = [{
        type: 'consulta', data:
        [{
          table : "(SELECT p.id_produccion_formula,i.cod_fabrica as codigo,i.item as detalle,p.cantidad as cantidad,m.medida ,p.eliminado,p.id_item from produccion_formula p inner join item2 i on i.id_item=p.id_item and tipo_item in(2,1) and p.sw_cabecera>0 and p.sw_cabecera="+id_produccion_formula+"  inner join medida m on m.id_medida=p.id_medida)",
          field :[{
            name : 'eliminado',
            value : '0',
            type:'integer'
          }],
          order_by: "trim(detalle)",
          return:['id_produccion_formula','codigo','detalle','cantidad','medida','id_item']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              data_insumo : value[1].result
            })
          }else{
            this.setState({
              data_insumo : []
            })
          }
        });
      }
    }else{
      this.setState({
        data_insumo : []
      })
    }
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
          id_produccion_formula:data.id_produccion_formula,
          codigo:data.codigo,
          produccion_formula:data.produccion_formula
      },()=>{
        this.return_table_insumos()
      })
    }
  }
  return_table_edicion_2 =(data)=>{
    if(data){
      console.log(data)
      this.setState ({
          id_produccion_formula_2:data.id_produccion_formula,
          cantidad:data.cantidad,
          id_produccion_formula_select:data.id_item
      },()=>{
        //this.return_table_insumos()
      })
    }
  }

  render() {
    const id_produccion_formula = this.state.id_produccion_formula
    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Código"} name={"codigo"} value={this.state.codigo} return_data={this.return_data} />
          <GetInput label={"Fórmula"} name={"produccion_formula"} value={this.state.produccion_formula} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        {(Number(id_produccion_formula)==0)?
        <GetTable name={"tabla_formula"} data={this.state.data_formula}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
         :
           <React.Fragment>
           <div className="formulario white">
             <GetInput label={"Cantidad"} name={"cantidad"} value={this.state.cantidad} return_data={this.return_data} />
             <GetSelect label={"Insumo"} name={"id_produccion_formula_select"} data={this.state.data_insumo_select} value={this.state.id_produccion_formula_select} return_data={this.return_data} />
             <GetButton disabled={this.state.btn_limpiar_enabled_2} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar_2}  />
             <GetButton disabled={this.state.btn_grabar_enabled_2}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar_2}/>
           </div>
           <GetTable name={"tabla_insumo"} data={this.state.data_insumo}  return_table_edicion={this.return_table_edicion_2}
            return_table_eliminar={this.return_table_eliminar_2}  />
          </React.Fragment>
        }
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_tabla()
    this.actualizar_select()
    this.actualizar_formulas()
  }

}

export default getFormulas;
