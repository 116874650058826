import React, { Component } from 'react';

class getSelect extends Component {
  constructor(props){
     super();
       //console.log("Carga2 "+ props.data)
     this.state = {
       width :props.width,
       name : props.name,
       value : props.value,
       style : props.style,
       label : props.label,
       visibled : (props.visibled)?props.visibled:true
     }
  }
  inputChangedHandler = (event)=>{
    //console.log(event.target)
    var index = event.nativeEvent.target.selectedIndex;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const label = event.nativeEvent.target[index].text;
    this.setState({
      value
    },()=>{
      this.props.return_data(name,value,label)
    });
  }

  render () {
   const data = this.props.data ?   this.props.data :[]
   const visibled =(this.props.visibled)?this.props.visibled:"OK"
   //console.log(visibled+" "+this.props.name);
   return (
     <React.Fragment>
     {(visibled==="OK")?
       <React.Fragment>
      <label for={this.state.name}>{this.state.label}</label>
      <select style={(this.state.style)?this.state.style:null} name={this.state.name} id={this.state.name} value={this.state.value} style={{"width":(this.state.width)?this.state.width:"auto"}} onChange={(e) => {this.inputChangedHandler(e)}}>
          <option value="-1">Seleccione {this.state.label}</option>
          {(data) ?
            data.map(row => (
              <option key={row.id} value={row.id}>{row.name}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
      </select>
      </React.Fragment>: null}
     </React.Fragment>
   )
  }
  componentDidUpdate(){
    if(this.state.value !== this.props.value){
       this.setState({
        value : this.props.value
      })
    }
  }

}
export default getSelect;
