import React, { Component } from 'react';
 

class Checkbox extends Component {
  render() {
    const { id, label, activeImg, inactiveImg, checked, onChange } = this.props;
    return (
      <label className="checkbox-label" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          className="checkbox-input"
          checked={checked}
          onChange={onChange}
        />
        <span
          className="checkbox-custom"
          style={{
            backgroundImage: `url(${checked ? activeImg : inactiveImg})`,
          }}
        ></span>
        {label}
      </label>
    );
  }
}

export default Checkbox;