import React, { Component } from 'react';
import Selectbd from '../bd/getDb';
import GetTable from '../util/getTable';
import DatePicker from "react-datepicker";
import GetButton from '../util/getButton';
import GetInput from '../util/getInput';
import "react-datepicker/dist/react-datepicker.css";
import {HorizontalBar,Doughnut} from 'react-chartjs-2';
class getDashboardSurvey extends Component {

  constructor(){/**********/
    super();
    this.state = {
        date_desde : new Date(),
        date_hasta : new Date(),
        url_global:"https://grupomn.net/neola/index.php/webservices/",
        data_productos:[],
        rubros:[],
        empresas:[],
        id_usuario_globales:0,
        id_rubro:0,
        busqueda:'',
        numero_encuestas:0,
        data_mejores:[],
        data_peores:[],
        data_mejoras:[],
        promotor:0,
        pasivo:0,
        detractor:0,
        por_promotor:0, 
        por_detractor:0,
        sw_contactarse:0,
        options:  {
          indexAxis: 'y', // Esto convierte el gráfico en barras horizontales
          scales: {
            x: {
              min: 0, // Valor mínimo en el eje x
              max: 100, // Valor máximo en el eje x
              ticks: {
                stepSize: 20, // Incrementos en el eje x
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Horizontal Bar Chart',
            },
          },
        }
    }
  }

  buscar_nps =()=>{
    const desde = this.formatDate(this.state.date_desde)
    const hasta = this.formatDate(this.state.date_hasta)
    const select = [{
        type: 'consulta', data:
        [{
          table : "(SELECT count(*) as encuestas FROM survey  where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
          field :[],
          return:['encuestas']
        },
        {
            table : "(select * from (SELECT cast(sum(calificacion)/count(*) as decimal(20,2)) as calificacion,count(*) as encuestas,nombre_local FROM survey s inner join `local` l on l.id_local=s.id_local where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' group by s.id_local) a where calificacion>=9 order by calificacion desc limit 5)",
            field :[],
            return:['calificacion', 'encuestas', 'nombre_local']
        },
        {
            table : "(select * from (SELECT cast(sum(calificacion)/count(*) as decimal(20,2)) as calificacion,count(*) as encuestas,nombre_local FROM survey s inner join `local` l on l.id_local=s.id_local where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' group by s.id_local) a where calificacion<=6 order by calificacion asc limit 5)",
            field :[],
            return:['calificacion', 'encuestas', 'nombre_local']
        },{
            table : "(SELECT sum(if(calificacion>=9,1,0)) as promotor,sum(if(calificacion<=8 and calificacion>=7,1,0)) as pasivo,sum(if(calificacion<=8 and calificacion<=6,1,0)) as detractor,cast((sum(if(calificacion>=9,1,0))/count(*))*100 as decimal(20,2)) as por_promotor, cast((sum(if(calificacion<=6,1,0))/count(*))*100 as decimal(20,2)) as por_detractor  FROM survey s where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59')",
            field :[],
            return:['promotor','pasivo','detractor','por_promotor','por_detractor']
        },
        {
            table : "(SELECT count(*) as encuestas FROM survey  where sw_contactarse=1 and fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
            field :[],
            return:['encuestas']
          },{
            table : "(SELECT sum(sw_atencion) as sw_atencion, sum(sw_calidad) as sw_calidad, sum(sw_instalacion) as sw_instalacion, sum(sw_precio) as sw_precio, sum(sw_variedad) as sw_variedad FROM survey  where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
            field :[],
            return:['sw_atencion', 'sw_calidad', 'sw_instalacion', 'sw_precio', 'sw_variedad']
          },]
      }]
      var result = Selectbd(select)
        if(result){
            result.then((value) => {
                if(value[1].status==="ok"){
                    this.setState({
                        numero_encuestas : Number(value[1].result[0].encuestas)
                    })
                }else{
                    this.setState({
                        numero_encuestas : 0
                    })
                }
                if(value[2].status==="ok"){
                    this.setState({
                        data_mejores : value[2].result
                    })
                }else{
                    this.setState({
                        data_mejores : []
                    })
                }
                if(value[3].status==="ok"){
                    this.setState({
                        data_peores : value[3].result
                    })
                }else{
                    this.setState({
                        data_peores : []
                    })
                }
                if(value[4].status==="ok"){
                    this.setState({
                        promotor : Number(value[4].result[0].promotor),
                        pasivo : Number(value[4].result[0].pasivo),
                        detractor : Number(value[4].result[0].detractor),
                        por_promotor : Number(value[4].result[0].por_promotor), 
                        por_detractor : Number(value[4].result[0].por_detractor)
                    })
                }else{
                    this.setState({
                        promotor : 0, pasivo: 0, detractor:0,por_detractor:0,por_promotor:0
                    })
                }
                if(value[5].status==="ok"){
                    this.setState({
                        sw_contactarse : Number(value[5].result[0].encuestas)
                    })
                }else{
                    this.setState({
                        sw_contactarse : 0
                    })
                }
                if(value[6].status==="ok"){
                    this.setState({
                        data_mejoras :value[6].result
                    })
                }else{
                    this.setState({
                        data_mejoras : []
                    })
                }


            })
        }
  }

  onChangeDesde = date => this.setState({ date_desde:date},()=>this.buscar_nps())

  onChangeHasta = date => this.setState({ date_hasta:date },()=>this.buscar_nps())

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }
   

   return_table_edicion =()=>{

   }
   return_table_eliminar =()=>{

   }

   formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  render() {
     
    const numero_encuestas = this.state.numero_encuestas
    const data_mejores= this.state.data_mejores
    const data_peores= this.state.data_peores
    const promotor= this.state.promotor
    const pasivo= this.state.pasivo
    const detractor= this.state.detractor
    const data_mejoras =this.state.data_mejoras


    const por_promotor= this.state.por_promotor
    const por_detractor= this.state.por_detractor

    let json_mejores = 
    {
"labels" : ['local1','local2'],
"datasets": [
    {
    "label": "Local",
    "data": [2,4],
    "borderColor": "white",
    "backgroundColor": "#338d48"
    }
]
}
let json_peores = 
{
"labels" : ['local1','local2'],
"datasets": [
{
"label": "Local",
"data": [2,4],
"borderColor": "white",
"backgroundColor": "#338d48"
}
]
}
let sw_atencion=0
let sw_calidad=0
let sw_instalacion=0
let sw_precio=0
let sw_variedad=0
if(data_mejoras.length>0){  
  //sw_atencion, sw_calidad, sw_instalacion, sw_precio, sw_variedad
  sw_atencion =  data_mejoras[0].sw_atencion
  sw_calidad =  data_mejoras[0].sw_calidad
  sw_instalacion =  data_mejoras[0].sw_instalacion
  sw_precio =  data_mejoras[0].sw_precio
  sw_variedad =  data_mejoras[0].sw_variedad
}
const data_ ={"datasets": [{"data": [sw_atencion,sw_calidad,sw_instalacion,sw_precio,sw_variedad],"backgroundColor": [
  "#336699",
  "#99CCFF",
  "#999933",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF",
  "#993300",
  "#CCCC99",
  "#666666",
]}],"labels": ["Atención al cliente","Calidad del Producto","Instalaciones","Precio","Variedad de platos"],

plugins: {
  labels: {
    render: "percentage", 
    precision: 2
  }
}}


const chartOptions = {
  plugins: {
    datalabels: {
      formatter: (value, context) => {
        const total = context.dataset.data.reduce((sum, current) => sum + current, 0);
        const percentage = ((value / total) * 100).toFixed(2) + '%';
        return percentage;
      },
      color: '#fff',
      labels: {
        title: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  }
};

 
    if(data_mejores.length>0){    
        const labels = data_mejores.map(local => local.nombre_local);
        const data = data_mejores.map(local => parseFloat(local.calificacion));
        json_mejores = 
                {
            "labels" : labels,
            "datasets": [
                {
                "label": "Local",
                "data": data,
                "borderColor": "white",
                "backgroundColor": "#338d48"
                }
            ]
            }
    }
    if(data_peores.length>0){    
        const labels = data_peores.map(local => local.nombre_local);
        const data = data_peores.map(local => parseFloat(local.calificacion));
        json_peores = 
                {
            "labels" : labels,
            "datasets": [
                {
                "label": "Local",
                "data": data,
                "borderColor": "white",
                "backgroundColor": "#338d48"
                }
            ]
            }
    }
    
    return (
      <React.Fragment>
        Selecionar Fechas:
        <label>Desde</label><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
        <label>Hasta</label><DatePicker selected={this.state.date_hasta} onChange={this.onChangeHasta} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /><br/>
        {(numero_encuestas>0)?
            <table className='formato_das' border="0" style={{"width":"100%"}}>
            <tr>
                <td><b>NPS General</b> <br/>{(por_promotor-por_detractor).toFixed(2)}%</td>
                <td><b>Total Encuestas</b><br/>{numero_encuestas}</td><td><b>Promotores</b><br/>{promotor}</td><td><b>Pasivos</b><br/>{pasivo}</td><td><b>Detractores</b><br/>{detractor}</td>
            </tr>
            <tr><td colSpan={5}>
                <table  className='formato_das'  border="0" style={{"width":"100%"}}><tr>
                    <td><b>Locales con Mejor NPS</b><br/>
                    <div style={{"height":"300px"}}>
                    {(data_mejores.length>0)?
                    <HorizontalBar
                        data={json_mejores} options={this.state.options} />:null}</div>
                    </td><td><b>Locales con NPS más Bajo</b><br/>
                    <div style={{"height":"300px"}}>
                    {(data_peores.length>0)?
                    <HorizontalBar
                        data={json_peores} options={this.state.options} />:null}</div></td><td><b>Clientes que piden ser Contactados</b><br/>{this.state.sw_contactarse}</td>
                </tr></table>
                </td>
            </tr>
            <tr><td colSpan={5}>
            <table  className='formato_das'  border="0" style={{"width":"100%"}}><tr>
                    <td> 
                 
                    <div className="lbl_chart_2">
                    <h2></h2>
                    <h2>Áreas de Mejoras</h2>
                      <div className="lbl_chart_2_sub">
                      
                        <React.Fragment>
                          <Doughnut
                            data={data_}
                            width={100}
                            height={80}
                            options={{
                              maintainAspectRatio: false
                            }} />
                        </React.Fragment>
                        
                      </div>
                    </div> 
                  </td>
                </tr></table>
              </td></tr>
            </table>
        :null}
      </React.Fragment>

    )
  }
  mostrarLineas = () => {
    const rubros = this.state.rubros;

    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_rubro} onChange={(e) => {this.handleChangeE3(e)}}>
          <option value="0">Seleccione Rubro</option>
          {(rubros.length !== 0) ?
            rubros.map(rubro => (
              <option key={rubro.id_rubro} value={rubro.id_rubro}>{rubro.rubro}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }
  mostrarEmpresas = () => {
    const clientes = this.state.empresas;
    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_usuario_globales} onChange={(e) => {this.handleChangeE2(e)}}>
          <option value="0">Seleccione Empresa</option>
          {(clientes.length !== 0) ?
            clientes.map(cliente => (
              <option key={cliente.id_usuario_globales} value={cliente.id_usuario_globales}>{cliente.etiqueta}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }


  handleChangeE2(event) {
    //let id_usuario= event.target.value;
    //let anio= this.state.anio;
    this.setState({
      id_usuario_globales: event.target.value
    });
  }

  handleChangeE3(event) {
    let id_rubro= event.target.value;
    //let anio= this.state.anio;
    this.setState({
      id_rubro: event.target.value
    });
  }
  componentDidMount (){
    //
  /*  const url=  this.state.url_global+"getEmpresaAPP";
      fetch( url)
      .then(res => res.json())
      .then((data) => {
        this.setState({ empresas:data })
      })
      .catch(console.log)*/




      const select = [{
        type: 'consulta', data:
        [{
          table : "(SELECT id_usuario_globales,etiqueta FROM usuario_globales  where activo=1 )",
          field :[],
          order_by: "etiqueta",
          return:['id_usuario_globales','etiqueta']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              empresas : value[1].result
            })
          }else{
            this.setState({
              empresas : []
            })
          }
        });
      }

      const select1 = [{
        type: 'consulta', data:
        [{
          table : "(SELECT id_rubro,rubro FROM rubro )",
          field :[],
          order_by: "rubro",
          return:['id_rubro','rubro']
        }]
      }]
      var result = Selectbd(select1)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              rubros : value[1].result
            })
          }else{
            this.setState({
              rubros : []
            })
          }
        });
      }
  }

}

export default getDashboardSurvey;
