import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getProductos extends Component {
//id_item_global, cod_global, item, id_rubro, id_servicio, cod_grupal, id_presentacion, id_estado, eliminado
  constructor(){/**********/
    super();
    this.state = {
        id_item_global:0,
        cod_global:"",
        cod_grupal:"",
        item:"",
        id_rubro:-1,
        id_presentacion:-1,
        id_servicio:-1,
        label_id_rubro:"",
        label_id_presentacion:"",
        label_id_servicio:"",
        id_estado:-1,
        data_estado: [],
        data_servicio:[],
        data_rubro:[],
        data_presentacion:[],
        data_producto:[],
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false,


        btn_limpiar_enabled_2 : false,
        btn_grabar_enabled_2 : false,
        data_receta:[],
        id_ficha_tecnica:0,
        cantidad:0,
        data_select_insumo:[],
        id_data_select_insumo:0,
        data_select_formula:[],
        id_data_select_formula:0,
        data_select_tipo:[],
        id_data_select_tipo:0
    }
  }
  return_limpiar = ()=>{
    this.setState ({
        id_item_global:0,
        cod_global:"",
        cod_grupal:"",
        item:"",
        id_rubro:-1,
        id_presentacion:-1,
        id_servicio:-1,
        id_estado:-1,
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    })
  }
  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())

  }
  grabar = ()=>{

    const id_item_global = this.state.id_item_global

    if(!validate_text(this.state.item)){
      alert('Ingrese Nombre de Item')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.cod_global)){
      alert('Ingrese Nombre de Código')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.cod_grupal)){
      alert('Ingrese Nombre de Código Grupal')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_rubro)===-1){
      alert('Seleccione Rubro')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_presentacion)===-1){
      alert('Seleccione Presentación')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_servicio)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    var select
    if(Number(id_item_global)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'item_global',
          field :[{
            name : 'item',
            value : this.state.item,
            type:'string'
          },{
            name : 'cod_global',
            value : this.state.cod_global,
            type:'string'
          },{
            name : 'cod_grupal',
            value : this.state.cod_grupal,
            type:'string'
          },{
            name : 'id_rubro',
            value : this.state.id_rubro,
            type :'integer'
          },{
            name : 'id_presentacion',
            value : this.state.id_presentacion,
            type :'integer'
          },{
            name : 'id_servicio',
            value : this.state.id_servicio,
            type :'integer'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'eliminado',
            value : '0',
            type :'integer'
          }],
          return:['id_item_global']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'item_global',
          field :[{
            name : 'item',
            value : this.state.item,
            type:'string'
          },{
            name : 'cod_global',
            value : this.state.cod_global,
            type:'string'
          },{
            name : 'cod_grupal',
            value : this.state.cod_grupal,
            type:'string'
          },{
            name : 'id_rubro',
            value : this.state.id_rubro,
            type :'integer'
          },{
            name : 'id_presentacion',
            value : this.state.id_presentacion,
            type :'integer'
          },{
            name : 'id_servicio',
            value : this.state.id_servicio,
            type :'integer'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          id_name : "id_item_global",
          id_value : id_item_global,
          id_type : "integer",
          return:['id_item_global']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  //id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, id_estado, correo_electronico
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_item_global, cod_global,cod_grupal, item, i.id_rubro, i.id_servicio, i.id_presentacion,  if(i.id_estado=0,'INACTIVO','ACTIVO') as estado,i.id_estado, i.eliminado, rubro,   servicio,  presentacion FROM item_global i inner join rubro r on r.id_rubro=i.id_rubro inner join servicio s on s.id_servicio=i.id_servicio inner join presentacion p on p.id_presentacion=i.id_presentacion )",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "cod_grupal",
        return:['id_item_global','cod_global','cod_grupal','item','id_rubro','id_servicio','id_presentacion','estado','id_estado','rubro','servicio','presentacion']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_producto : value[1].result
          })
        }else{
          this.setState({
            data_producto : []
          })
        }
      });
    }
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT id_rubro as id, concat(codigo,' - ',rubro) as name from rubro where eliminado=0)",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT id_presentacion as id, concat(codigo,' - ',presentacion) as name from presentacion where eliminado=0)",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT id_servicio as id, concat(codigo,' - ',servicio) as name from servicio where eliminado=0)",
        field :[],
        return:['id','name']
      },{
        table : "(select id_item as id,if(medida is null,item,concat(item,' - ',medida)) as name from item2 i left join medida m on m.id_medida=2)",
        field :[],
        return:['id','name']
      },{
        table : "(select id_produccion_formula as id,cast(concat(codigo,' - ',produccion_formula) as char) as name from produccion_formula where  sw_cabecera=0 and eliminado=0)",
        field :[],
        return:['id','name']
      },{
        table : "((SELECT 0 AS id,'INSUMO' AS name)union(SELECT 1 AS id,'FÓRMULA' AS name))",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"&&value[3].status==="ok"&&value[4].status==="ok"&&value[5].status==="ok"&&value[6].status==="ok"&&value[7].status==="ok"){
          this.setState({
            data_estado : value[1].result,
            data_rubro : value[2].result,
            data_presentacion : value[3].result,
            data_servicio : value[4].result,
            data_select_insumo : value[5].result,
            data_select_formula : value[6].result,
            data_select_tipo : value[7].result
          })
        }
      });
    }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  return_datatxt =(name,value)=>{

    this.setState({
      [name]:value
    } )
  }

  return_data =(name,value,label)=>{

    let codigo = ""
    try{
      var str = label;
      var res = str.split(" - ");
      if(res.length===2){
        codigo = res[0];
      }
    }catch(ex ){}

    this.setState({
      [name]:value,
      ["label_"+name]:codigo
    },()=>{
      this.return_codigo()
    })
  }
  return_codigo = ()=>{
    let codigo = ""
    codigo = this.state.label_id_servicio;
    codigo = codigo+this.state.label_id_rubro;
    codigo = codigo + "000"
    codigo = codigo+this.state.label_id_presentacion;


    let codigo_grupal = ""
    codigo_grupal = codigo_grupal+this.state.label_id_rubro;
    codigo_grupal = codigo_grupal + "000"
    codigo_grupal = codigo_grupal+this.state.label_id_presentacion;


    this.setState({
      cod_global:codigo,
      cod_grupal:codigo_grupal
    })
  }
  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'item_global',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_item_global",
          id_value : data.id_item_global,
          id_type : "integer",
          return:['id_item_global']
        }]
      }]
      console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
          id_item_global:data.id_item_global,
          cod_global:data.cod_global,
          cod_grupal:data.cod_grupal,
          item:data.item,
          id_rubro:data.id_rubro,
          id_presentacion:data.id_presentacion,
          id_servicio:data.id_servicio,
          id_estado:data.id_estado
      },()=>{
        this.return_table_recetas()
      })
    }
  }

  return_table_recetas =()=>{
    const id_item_global = this.state.id_item_global
    if(Number(id_item_global)>0){
      const select = [{
        type: 'consulta', data:
        [{
          table : "( select * from ((select id_ficha_tecnica,i.cod_fabrica as COD,i.item,f.cantidad,m.medida,i.precio_costo as Costo,m.id_medida,f.id_item_secundario  from ficha_tecnica1 f inner join item2 i on i.id_item=f.id_item_secundario inner  join medida m on m.id_medida=f.id_medida where id_item_principal="+id_item_global+")union( select id_ficha_tecnica,i.codigo as COD,produccion_formula,f.cantidad,cast('S/N' as char) as medida,i.costo_ as Costo, cast('S/N' as char)as `T.Medida`,f.id_produccion_formula  from ficha_tecnica1 f  inner join produccion_formula i on i.id_produccion_formula=f.id_produccion_formula  where id_item_principal="+id_item_global+")) a)",
          field :[],
          order_by: "id_ficha_tecnica",
          return:['id_ficha_tecnica','COD','item','cantidad','medida','Costo','id_medida','id_item_secundario']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              data_receta : value[1].result
            })
          }else{
            this.setState({
              data_receta : []
            })
          }
        });
      }
    }else{
      this.setState({
        data_insumo : []
      })
    }
  }

  actualizar_formulas=()=>{
    let select = [{
    type: 'query', data:"update produccion_formula p , item2 i set p.costo_=i.precio_costo where i.id_item =p.id_item"}]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
      })
    }

    select = [{
    type: 'query', data:"update produccion_formula c ,(SELECT cast(sum(cantidad*costo_) as decimal(20,2)) as costo,sw_cabecera FROM produccion_formula where sw_cabecera>0 and eliminado=0 group by sw_cabecera) d set c.costo_=d.costo where c.id_produccion_formula=d.sw_cabecera"}]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
      })
    }


    select = [{
    type: 'query', data:"update  (select i.id_item_global,cast(sum(cantidad*Costo) as decimal(20,2)) as precio_c from ((select id_ficha_tecnica, i.cod_fabrica as COD,i.item,f.cantidad,m.medida,i.precio_costo as Costo,m.id_medida,f.id_item_secundario,id_item_principal  from ficha_tecnica1 f   inner join item2 i on i.id_item=f.id_item_secundario inner join medida m on m.id_medida=f.id_medida   where id_item_principal>0)union( select id_ficha_tecnica,i.codigo as COD,produccion_formula,f.cantidad, cast('S/N' as char) as medida,i.costo_   as Costo, cast('S/N' as char)as `T.Medida`,f.id_produccion_formula,id_item_principal  from ficha_tecnica1 f   inner join produccion_formula i on i.id_produccion_formula=f.id_produccion_formula  where id_item_principal>0)) a   inner join item_global i on i.id_item_global=id_item_principal group by i.id_item_global) p , item_global i set i.precio_costo=p.precio_c where i.id_item_global=p.id_item_global"}]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
      })
    }





  }

  render() {
    const id_item_global = this.state.id_item_global
    //console.log(Number(this.state.id_data_select_tipo))
    return (
      <React.Fragment>
        <div className="formulario white">
          <GetSelect label={"Rubro"} name={"id_rubro"} data={this.state.data_rubro} value={this.state.id_rubro} return_data={this.return_data} />
          <GetSelect label={"Presentación"} name={"id_presentacion"} data={this.state.data_presentacion} value={this.state.id_presentacion} return_data={this.return_data} />
          <GetSelect label={"Servicio"} name={"id_servicio"} data={this.state.data_servicio} value={this.state.id_servicio} return_data={this.return_data} />
          <GetInput label={"Código Global"} name={"cod_global"} value={this.state.cod_global} return_data={this.return_datatxt} />
          <GetInput label={"Código Grupal"} name={"cod_grupal"} value={this.state.cod_grupal} return_data={this.return_datatxt} />
          <GetInput label={"Producto"} name={"item"} value={this.state.item} return_data={this.return_datatxt} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_datatxt} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>

         {(Number(id_item_global)===0)?
           <GetTable name={"tabla_producto"} data={this.state.data_producto}  return_table_edicion={this.return_table_edicion}
            return_table_eliminar={this.return_table_eliminar}  />
          :
            <React.Fragment>
            <div className="formulario white">
              <GetSelect label={"Tipo"} name={"id_data_select_tipo"} data={this.state.data_select_tipo} value={this.state.id_data_select_tipo} return_data={this.return_datatxt} />
              <GetInput label={"Cantidad"} name={"cantidad"} value={this.state.cantidad} return_data={this.return_datatxt} />
              <GetSelect visibled={(Number(this.state.id_data_select_tipo)===0)?false:true} label={"Insumo"} name={"id_data_select_insumo"} data={this.state.data_select_insumo} value={this.state.id_data_select_insumo} return_data={this.return_datatxt} />
              <GetSelect visibled={(Number(this.state.id_data_select_tipo)===0)?true:false} label={"Fórmula"} name={"id_data_select_formula"} data={this.state.data_select_formula} value={this.state.id_data_select_formula} return_data={this.return_datatxt} />
              <GetButton disabled={this.state.btn_limpiar_enabled_2} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar_2}  />
              <GetButton disabled={this.state.btn_grabar_enabled_2}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar_2}/>
            </div>
            <GetTable name={"tabla_receta"} data={this.state.data_receta}  return_table_edicion={this.return_table_edicion_2}
             return_table_eliminar={this.return_table_eliminar_2}  />
           </React.Fragment>
         }
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select()
    this.actualizar_tabla()
    this.actualizar_formulas()
  }

}

export default getProductos;
