import React, { useState,Component } from 'react';
import { StyledOffCanvas, Menu, Overlay } from 'styled-off-canvas'
import { Menu as BurgerIcon } from '@styled-icons/remix-fill/Menu'
import { Close as CloseIcon } from '@styled-icons/remix-fill/Close'
import styled from 'styled-components'
//import logo from './logo.svg';
import './../App.scss';
//import SideBar from './../layout/sidebar/SideBarMenu'
//import MainContent from './../layout/main/MainContent'
import GetLocales from '../componentes/getLocales'
import GetUsuarios from '../componentes/getUsuarios'
import GetClientes from '../componentes/getClientes'
import GetRubro from '../componentes/getRubro'
import GetPresentacion from '../componentes/getPresentacion'
import GetServicio from '../componentes/getServicio'
import GetProductos from '../componentes/getProductos'
import GetRubro2 from '../componentes/getRubro2'
import GetReporte2 from '../componentes/getReporte2'

import GetInsumos from '../componentes/getInsumos'
import GetReporteCentralizado from '../componentes/getReporteCentralizado'
import GetFormulas from '../componentes/getFormulas'
import GetCosteo from '../componentes/getCosteo'
import GetPantalla from '../componentes/getPantalla'
import GetReporte1 from '../componentes/getReporte1'
import GetIndices from '../componentes/getIndices'
import GetCategoria from '../componentes/getCategoria'
import GetFormulario from '../componentes/getFormulario'
import GetNewAuditoria from '../componentes/getNewAuditoria'
import GetNewLogistica from '../componentes/getNewLogistica'
import GetListaAuditoria from '../componentes/getListaAuditoria'
import GetCalendar from '../componentes/getCalendar'
import GetHomologacionItem from '../componentes/getHomologacionItem'
import GetListaLogistica from '../componentes/getListaLogistica'
import GetUltimasVentas from '../componentes/getUltimasVentas'
import GetDashboardSurvey from './getDashboardSurvey'
import GetDashboardSurveyV2 from './getDashboardSurveyv2'
import GetTracking from '../componentes/getTracking'
//import GetReporteLogistica from '../componentes/getReporteLogistica'


import reactLogo from '../images/logow.png'
import userImage from '../images/user.jpg';
import {HorizontalBar} from 'react-chartjs-2';
import GetReporteLogistica from './getReporteLogistica';


const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  display: block;
  margin: 0;
  padding: 0;
`
const A = styled.a`
  border-bottom: 1px solid #efefef;
  color: ${props => props.active ? 'rgba(218, 135, 196)' : '#333'};
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 18px;
  padding: 18px 18px 18px 5px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 200ms ease-in-out;
  &:hover {
    color: rgba(218, 135, 196);
  }
`


class getDashboard extends Component {

  constructor(){
     super();
     this.state = {
       id_modulo:0,
       id_submodulo:0,
       isOpen:false,
        
     }
    // const [isOpen, setIsOpen] = useState(false)
  }
  setIsOpen =(isOpen)=>{
    this.setState({
      isOpen
    })
  }
  getModulo =(id_modulo)=>{

      this.setState({
        id_modulo,
        id_submodulo:0
      })
    //}
  }
  setModulo =(id_modulo)=>{
    if(id_modulo===4){
      localStorage.removeItem("neolav2_uuid_usuario")
      localStorage.removeItem("neolav2_id_tipo_rol")
      localStorage.removeItem("neolav2_tipo_rol")
      localStorage.removeItem("neolav2_nombres")
      localStorage.removeItem("neolav2_sw_venta")
      window.location.reload(false);
    }else{
      this.setState({
        id_modulo
      })
    }
  }
  setSubModulo=(id_submodulo)=>{
    this.setState({
      id_submodulo,
      isOpen:false,
      id_modulo:0
    })
  }
  render() {

    let style =  "toggled"  ;
    style +=  " sidebar-bg"  ;
    /*return (
          <div className={"page-wrapper default-theme bg1 "+ style  }>
            <SideBar getModulo={this.getModulo} firebase={this.props.firebase} />
            <MainContent id_modulo={this.state.id_modulo} firebase={this.props.firebase} />
          </div>
    )*/

/*
<img src={reactLogo} alt="React logo" width="300px" />
<div className="" style={{"textAlign":"center","fontSize": "medium","color": "white","fontWeight": "bolder","width":"100%","alignItems": "center"}}>
    <div className="cssversion_contentCenter">
        <img className="img-responsive img-rounded" style={{"width":"100px"}} src={userImage} alt="User " />
    </div>
    <div className="user-info">
        <span className="user-name">Usuario: {localStorage.getItem("neolav2_nombres")}</span><br/>
        <span className="user-role">Tipo de Perfil: {localStorage.getItem("neolav2_tipo_rol")}</span><br/>
    </div>
</div>
*/
    const data = this.state.data
    let modulo =
    <div className="box" style={{"textAlign":"center","fontSize": "medium","color": "#338d48","fontWeight": "bolder","alignItems": "center"}}>
      <p>Módulo de Centralización v.1.0. </p>

          <HorizontalBar
            width={70}
            height={85}
            data={data} />

    </div>
    if(Number(localStorage.getItem("neolav2_sw_venta"))===0){
        modulo =<div className="box" style={{"textAlign":"center","fontSize": "medium","color": "black","fontWeight": "bolder","alignItems": "center"}}>
          <p>Módulo de Centralización v.1.0. </p>
        </div>
    }

    if(Number(this.state.id_submodulo)===1){
      modulo =<GetUsuarios/>
    }
    if(Number(this.state.id_submodulo)===2){
      modulo =<GetLocales/>
    }
    if(Number(this.state.id_submodulo)===3){
      modulo =<GetClientes/>
    }
    if(Number(this.state.id_submodulo)===4){
      modulo =<GetRubro/>
    }
    if(Number(this.state.id_submodulo)===5){
      modulo =<GetPresentacion/>
    }
    if(Number(this.state.id_submodulo)===6){
      modulo =<GetServicio/>
    }
    if(Number(this.state.id_submodulo)===7){
      modulo =<GetProductos/>
    }
    if(Number(this.state.id_submodulo)===8){
      modulo =<GetRubro2/>
    }
    if(Number(this.state.id_submodulo)===9){
      modulo =<GetInsumos/>
    }
    if(Number(this.state.id_submodulo)===10){
      modulo =<GetFormulas/>
    }
    if(Number(this.state.id_submodulo)===11){
      modulo =<GetCosteo/>
    }
    if(Number(this.state.id_submodulo)===12){
      modulo =<GetPantalla/>
    }
    if(Number(this.state.id_submodulo)===13){
      modulo =<GetReporte1/>
    }
    if(Number(this.state.id_submodulo)===14){
      modulo =<GetIndices/>
    }
    if(Number(this.state.id_submodulo)===15){
      modulo =<GetCategoria/>
    }
    if(Number(this.state.id_submodulo)===16){
      modulo =<GetFormulario/>
    }
    if(Number(this.state.id_submodulo)===17){
      modulo =<GetNewAuditoria setSubModulo={this.setSubModulo}  firebase={this.props.firebase}/>
    }
    if(Number(this.state.id_submodulo)===19){
      modulo =<GetListaAuditoria setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }
    if(Number(this.state.id_submodulo)===20){
      modulo =<GetReporte2 setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }

    if(Number(this.state.id_submodulo)===18){
      modulo =<GetCalendar/>
    }

    if(Number(this.state.id_submodulo)===21){
      modulo =<GetHomologacionItem setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }
    if(Number(this.state.id_submodulo)===24){
      modulo =<GetNewLogistica setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }
    if(Number(this.state.id_submodulo)===25){
      modulo =<GetListaLogistica setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }
    if(Number(this.state.id_submodulo)===26){
      modulo =<GetUltimasVentas setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }
    if(Number(this.state.id_submodulo)===27){
      modulo =<GetReporteLogistica setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }

    if(Number(this.state.id_submodulo)===29){
      modulo =<GetDashboardSurveyV2 setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }

    if(Number(this.state.id_submodulo)===30){
      modulo =<GetTracking setSubModulo={this.setSubModulo} firebase={this.props.firebase}/>
    }

    if(Number(this.state.id_submodulo)===98){
      modulo =<GetReporteCentralizado/>
    }
    const isOpen = this.state.isOpen
    return(
      <StyledOffCanvas
        isOpen={isOpen}
        position ={'left'}
        onClose={() => this.setIsOpen(false)}
      >

      <BurgerIcon
                size={48}
                css={{
                  background: '#fff',
                  borderRadius: '6px',
                  padding: '8px',
                  cursor: 'pointer'
                }}
                onClick={() => { this.setIsOpen((isOpen) => !isOpen) }}
              />

        <Menu>
          <div
           css={{
             padding: '10px 10px 20px 10px',
             textAlign: 'right'
           }}
          >
           <CloseIcon
             onClick={() => this.setIsOpen(false)}
             size={36}
             css={{
               color: '#333',
               cursor: 'pointer',
               textAlign: 'center'
             }}
           />
          </div>
          {(this.state.id_modulo===0)?
          <>
            <Ul>
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4552&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4647)?
              <Li>
                <A href='#' onClick={() => this.setModulo(2)} >Reportes</A>
              </Li>:null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==5&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4552&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4647)?
                <Li>
                <A href='#' onClick={() => this.setModulo(3)}>Auditoría</A>
              </Li>:null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==5&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371)?
                <Li>
                <A href='#' onClick={() => this.setModulo(7)}>Logística</A>
              </Li>:null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==5&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4552&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4647)?
                <React.Fragment>
                  <Li>
                    <A href='#' onClick={() => this.setModulo(1)}>Administrar Usuarios</A>
                  </Li>
                  <Li>
                    <A href='#' onClick={() => this.setModulo(5)}>Administrar Productos</A>
                  </Li>
                  <Li>
                    <A href='#' onClick={() => this.setModulo(6)}>Homologación</A>
                  </Li>
                </React.Fragment>
              :null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4552&&Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4647)?
              <Li>
                <A href='#' onClick={() => this.setModulo(28)} >Encuestas</A>
              </Li>:null}
              <Li>
                <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/salir_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                  <A href='#' onClick={() => this.setModulo(4)}>Salir</A>
                </div>
              </Li>
            </Ul>
          </>
          :null}
          {(this.state.id_modulo===1)?
          <>
            <Ul>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(1)}>Perfiles de Usuario</A>
              </Li>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(2)}>Locales</A>
              </Li>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(3)}>Clientes</A>
              </Li>

              <Li>
                <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                  <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
                </div>
              </Li>
            </Ul>
          </>
          :null}
          {(this.state.id_modulo===2)?
          <>
            <Ul>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(98)}>Ventas por Empresa</A>
              </Li>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(11)}>Costos</A>
              </Li>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(13)}>Reporte por Local / Real</A>
              </Li>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(20)}>Más Reportes</A>
              </Li>
              <Li>
                <A href='#' onClick={() => this.setSubModulo(26)}>Sincronización Ventas</A>
              </Li>
              <Li>

              <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
              </div>
              </Li>
            </Ul>
          </>
          :null}
          {(this.state.id_modulo===3)?
          <>
            <Ul>
            {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371)?
              <Li>
                <A href='#' onClick={() => this.setSubModulo(14)}>Índices</A>
              </Li>:null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371)?
              <Li>
                <A href='#' onClick={() => this.setSubModulo(15)}>Categoría</A>
              </Li>:null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371)?
              <Li>
                <A href='#' onClick={() => this.setSubModulo(16)}>Formulario</A>
              </Li>:null}
              <Li>
                <A href='#' onClick={() => this.setSubModulo(17)}>Nueva Auditoría</A>
              </Li>
              
              <Li>
                <A href='#' onClick={() => this.setSubModulo(19)}>Lista de Auditorías</A>
              </Li>
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371)?
              <Li>
                <A href='#' onClick={() => this.setSubModulo(19)}>Reporte de Auditorías</A>
              </Li>:null}
              {(Number(localStorage.getItem("neolav2_id_tipo_rol"))!==4371)?
              <Li>
                <A href='#' onClick={() => this.setSubModulo(18)}>Calendario</A>
              </Li>:null}
              <Li>
              <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
              </div>
              </Li>
            </Ul>
          </>
          :null}


          {(this.state.id_modulo===5)?
          <>
          <Ul>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(4)}>Rubro de Productos</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(5)}>Presentación</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(6)}>Tipo de Servicio</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(7)}>Productos</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(8)}>Rubro de Insumos</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(9)}>Insumos</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(10)}>Fórmulas</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(11)}>Pantalla Táctil</A>
            </Li>
            <Li>
            <div  style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
              <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
            </div>
            </Li>
          </Ul>
        </>
        :null}
        {(this.state.id_modulo===6)?
        <>
          <Ul>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(21)}>Homologación de Item's</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(22)}>Homologación de Precios</A>
            </Li>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(23)}>Homologación de Pantallas</A>
            </Li>

            <Li>
              <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
              </div>
            </Li>
          </Ul>
        </>
        :null}
        {(this.state.id_modulo===7)?
        <>
          <Ul>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(24)}>Nueva Logística</A>
            </Li>
            {(Number(localStorage.getItem("neolav2_id_tipo_rol"))==4647||Number(localStorage.getItem("neolav2_id_tipo_rol"))==1)?
            <>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(25)}>Lista de Logísticas</A>
            </Li>

            <Li>
              <A href='#' onClick={() => this.setSubModulo(27)}>Reporte de Logísticas</A>
            </Li>
            </>:null}
            <Li>
              <A href='#' onClick={() => this.setSubModulo(30)}>Tracking</A>
            </Li>
              
            <Li>
              <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
              </div>
            </Li>
          </Ul>
        </>
        :null}
        {(this.state.id_modulo===28)?
        <>
          <Ul>
            <Li>
              <A href='#' onClick={() => this.setSubModulo(29)}>Dashboard</A>
            </Li>
             
            <Li>
              <div style={{"width":"100%","height":"100%","backgroundImage":"url(" + require('../images/regresar_.png')  + ")","backgroundPosition":"left","backgroundRepeat":"no-repeat","paddingLeft": "50px"}}>
                <A href='#' onClick={() => this.setModulo(0)}>Regresar</A>
              </div>
            </Li>
          </Ul>
        </>
        :null}
        </Menu>
        <Overlay />
        <div  className="cssversion_contentCenter" style={{"width":"100%","height":"100%","position": "fixed","paddingBottom":"100px"}}>
          <div style={{"width":"95%","height":"100%","overflowY": "auto"}}>
            {modulo}
          </div>
        </div>
      </StyledOffCanvas>
    )


    }
    componentDidMount (){
//console.log("HOSTNAME "+window.location.hostname)
        if(Number(localStorage.getItem("neolav2_sw_venta"))===1){
              const url =  "https://grupomn.net/neola/index.php/webservices/getdataVentasHoyTodos";
             //nsole.log(url)
              fetch( url)
              .then(res => res.json())
              .then((data) => {
                if(data[0].status==="0"){
                  this.setState({ data :[]})
                }else{
                  console.log(data[0])
                  this.setState({ data:data[0].grafico[0] })
                }
              })
              .catch(console.log)
      }

      /*
      <Li>
        <A href='#' onClick={() => this.setSubModulo(0)}>Catálogos</A>
      </Li>
      <Li>
        <A href='#' onClick={() => this.setSubModulo(0)}>Usuarios</A>
      </Li>
      <Li>
        <A href='#' onClick={() => this.setSubModulo(0)}>Administrador de Tareas</A>
      </Li>*/
    }
  }
  export default getDashboard;
