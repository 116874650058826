import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
//ID CATALOGO  =20
class getFormulario extends Component {
  constructor(){
    super();
    this.state = {
        id_formulario:0,
        formulario:'',
        id_estado:-1,
        data_estado:[],
        btn_limpiar_enabled:false,
        btn_grabar_enabled:false,
        data_formulario:[],
        id_indice:-1,
        data_indice:[],
        id_categoria:-1,
        data_categoria:[],
        id_subcategoria:0,
        data_subcategoria:[],
        orden:0,
        id_calificacion:-1,
        data_calificacion:[],
        id_estado_detalle:0,
        data_estado_detalle:[],
        sw_imagenes:-1,
        data_imagen:[],
        id_tipo_local:-1,
        data_tipo_local:[]

    }
  }
  //id_catalogo, catalogo, tipo, sw_padre, activo, id_estado, eliminado
  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'formulario',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_formulario",
          id_value : data.id_formulario,
          id_type : "integer",
          return:['id_formulario']
        }]
      }]
      //console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }

  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())
  }
  return_grabar_detalle =()=>{
    this.setState({
      btn_grabar_enabled_detalle:true
    },()=>this.grabar_detalle())
  }

  grabar = ()=>{
    const id_formulario = this.state.id_formulario
    if(!validate_text(this.state.formulario)){
      alert('Ingrese Nombre de Formulario')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }


    var select
    if(Number(id_formulario)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'formulario',
          field :[{
            name : 'formulario',
            value : this.state.formulario,
            type:'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          return:['id_formulario']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'formulario',
          field :[{
            name : 'formulario',
            value : this.state.formulario,
            type:'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          id_name : "id_formulario",
          id_value : id_formulario,
          id_type : "integer",
          return:['id_formulario']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }


  grabar_detalle = ()=>{
    const id_formulario = this.state.id_formulario
    const id_indice = this.state.id_indice
    const id_categoria = this.state.id_categoria
    const id_subcategoria = this.state.id_subcategoria
    const id_tipo_local = this.state.id_tipo_local
    const orden = this.state.orden
    const id_calificacion = this.state.id_calificacion
    const sw_imagenes =this.state.sw_imagenes
    const id_estado =this.state.id_estado_detalle
    if(!validate_text(this.state.formulario)){
      alert('Ingrese Nombre de Formulario')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }


//id_formulario_detalle, id_formulario, id_indice, id_categoria,
// id_subcategoria, sw_tipo_locales, orden, calificacion, sw_imagenes, eliminado, id_estado, id_tipo_local
    var select

      select = [{
        type: 'insertar', data:
        [{
          table : 'formulario_detalle',
          field :[{
            name : 'id_formulario',
            value : this.state.id_formulario,
            type:'string'
          },{
            name : 'id_indice',
            value : this.state.id_indice,
            type :'integer'
          },{
            name : 'id_categoria',
            value : this.state.id_categoria,
            type :'integer'
          },{
            name : 'id_subcategoria',
            value : this.state.id_subcategoria,
            type :'integer'
          },{
            name : 'id_tipo_local',
            value : this.state.id_tipo_local,
            type :'integer'
          },{
            name : 'orden',
            value : this.state.orden,
            type :'integer'
          },{
            name : 'calificacion',
            value : this.state.id_calificacion,
            type :'integer'
          },{
            name : 'sw_imagenes',
            value : this.state.sw_imagenes,
            type :'integer'
          },{
            name : 'id_estado',
            value : this.state.id_estado_detalle,
            type :'integer'
          }],
          return:['id_formulario']
        }]
      }]

    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar_2()
        }
      })
    }
  }

  return_limpiar_2 = ()=>{
    this.setState ({
      btn_grabar_enabled_detalle:false 
    })
  }

  return_limpiar = ()=>{
    this.setState ({
      id_formulario:0,
      formulario:'',
      id_estado:-1,
      btn_limpiar_enabled:false,
      btn_grabar_enabled:false,
      id_indice:0
    })
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
        id_formulario:data.id_formulario,
        formulario:data.formulario,
        id_estado:data.id_estado,
        })
    }
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }

  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT id_auditoria_indice as id, indice as name from auditoria_indice where eliminado=0)",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT id_catalogo as id, catalogo as name from catalogo where tipo=20 and activo=1 and eliminado=0)",
        field :[],
        return:['id','name']
      },{
        table : "((SELECT 0 AS id,'0' AS name)union(SELECT 1 AS id,'1' AS name)union(SELECT 2 AS id,'2' AS name)union(SELECT 3 AS id,'3' AS name)union(SELECT 4 AS id,'4' AS name)union(SELECT 5 AS id,'5' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "((SELECT 0 AS id,'SI' AS name)union(SELECT 1 AS id,'NO' AS name))",
        field :[],
        return:['id','name']
      },{
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '3',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"&&value[3].status==="ok"&&value[4].status==="ok"&&value[5].status==="ok"&&value[6].status==="ok"){
          this.setState({
            data_estado : value[1].result,
            data_indice : value[2].result,
            data_categoria : value[3].result,
            data_subcategoria : value[3].result,
            data_calificacion: value[4].result,
            data_imagen: value[5].result,
            data_estado_detalle : value[1].result,
            data_tipo_local: value[6].result,
          })
        }
      });
    }
  }
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "formulario",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "formulario",
        return:['id_formulario','formulario','id_estado']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_formulario : value[1].result
          })
        }else{
          this.setState({
            data_formulario : []
          })
        }
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Formulario"} name={"formulario"} value={this.state.formulario} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_formulario"} data={this.state.data_formulario}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
         <div className="formulario white">
           <GetSelect label={"Indice"} name={"id_indice"} data={this.state.data_indice} value={this.state.id_indice} return_data={this.return_data} />
           <GetSelect label={"Categoría"} name={"id_categoria"} data={this.state.data_categoria} value={this.state.id_categoria} return_data={this.return_data} />
           <GetSelect label={"Sub-Categoría"} name={"id_subcategoria"} data={this.state.data_subcategoria} value={this.state.id_subcategoria} return_data={this.return_data} />
           <GetInput label={"Orden"} name={"orden"} value={this.state.orden} return_data={this.return_data} />
           <GetSelect label={"Calificación"} name={"id_calificacion"} data={this.state.data_calificacion} value={this.state.id_calificacion} return_data={this.return_data} />
           <GetSelect label={"Imagen"} name={"sw_imagenes"} data={this.state.data_imagen} value={this.state.sw_imagenes} return_data={this.return_data} />
           <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado_detalle} value={this.state.id_estado_detalle} return_data={this.return_data} />
           <GetSelect label={"Tipo Local"} name={"id_tipo_local"} data={this.state.data_tipo_local} value={this.state.id_tipo_local} return_data={this.return_data} />

           <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
           <GetButton disabled={this.state.btn_grabar_enabled_detalle}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar_detalle}  />
         </div>
       </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getFormulario;
