import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Selectbd from './../bd/getDb';
class GetReporteLogistica extends Component {
  state = {
    empresas:[],
    id_usuario_globales:0,
    id_reporte :4,
    date_desde : new Date(),
    date_hasta : new Date()
  }

  onChangeDesde = date => this.setState({ date_desde:date })

  onChangeHasta = date => this.setState({ date_hasta:date })

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }

  setReporteExportar =()=>{
    const fecha_inicio = this.formatDate(this.state.date_desde)
    const fecha_fin = this.formatDate(this.state.date_hasta)
    //const id_reporte= Number(this.state.id_reporte)
    const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarExcelVenta7/"+fecha_inicio+"/"+fecha_fin;
    console.log(url)
    window.open(url, "_self")
    
    /*if(id_reporte===4){
      const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarExcelVenta6/"+fecha_inicio+"/"+fecha_fin;
      console.log(url)
      window.open(url, "_self")
    }else{
      if(id_reporte===1){
        const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarExcelVenta2/"+fecha_inicio+"/"+fecha_fin;
        console.log(url)
        window.open(url, "_self")
      }else{
        if(id_reporte===2){
            const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarExcelVenta4/"+fecha_inicio+"/"+fecha_fin;
            console.log(url)
            window.open(url, "_self")
          }else{
            if(id_reporte===3){
                const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarExcelVenta5/"+fecha_inicio+"/"+fecha_fin;
                console.log(url)
                window.open(url, "_self")
              }else{
                const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarExcelVenta3/"+fecha_inicio+"/"+fecha_fin;
                console.log(url)
                window.open(url, "_self")
            }
        }
      }
    }*/
  }

  render(){



      return (
        <div className="" style={{ "backgroundColor": "white","width": "100%"}}>
          <div className="lbl_global" style={{ "backgroundColor": "white"}}>
            <div className="lbl_uno"  style={{ "backgroundColor": "white"}}>
                  <React.Fragment>
                    <br/>
                    <label>Desde</label><br/>
                    <DatePicker dateFormat="dd/MMM/yyyy" selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
                    <br/><label>Hasta</label><br/>
                    <DatePicker dateFormat="dd/MMM/yyyy" selected={this.state.date_hasta} onChange={this.onChangeHasta} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /><br/>
                    <button className="button-global" style={{"width":"250px"}} onClick={ () => this.setReporteExportar()}>Exportar</button>
                  </React.Fragment>
            </div>
            <div className="lbl_tres" style={{ "backgroundColor": "white"}}></div>
          </div>
        </div>
      )
  }

  handleChangeE2(event) {
    //let id_usuario= event.target.value;
    //let anio= this.state.anio;
    this.setState({
      id_usuario_globales: event.target.value
    });
  }
  handleChangeR(event) {
    this.setState({
      id_reporte: event.target.value,
    });
  }


  formatDate=(date)=> {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year,month, day].join('-');
   }

   mostrarEmpresas = () => {
    const clientes = this.state.empresas;
    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_usuario_globales} onChange={(e) => {this.handleChangeE2(e)}}>
          <option value="0">Seleccione Empresa</option>
          {(clientes.length !== 0) ?
            clientes.map(cliente => (
              <option key={cliente.id_usuario_globales} value={cliente.id_usuario_globales}>{cliente.etiqueta}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }

  componentDidMount (){
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_usuario_globales,etiqueta FROM usuario_globales  where activo=1 )",
        field :[],
        order_by: "etiqueta",
        return:['id_usuario_globales','etiqueta']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            empresas : value[1].result
          })
        }else{
          this.setState({
            empresas : []
          })
        }
      });
    }
  }
}

export default GetReporteLogistica;
