import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getServicio extends Component {

  constructor(){/**********/
    super();
    this.state = {
        id_servicio:0,
        servicio:"",
        codigo:"",
        id_estado:-1,
        data_estado: [],
        data_servicio:[],
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    }
  }
  return_limpiar = ()=>{
    this.setState ({
        id_servicio:0,
        servicio:"",
        codigo:"",
        id_estado:-1,
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    })
  }
  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())

  }
  grabar = ()=>{

    const id_servicio = this.state.id_servicio

    if(!validate_text(this.state.servicio)){
      alert('Ingrese Servicio')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    var select
    if(Number(id_servicio)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'servicio',
          field :[{
            name : 'servicio',
            value : this.state.servicio,
            type:'string'
          },{
            name : 'codigo',
            value : this.state.codigo,
            type:'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'eliminado',
            value : '0',
            type :'integer'
          }],
          return:['id_servicio']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'servicio',
          field :[{
            name : 'servicio',
            value : this.state.servicio,
            type:'string'
          },{
            name : 'codigo',
            value : this.state.codigo,
            type:'string'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          id_name : "id_servicio",
          id_value : id_servicio,
          id_type : "integer",
          return:['id_servicio']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  //id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, id_estado, correo_electronico
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_servicio, servicio, id_estado,codigo,  if(id_estado=0,'INACTIVO','ACTIVO') as estado,eliminado FROM servicio )",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "servicio",
        return:['id_servicio','servicio','id_estado','codigo','estado']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_servicio : value[1].result
          })
        }else{
          this.setState({
            data_servicio : []
          })
        }
      });
    }
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_estado : value[1].result
          })
        }
      });
    }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'servicio',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_servicio",
          id_value : data.id_servicio,
          id_type : "integer",
          return:['id_servicio']
        }]
      }]
      console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
          id_servicio:data.id_servicio,
          servicio:data.servicio,
          id_estado:data.id_estado,
          codigo:data.codigo
      })
    }
  }
  render() {

    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Servicio"} name={"servicio"} value={this.state.servicio} return_data={this.return_data} />
          <GetInput label={"Código"} name={"codigo"} value={this.state.codigo} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_servicio"} data={this.state.data_servicio}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getServicio;
