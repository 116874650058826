import React, { Component } from 'react';
import Selectbd from './../bd/getDb';
import GetTable from './../util/getTable';
import DatePicker from "react-datepicker";
import GetButton from './../util/getButton';
import GetInput from './../util/getInput';
import "react-datepicker/dist/react-datepicker.css";
class getReporte1 extends Component {

  constructor(){/**********/
    super();
    this.state = {
        date_desde : new Date(),
        date_hasta : new Date(),
        url_global:"https://grupomn.net/neola/index.php/webservices/",
        data_productos:[],
        rubros:[],
        empresas:[],
        id_usuario_globales:0,
        id_rubro:0,
        busqueda:''
    }
  }

  onChangeDesde = date => this.setState({ date_desde:date })

  onChangeHasta = date => this.setState({ date_hasta:date })

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }
  actualizar_tablaC =()=>{
    if(Number(this.state.id_usuario_globales)<=0)return
    const desde = this.formatDate(this.state.date_desde)
    const hasta = this.formatDate(this.state.date_hasta)
    let sql =""
    const id_rubro = this.state.id_rubro
    if(Number(id_rubro)>0){
      sql=" and i.id_rubro="+id_rubro
    }
    //cast(sum(cantidad*i.precio_costo) as decimal(20,2)) as Costo_Total
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT rubro,replace(replace(replace(i.item,' PPL',''),' DOMI',''),' PLL','') as item,sum(cantidad) as Cantidad,cast(a.precio_venta as decimal(20,2)) as Precio_Uni,cast(sum(cantidad)*a.precio_venta as decimal(20,2)) as Total_Venta,cast(i.precio_costo as decimal(20,2)) as Costo_Uni,cast(sum(cantidad)*i.precio_costo as decimal(20,2)) as Total_Costo,concat(cast(cast(if(i.precio_costo>0 and a.precio_venta>0,i.precio_costo/a.precio_venta,0)*100 as decimal(20,2)) as char),'%') as Porcentaje_Costo FROM (SELECT * FROM items  where id_usuario_globables="+Number(this.state.id_usuario_globales)+") a inner join item_global i on i.cod_global=a.cod_global inner join rubro r on r.id_rubro=i.id_rubro "+sql+" inner join detalle_ventas d on d.id_item=a.id_item_sucursal and id_usuario_globales="+Number(this.state.id_usuario_globales)+" and fecha>='"+desde+" 00:00:00' and fecha<='"+hasta+" 23:59:59' group by cod_grupal order by i.item )",
        field :[],
        order_by: "item",
        return:['rubro','item','Cantidad']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_productos : value[1].result
          })
        }else{
          this.setState({
            data_productos : []
          })
        }
      });
    }
  }
  actualizar_tablaC1 =()=>{
    if(Number(this.state.id_usuario_globales)<=0)return
    const desde = this.formatDate(this.state.date_desde)
    const hasta = this.formatDate(this.state.date_hasta)
    let sql =""
    const id_rubro = this.state.id_rubro
    if(Number(id_rubro)>0){
      sql=" and i.id_rubro="+id_rubro
    }
    //cast(sum(cantidad*i.precio_costo) as decimal(20,2)) as Costo_Total
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT rubro,replace(replace(replace(replace(replace(replace(replace(i.item,' PPL',''),' DOMI',''),' PLL',''),' ARROZ',''),' MADUROS',''),' YUCA',''),' PATACONES','') as item,sum(cantidad) as Cantidad,cast(a.precio_venta as decimal(20,2)) as Precio_Uni,cast(sum(cantidad)*a.precio_venta as decimal(20,2)) as Total_Venta,cast(i.precio_costo as decimal(20,2)) as Costo_Uni,cast(sum(cantidad)*i.precio_costo as decimal(20,2)) as Total_Costo,concat(cast(cast(if(i.precio_costo>0 and a.precio_venta>0,i.precio_costo/a.precio_venta,0)*100 as decimal(20,2)) as char),'%') as Porcentaje_Costo FROM (SELECT * FROM items  where id_usuario_globables="+Number(this.state.id_usuario_globales)+") a inner join item_global i on i.cod_global=a.cod_global inner join rubro r on r.id_rubro=i.id_rubro "+sql+" inner join detalle_ventas d on d.id_item=a.id_item_sucursal and id_usuario_globales="+Number(this.state.id_usuario_globales)+" and fecha>='"+desde+" 00:00:00' and fecha<='"+hasta+" 23:59:59' group by replace(replace(replace(replace(replace(replace(replace(i.item,' PPL',''),' DOMI',''),' PLL',''),' ARROZ',''),' MADUROS',''),' YUCA',''),' PATACONES','') order by i.item )",
        field :[],
        order_by: "item",
        return:['rubro','item','Cantidad']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_productos : value[1].result
          })
        }else{
          this.setState({
            data_productos : []
          })
        }
      });
    }
  }

  actualizar_tabla =()=>{
    if(Number(this.state.id_usuario_globales)<=0)return
    const desde = this.formatDate(this.state.date_desde)
    const hasta = this.formatDate(this.state.date_hasta)
    let sql =""
    const id_rubro = this.state.id_rubro
    if(Number(id_rubro)>0){
      sql=" and i.id_rubro="+id_rubro
    }
    //cast(sum(cantidad*i.precio_costo) as decimal(20,2)) as Costo_Total
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT rubro,i.item,sum(cantidad) as Cantidad,cast(a.precio_venta as decimal(20,2)) as Precio_Uni,cast(sum(cantidad)*a.precio_venta as decimal(20,2)) as Total_Venta,cast(i.precio_costo as decimal(20,2)) as Costo_Uni,cast(sum(cantidad)*i.precio_costo as decimal(20,2)) as Total_Costo,concat(cast(cast(if(i.precio_costo>0 and a.precio_venta>0,i.precio_costo/a.precio_venta,0)*100 as decimal(20,2)) as char),'%') as Porcentaje_Costo FROM (SELECT * FROM items  where id_usuario_globables="+Number(this.state.id_usuario_globales)+") a inner join item_global i on i.cod_global=a.cod_global inner join rubro r on r.id_rubro=i.id_rubro "+sql+" inner join detalle_ventas d on d.id_item=a.id_item_sucursal and id_usuario_globales="+Number(this.state.id_usuario_globales)+" and fecha>='"+desde+" 00:00:00' and fecha<='"+hasta+" 23:59:59' group by d.id_item order by i.item )",
        field :[],
        order_by: "item",
        return:['rubro','item','Cantidad','Precio_Uni','Total_Venta','Costo_Uni','Total_Costo','Porcentaje_Costo']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_productos : value[1].result
          })
        }else{
          this.setState({
            data_productos : []
          })
        }
      });
    }
  }

   return_table_edicion =()=>{

   }
   return_table_eliminar =()=>{

   }

   formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  render() {
    //rubro','item','Cantidad','Precio_Uni','Total_Venta','Costo_Uni','Total_Costo','Porcentaje_Costo
    const data_productos = this.state.data_productos
    let busqueda = this.state.busqueda
    busqueda = (busqueda+"").toUpperCase()
    let array_productos =[]
    //console.log(busqueda)
    data_productos.forEach(cpDTC => {
     if((cpDTC.item+"").toUpperCase().includes(busqueda)){
         array_productos= [ ...array_productos, {
            'rubro': cpDTC.rubro,
            'item': cpDTC.item,
            'Cantidad': cpDTC.Cantidad,
            'Precio_Uni': cpDTC.Precio_Uni,
            'Total_Venta': cpDTC.Total_Venta,
            'Costo_Uni': cpDTC.Costo_Uni,
            'Total_Costo': cpDTC.Total_Costo,
            'Porcentaje_Costo': cpDTC.Porcentaje_Costo,
         }]
     }
   })
  //  const busqueda = this.state.busqueda

    return (
      <React.Fragment>
      Empresa:<br/>
      {this.mostrarEmpresas()}<br/>
      <label>Desde</label><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
      <label>Hasta</label><DatePicker selected={this.state.date_hasta} onChange={this.onChangeHasta} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /><br/>
      {this.mostrarLineas()}
      <GetButton name={"Buscar"} value={"Buscar"} return_accion={this.actualizar_tabla}  /> {'    '}
      <GetButton name={"BuscarC"} value={"Búsqueda Consolidada con Acompañante"} return_accion={this.actualizar_tablaC}  />{'    '}
      <GetButton name={"BuscarC"} value={"Búsqueda Consolidada"} return_accion={this.actualizar_tablaC1}  />{'    '}
      <GetButton name={"Excel"} value={"Excel"} return_accion={this.exportar_tabla}  />
      <div className="formulario white">
        <GetInput label={"Búsqueda"} name={"busqueda"} value={this.state.busqueda} return_data={this.return_data} />
      </div>
      <br/>
      <GetTable name={"tabla_producto"} data={array_productos}  return_table_edicion={this.return_table_edicion}
       return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>

    )
  }
  mostrarLineas = () => {
    const rubros = this.state.rubros;

    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_rubro} onChange={(e) => {this.handleChangeE3(e)}}>
          <option value="0">Seleccione Rubro</option>
          {(rubros.length !== 0) ?
            rubros.map(rubro => (
              <option key={rubro.id_rubro} value={rubro.id_rubro}>{rubro.rubro}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }
  mostrarEmpresas = () => {
    const clientes = this.state.empresas;
    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_usuario_globales} onChange={(e) => {this.handleChangeE2(e)}}>
          <option value="0">Seleccione Empresa</option>
          {(clientes.length !== 0) ?
            clientes.map(cliente => (
              <option key={cliente.id_usuario_globales} value={cliente.id_usuario_globales}>{cliente.etiqueta}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }


  handleChangeE2(event) {
    //let id_usuario= event.target.value;
    //let anio= this.state.anio;
    this.setState({
      id_usuario_globales: event.target.value
    });
  }

  handleChangeE3(event) {
    let id_rubro= event.target.value;
    //let anio= this.state.anio;
    this.setState({
      id_rubro: event.target.value
    });
  }
  componentDidMount (){
    //
  /*  const url=  this.state.url_global+"getEmpresaAPP";
      fetch( url)
      .then(res => res.json())
      .then((data) => {
        this.setState({ empresas:data })
      })
      .catch(console.log)*/




      const select = [{
        type: 'consulta', data:
        [{
          table : "(SELECT id_usuario_globales,etiqueta FROM usuario_globales  where activo=1 )",
          field :[],
          order_by: "etiqueta",
          return:['id_usuario_globales','etiqueta']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              empresas : value[1].result
            })
          }else{
            this.setState({
              empresas : []
            })
          }
        });
      }

      const select1 = [{
        type: 'consulta', data:
        [{
          table : "(SELECT id_rubro,rubro FROM rubro )",
          field :[],
          order_by: "rubro",
          return:['id_rubro','rubro']
        }]
      }]
      var result = Selectbd(select1)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.setState({
              rubros : value[1].result
            })
          }else{
            this.setState({
              rubros : []
            })
          }
        });
      }
  }

}

export default getReporte1;
