import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
class getInsumos extends Component {
//id_item_global, cod_global, item, id_rubro, id_servicio, cod_grupal, id_presentacion, id_estado, eliminado
  constructor(){/**********/
    super();
    this.state = {
        id_item:0,
        cod_fabrica:"",
        item:"",
        precio_costo:"",
        id_rubro:-1,
        id_estado:-1,
        data_estado: [],
        data_rubro:[],
        data_insumo:[],
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    }
  }
  return_limpiar = ()=>{
    this.setState ({
        id_item:0,
        cod_fabrica:"",
        item:"",
        precio_costo:"",
        id_rubro:-1,
        id_estado:-1,
        btn_limpiar_enabled : false,
        btn_grabar_enabled : false
    })
  }
  return_grabar =()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.grabar())

  }
  grabar = ()=>{

    const id_item = this.state.id_item

    if(!validate_text(this.state.item)){
      alert('Ingrese Nombre de Item')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(!validate_text(this.state.cod_fabrica)){
      alert('Ingrese Nombre de Código')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }

    if(Number(this.state.id_estado)===-1){
      alert('Seleccione Estado')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }
    if(Number(this.state.id_rubro)===-1){
      alert('Seleccione Rubro')
      this.setState({
        btn_grabar_enabled:false
      })
      return null
    }


    var select
    if(Number(id_item)===0){
      select = [{
        type: 'insertar', data:
        [{
          table : 'item2',
          field :[{
            name : 'item',
            value : this.state.item,
            type:'string'
          },{
            name : 'cod_fabrica',
            value : this.state.cod_fabrica,
            type:'string'
          },{
            name : 'precio_costo',
            value : this.state.precio_costo,
            type:'string'
          },{
            name : 'id_rubro',
            value : this.state.id_rubro,
            type :'integer'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          },{
            name : 'eliminado',
            value : '0',
            type :'integer'
          }],
          return:['id_item']
        }]
      }]
    }else{
      select = [{
        type: 'actualizar', data:
        [{
          table : 'item2',
          field :[{
            name : 'item',
            value : this.state.item,
            type:'string'
          },{
            name : 'cod_fabrica',
            value : this.state.cod_fabrica,
            type:'string'
          },{
            name : 'precio_costo',
            value : this.state.precio_costo,
            type:'string'
          },{
            name : 'id_rubro',
            value : this.state.id_rubro,
            type :'integer'
          },{
            name : 'id_estado',
            value : this.state.id_estado,
            type :'integer'
          }],
          id_name : "id_item",
          id_value : id_item,
          id_type : "integer",
          return:['id_item']
        }]
      }]
    }
    var result = Selectbd(select)
    //console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.actualizar_tabla()
          this.return_limpiar()
        }
      })
    }
  }
  //id_usuario, usuario, clave, id_tipo_rol, apellidos, nombres, id_estado, correo_electronico
  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT id_item, cod_fabrica,item, i.id_rubro,precio_costo, if(i.id_estado=0,'INACTIVO','ACTIVO') as estado,i.id_estado, i.eliminado, rubro  FROM item2 i inner join rubro2 r on r.id_rubro=i.id_rubro )",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "item",
        return:['id_item','cod_fabrica','item','id_rubro','precio_costo','estado','id_estado','eliminado','rubro']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            data_insumo : value[1].result
          })
        }else{
          this.setState({
            data_insumo : []
          })
        }
      });
    }
  }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "((SELECT 0 AS id,'INACTIVO' AS name)union(SELECT 1 AS id,'ACTIVO' AS name))",
        field :[],
        return:['id','name']
      },{
        table : "(SELECT id_rubro as id, rubro as name from rubro2 where eliminado=0)",
        field :[],
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"){
          this.setState({
            data_estado : value[1].result,
            data_rubro : value[2].result
          })
        }
      });
    }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  return_data =(name,value)=>{

    this.setState({
      [name]:value
    } )
  }


  return_table_eliminar =(data)=>{
    if(data){
      var select = [{
        type: 'actualizar', data:
        [{
          table : 'item2',
          field :[{
            name : 'eliminado',
            value : '1',
            type:'integer'
          }],
          id_name : "id_item",
          id_value : data.id_item,
          id_type : "integer",
          return:['id_item']
        }]
      }]
      console.log(select)
      var result = Selectbd(select)
      //console.log(result)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
            this.actualizar_tabla()
            this.return_limpiar()
          }
        })
      }
    }
  }
  return_table_edicion =(data)=>{
    if(data){
      this.setState ({
          id_item:data.id_item,
          cod_fabrica:data.cod_fabrica,
          item:data.item,
          precio_costo:data.precio_costo,
          id_rubro:data.id_rubro,
          id_estado:data.id_estado
      })
    }
  }
  render() {

    return (
      <React.Fragment>
        <div className="formulario white">
          <GetSelect label={"Rubro"} name={"id_rubro"} data={this.state.data_rubro} value={this.state.id_rubro} return_data={this.return_data} />
          <GetInput label={"Código"} name={"cod_fabrica"} value={this.state.cod_fabrica} return_data={this.return_data} />
          <GetInput label={"Insumo"} name={"item"} value={this.state.item} return_data={this.return_data} />
          <GetInput label={"Precio Costo"} name={"precio_costo"} value={this.state.precio_costo} return_data={this.return_data} />
          <GetSelect label={"Estado"} name={"id_estado"} data={this.state.data_estado} value={this.state.id_estado} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"limpiar"} value={"Limpiar"} return_accion={this.return_limpiar}  />
          <GetButton disabled={this.state.btn_grabar_enabled}  name={"grabar"} value={"Grabar"} return_accion={this.return_grabar}  />
        </div>
        <GetTable name={"tabla_insumo"} data={this.state.data_insumo}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_select();
    this.actualizar_tabla()
  }

}

export default getInsumos;
