import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import GetFormularioNewLogistica from './getFormularioNewLogistica';
import DatePicker from "react-datepicker";
import Moment from 'moment';
//ID CATALOGO  =20
class getNewLogistica extends Component {
  constructor(props){
    super(props);
    this.state = {
        id_formulario_cabecera:0,
        id_formulario:0,
        data_formulario:[],
        id_local:0,
        data_local:[],
        btn_limpiar_enabled:false,
        data_detalle:[],
        date_desde : new Date(),
        date_hasta : new Date()
    }
  }
  onChangeDesde = date => this.setState({ date_desde:date })

  onChangeHasta = date => this.setState({ date_hasta:date })

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }
  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }
 actualizar_tabla =()=>{
   const id_formulario = Number(this.state.id_formulario)
   const select = [{
    type: 'consulta', data:
    [{
      table : "(SELECT f.id_formulario_detalle,c.catalogo,cast(cs.catalogo as char) as subcategoria,i.indice,calificacion,sw_imagenes,txt_id_etiquetas,f.orden FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria AND id_formulario="+id_formulario+" inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice left join catalogo cs on cs.id_catalogo=f.id_subcategoria)",
      field :[],
      order_by: "orden,id_formulario_detalle",
      return:['id_formulario_detalle', 'catalogo', 'subcategoria', 'indice', 'calificacion', 'sw_imagenes','txt_id_etiquetas']
    }]
  }]
  var result = Selectbd(select)
  if(result){
    result.then((value) => {
      if(value[1].status==="ok"){
        this.setState({
          data_detalle : value[1].result
        })
      }else{
        this.setState({
          data_detalle : []
        })
      }
    });
  }
 }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "formulario",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        },{
            name : 'tipo',
            value : '1',
            type:'integer'
          }],
        order_by: "formulario",
        return:['id_formulario as id','formulario as name']
      },{
        table : '`catalogo`',
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        },{
          name : 'id_estado',
          value :'1',
          type:'integer'
        },{
            name : 'tipo',
            value :'22',
            type:'integer'
          }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"){
          this.setState({
            data_formulario: value[1].result,
            data_local: value[2].result
          })
        }
      });
    }
  }
  return_limpiar = ()=>{

    const id_local = Number(this.state.id_local)
    const id_formulario = Number(this.state.id_formulario)
    let fecha_emision = this.state.date_desde
    if(id_formulario===0){
      alert("Seleccione Formulario")
      return null
    }
    if(id_local===0){
      alert("Seleccione Placa")
      return null
    }

    var sw_existencia = 0
    const select_verificar_existencia = [{
     type: 'consulta', data:
     [{
       table : "(SELECT * FROM formulario_cabecera WHERE id_local="+id_local+" AND id_formulario="+id_formulario+" AND id_usuario="+Number(localStorage.getItem("neolav2_uuid_usuario"))+" AND MID(fecha_emision,1,10)='"+this.formatDate(fecha_emision)+"')",
       field :[],
       return:['id_formulario_cabecera']
     }]
   }]
   //console.log("--> 1")
   var result_verificar_existencia = Selectbd(select_verificar_existencia)
   if(result_verificar_existencia){
     result_verificar_existencia.then((value) => {
       if(value[1].status==="ok"){
           sw_existencia=1
          //  console.log("--> 2")
           this.setState ({
             btn_limpiar_enabled:false,id_formulario_cabecera : value[1].result[0].id_formulario_cabecera
           },()=>{
             this.actualizar_tabla()
           })
           this.get_loading(false)
       }
       if(sw_existencia===0){

          var select = [{
            type: 'insertar', data:
            [{
              table : 'formulario_cabecera',
              field :[{
                name : 'id_formulario',
                value : id_formulario,
                type:'integer'
              },{
                name : 'id_local',
                value : id_local,
                type :'integer'
              },{
                name : 'id_usuario',
                value : Number(localStorage.getItem("neolav2_uuid_usuario")),
                type :'integer'
              },{
                name : 'id_estado',
                value : 0,
                type :'integer'
              },{
                name : 'fecha_creacion',
                value : this.formatDate(new Date()),
                type :'string'
              },{
                name : 'fecha_emision',
                value : Moment(fecha_emision).format('YYYY-MM-DD HH:mm:ss') ,
                type :'string'
              }],
              return:['id_formulario_cabecera']
            }]
          }]
          var result = Selectbd(select)

          if(result){
              result.then((value) => {
                console.log(value)
                  this.setState ({
                    btn_limpiar_enabled:false,id_formulario_cabecera : value[1].result[0].id_formulario_cabecera
                  },()=>{
                    this.actualizar_tabla()
                  })
                  this.get_loading(false)
              })
          }
        }


     });
   }
this.get_loading(true)



  }
  return_limpiarT =()=>{
    this.setState ({
      id_formulario_cabecera:0,
      data_detalle:[]
    })
  }
  formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }
  get_loading =(msg_loading)=>{
    this.setState({
      msg_loading
    })
  }
  render() {
    let modal_loading
    const msg_loading = this.state.msg_loading
    if(msg_loading){
      modal_loading = <div id="loaderf" className="loaderf loaderf-default is-active" data-text=""></div>
    }
    return (
      <React.Fragment>
        <div id="loader_msg" className="loader_msg loader_msg-default is-active" data-text="">
            <div className="cssversion_pw100 cssversion_ph100 cssversion_menu cssversion_colorblanco">
            <table className="table_v2" style={{"width":"100%"}}>
            <thead>
             <tr><th colSpan="2" style={{"fontSize":"18px"}}>Nueva Logística</th></tr>
            </thead>
            <tbody>
             <tr><td style={{"fontSize":"18px"}}>Usuario:</td><td style={{"fontSize":"18px"}}>{localStorage.getItem("neolav2_nombres")}</td></tr>
             <tr><td style={{"fontSize":"18px"}}>Transportista:</td><td><GetSelect style={"select-css"} width={"100%"} label={""} name={"id_local"} data={this.state.data_local} value={this.state.id_local} return_data={this.return_data} /></td></tr>
             <tr><td style={{"fontSize":"18px"}}>Fecha:</td><td><DatePicker dateFormat="Pp"  selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} showTimeSelect timeFormat="p"
      timeIntervals={15} /></td></tr>
             <tr><td style={{"fontSize":"18px"}}>Tipo:</td><td><GetSelect style={"select-css"} width={"100%"} label={""} name={"id_formulario"} data={this.state.data_formulario} value={this.state.id_formulario} return_data={this.return_data} /></td></tr>
            </tbody>
            </table>
            <GetButton classname={"button_v1"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} name={"Continuar"} value={"Continuar"} return_accion={this.return_limpiar}  />
            <GetButton classname={"button_v2"} style={{"width":"100%"}} disabled={this.state.btn_limpiar_enabled} name={"Salir"} value={"Salir"}
             return_accion={this.props.setSubModulo}  />

            {(this.state.data_detalle&&this.state.data_detalle.length>0)?
            <GetFormularioNewLogistica  return_limpiarT ={this.return_limpiarT} firebase={this.props.firebase} id_formulario_cabecera={this.state.id_formulario_cabecera} id_formulario={this.state.id_formulario} data_detalle ={this.state.data_detalle} />
            :null}
          </div>
          </div>
          {modal_loading}
       </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select();
  }

}

export default getNewLogistica;
