import React, { Component } from 'react';
import GetInput from './../util/getInput';
import Selectbd from './../bd/getDb';
class getCosteo extends Component {

  constructor(){/**********/
    super();
    this.state = {
        busqueda:'',
        data_productos:[]
    }
  }

  actualizar_tabla =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "( select id_ficha_tecnica, COD, a.item as receta , cantidad, medida, Costo, id_medida, id_item_secundario, id_item_principal,i.item,i.id_item_global from ((select id_ficha_tecnica, i.cod_fabrica as COD,i.item,f.cantidad,m.medida,i.precio_costo as Costo,m.id_medida,f.id_item_secundario,id_item_principal  from ficha_tecnica1 f inner join item2 i on i.id_item=f.id_item_secundario inner join medida m on m.id_medida=f.id_medida where id_item_principal>0)union( select id_ficha_tecnica,i.codigo as COD,produccion_formula,f.cantidad, cast('S/N' as char) as medida,i.costo_ as Costo, cast('S/N' as char)as `T.Medida`,f.id_produccion_formula,id_item_principal  from ficha_tecnica1 f  inner join produccion_formula i on i.id_produccion_formula=f.id_produccion_formula  where id_item_principal>0)) a inner join item_global i on i.id_item_global=id_item_principal order by i.item,i.id_item_global )",
        field :[],
        order_by: "item,id_item_global",
        return:['id_ficha_tecnica', 'COD', 'receta', 'cantidad', 'medida', 'Costo', 'id_medida', 'id_item_secundario', 'id_item_principal', 'item', 'id_item_global']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          let id_item_global = 0
          let array_total =[]
          let array_subtotal =[]
          let name_producto =""
          value[1].result.forEach (producto => {
            //console.log(producto)
            if(Number(id_item_global)===0){
              id_item_global = Number(producto.id_item_global)
              name_producto = producto.item
              array_subtotal=[...array_subtotal, {
                  'COD':producto.COD,
                  'Costo':producto.Costo,
                  'id_ficha_tecnica':producto.id_ficha_tecnica,
                  'cantidad':producto.cantidad,
                  'id_item_global':producto.id_item_global,
                  'id_item_principal':producto.id_item_principal,
                  'id_item_secundario':producto.id_item_secundario,
                  'id_medida':producto.id_medida,
                  'item':producto.item,
                  'medida':producto.medida,
                  'receta':producto.receta}]
            }else{
              if(Number(id_item_global)===Number(producto.id_item_global)){
                array_subtotal=[...array_subtotal, {
                    'COD':producto.COD,
                    'Costo':producto.Costo,
                    'id_ficha_tecnica':producto.id_ficha_tecnica,
                    'cantidad':producto.cantidad,
                    'id_item_global':producto.id_item_global,
                    'id_item_principal':producto.id_item_principal,
                    'id_item_secundario':producto.id_item_secundario,
                    'id_medida':producto.id_medida,
                    'item':producto.item,
                    'medida':producto.medida,
                    'receta':producto.receta}]
              }else{
                array_total=[...array_total, {name_producto,array_subtotal}]
                id_item_global = Number(producto.id_item_global)
                name_producto = producto.item
                array_subtotal =[]
                array_subtotal=[...array_subtotal, {
                    'COD':producto.COD,
                    'Costo':producto.Costo,
                    'id_ficha_tecnica':producto.id_ficha_tecnica,
                    'cantidad':producto.cantidad,
                    'id_item_global':producto.id_item_global,
                    'id_item_principal':producto.id_item_principal,
                    'id_item_secundario':producto.id_item_secundario,
                    'id_medida':producto.id_medida,
                    'item':producto.item,
                    'medida':producto.medida,
                    'receta':producto.receta}]
              }
            }
          })
          array_total=[...array_total, {name_producto,array_subtotal}]
          //console.log(array_total)
          this.setState({
            data_productos : array_total
          })


        }else{
          this.setState({
            data_productos : []
          })
        }
      });
    }
  }

 inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }

 visualizar_detalle = (producto) =>{
   //const roundTo = require('round-to');
   let total = 0
   if(producto.length !== 0){
     producto.forEach (componente => {
       total = total + (Number(componente.cantidad)*Number(componente.Costo))
     })
   }
   return(
     <div>
       <table>
       <tr style={{"color": "chocolate"}}><td colspan='2'>Costo Total :</td><td>{total.toFixed(2)}</td></tr></table>
       Componentes: <br/>
       <table>
         {(producto.length !== 0) ?
            producto.map(componente => (
              <React.Fragment>
              <tr><td  style={{"width":"10%","fontSize":"7pt"}}>{componente.cantidad}</td><td  style={{"fontSize":"7pt"}}>{componente.receta}</td><td style={{"fontSize":"7pt"}}>{componente.Costo}</td></tr>
              </React.Fragment>
            )) : null}
       </table>
     </div>
   )
 }
 visualizar_tabla=()=>{
   const data_productos = this.state.data_productos
   const busqueda = this.state.busqueda
   return (
     <div class="ex-section ex2">
        <div class="ex-area">
          <div class="ex-container">
             <div class="parent white">
             {(data_productos.length !== 0) ?
                data_productos.map(producto => (
                  <React.Fragment>
                  {(producto.name_producto.includes(busqueda))?
                  <div class="box green">
                  <font style={{"color":"darkgreen","fontWeight":"bolder"}}>{producto.name_producto}</font>
                    {this.visualizar_detalle(producto.array_subtotal)}
                  </div>
                  :<React.Fragment></React.Fragment>}
                  </React.Fragment>
                ))
               :<React.Fragment></React.Fragment>
              }
              </div>
            </div>
          </div>
      </div>
   )
 }
  render() {

    return (
      <React.Fragment>
        <div className="formulario white">
          <GetInput label={"Búsqueda"} name={"busqueda"} value={this.state.busqueda} return_data={this.return_data} />
        </div>
        {this.visualizar_tabla()}
      </React.Fragment>

    )
  }
  componentDidMount (){
    this.actualizar_tabla()
  }

}

export default getCosteo;
