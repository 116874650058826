import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import GetFormularioNew from './getFormularioNew';

//ID CATALOGO  =20
class getVistaPrevia extends Component {
  constructor(props){
    super(props);
    this.state = {
        id_formulario:0,
        data_formulario:[],
        id_tipo_local:0,
        data_tipo_local:[],
        btn_limpiar_enabled:false,
        data_detalle:[]
    }
  }

  return_data =(name,value,id)=>{
    this.setState({
      [name]:value
    })
  }
 actualizar_tabla =()=>{
   const select = [{
    type: 'consulta', data:
    [{
      table : "(SELECT f.id_formulario_detalle,c.catalogo,cast(cs.catalogo as char) as subcategoria,i.indice,calificacion,sw_imagenes,txt_id_etiquetas,f.orden FROM formulario_detalle FROM formulario_detalle f inner join catalogo c on c.id_catalogo=f.id_categoria inner join auditoria_indice i on i.id_auditoria_indice=f.id_indice left join catalogo cs on cs.id_catalogo=f.id_subcategoria)",
      field :[],
      order_by: "orden,id_formulario_detalle",
      return:['id_formulario_detalle', 'catalogo', 'subcategoria', 'indice', 'calificacion', 'sw_imagenes','txt_id_etiquetas']
    }]
  }]
  var result = Selectbd(select)
  if(result){
    result.then((value) => {
      if(value[1].status==="ok"){
        this.setState({
          data_detalle : value[1].result
        })
      }else{
        this.setState({
          data_detalle : []
        })
      }
    });
  }
 }
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "formulario",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "formulario",
        return:['id_formulario as id','formulario as name']
      },{
        table : 'catalogo',
        field :[{
          name : 'tipo',
          value : '3',
          type:'integer'
        },{
          name : 'activo',
          value :'1',
          type:'integer'
        }],
        return:['id_catalogo as id','catalogo as name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"){
          this.setState({
            data_formulario: value[1].result,
            data_tipo_local: value[2].result
          })
        }
      });
    }
  }
  return_limpiar = ()=>{
    this.setState ({
      btn_limpiar_enabled:false
    },()=>{
      this.actualizar_tabla()
    })
  }
  return_limpiarT =()=>{
    this.setState ({
      data_detalle:[]
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="formulario white">
          <GetSelect label={"Formulario"} name={"id_formulario"} data={this.state.data_formulario} value={this.state.id_formulario} return_data={this.return_data} />
          <GetSelect label={"Tipo Local"} name={"id_tipo_local"} data={this.state.data_tipo_local} value={this.state.id_tipo_local} return_data={this.return_data} />
          <GetButton disabled={this.state.btn_limpiar_enabled} name={"Generar"} value={"generar"} return_accion={this.return_limpiar}  />
          {(this.state.data_detalle&&this.state.data_detalle.length>0)?
          <GetFormularioNew  return_limpiarT ={this.return_limpiarT} firebase={this.props.firebase} data_detalle ={this.state.data_detalle} />
          :null}
        </div>
       </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select();
  }

}

export default getVistaPrevia;
